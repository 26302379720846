<template>
  <div :class="`switch ${theme}`">
    <span class="title">{{ labelLeft }}</span>
    <div class="selector">
      <label :for="name">
        <input type="checkbox" :id="name" :name="name" :checked="isChecked" @change="check">
        <span class="label">&nbsp;</span>
      </label>
    </div>
    <span class="title">{{ labelRight }}</span>
  </div>
</template>
<script>
export default {
  name: 'ToggleDoubleLabel',
  props: {
    name: { type: String, required: true },
    labelLeft: { type: String, required: true },
    labelRight: { type: String, required: true },
    isChecked: { type: Boolean, default: false },
    theme: { type: String, default: 'light' },
  },
  methods: {
    check(event) {
      this.$emit('update', event.target.checked);
    },
  },
};
</script>
<style lang="scss" scoped>
.switch {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: fds-rem(16px);
  font-weight: 500;

  .selector {
    margin: 0 10px;
  }

  &.light {
    color: $fds-color--gray3;
    input {
      &+.label {
        background-color: $fds-color--secondary;
      }
    }
  }
  &.dark {
    color: $fds-color--white;
    input {
      &+.label {
        background-color: $fds-color--secondary;
      }
    }
  }
  input {
    display: block;
    width: 0;
    height: 0;
    margin: 0;
    overflow: hidden;

    &:checked {
      &+.label {
        &::before {
          -webkit-transform: translateX(26px);
          -moz-transform: translateX(26px);
          -o-transform: translateX(26px);
          transform: translateX(26px);
          @media screen and (max-width: 767px) {
            -webkit-transform: translateX(20px);
            -moz-transform: translateX(20px);
            -o-transform: translateX(20px);
            transform: translateX(20px);
          }
        }
      }
    }
  }
  .label {
    position: relative;
    display: block;
    width: 52px;
    height: 27px;
    margin: 0;
    padding: 3px;
    text-indent: -9999px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -o-border-radius: 15px;
    border-radius: 15px;
    will-change: background-color;
    -webkit-transition: all .3s .1s ease;
    -moz-transition: all .3s .1s ease;
    -o-transition: all .3s .1s ease;
    transition: all .3s .1s ease;
    cursor: pointer;
    -webkit-box-shadow: 0 4px 4px rgba(0,0,0,.2);
    -moz-box-shadow: 0 4px 4px rgba(0,0,0,.2);
    -o-box-shadow: 0 4px 4px rgba(0,0,0,.2);
    box-shadow: 0 4px 4px rgba(0,0,0,.2);

    @media screen and (max-width: 767px) {
      width: 40px;
      height: 20px;
    }

    &::before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-color: #fff;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -o-border-radius: 50%;
      border-radius: 50%;
      -webkit-box-shadow: 0 0 4px rgba(0,0,0,.2);
      -moz-box-shadow: 0 0 4px rgba(0,0,0,.2);
      -o-box-shadow: 0 0 4px rgba(0,0,0,.2);
      box-shadow: 0 0 4px rgba(0,0,0,.2);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
      will-change: transform;
      -webkit-transition: all .2s linear;
      -moz-transition: all .2s linear;
      -o-transition: all .2s linear;
      transition: all .2s linear;

      @media screen and (max-width: 767px) {
        width: 14px;
        height: 14px;
      }
    }
  }
}
</style>
