/* eslint-disable */

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { defaultFormat } from 'moment';
import router from '../router';

export default class AnalyticHelper {
  constructor() {
    this.appInstance = null;

    // OneTrust Logic to Disable Analytics
    this.addOneTrustHelperExtension();
    this.allowCookies = window.otFordHelper.groupConsent(6);

    if (this.allowCookies) {
      this.appInstance = new ApplicationInsights({
        config: {
          instrumentationKey: process.env.VUE_APP_APPLICATION_INSIGHT_KEY
        }
      });
      this.appInstance.loadAppInsights();
    } else {
      this.deleteCookie('ai_session');
      this.deleteCookie('ai_user');
    }
  }

  trackEventBtnPage(nameAction) {
    const copy1 = this.cleanName(router.currentRoute.name);
    const copy2 = this.cleanName(nameAction);
    const nameEvent = `Page_${copy1}_ActionBtn_${copy2}`;

    const trackObject = {
      name: nameEvent
    };

    if (this.allowCookies) {
      this.appInstance.trackEvent(trackObject);
    }
  }

  trackEventBtnPageWithMetaInfo(nameAction, metaInfo) {
    const copy1 = this.cleanName(router.currentRoute.value.name);
    const copy2 = this.cleanName(nameAction);
    const nameEvent = `Page_${copy1}_Action_${copy2}`;

    const trackObject = {
      name: nameEvent,
      properties: metaInfo
    };

    if (this.allowCookies) {
      this.appInstance.trackEvent(trackObject);
    }
  }

  trackEventBtnPrimaryNav(nameAction) {
    const copy = this.cleanName(nameAction);
    const nameEvent = `PrimaryNav_NavItem_${copy}`;

    const trackObject = {
      name: nameEvent
    };

    if (this.allowCookies) {
      this.appInstance.trackEvent(trackObject);
    }
  }

  trackEventBtnPrimaryNavMobile(nameAction) {
    const copy = this.cleanName(nameAction);
    const nameEvent = `PrimaryNavMobile_NavItem_${copy}`;

    const trackObject = {
      name: nameEvent
    };

    if (this.allowCookies) {
      this.appInstance.trackEvent(trackObject);
    }
  }

  trackEventBtnFooter(nameAction) {
    const copy = this.cleanName(nameAction);
    const nameEvent = `Footer_Action_${copy}`;

    const trackObject = {
      name: nameEvent
    };

    if (this.allowCookies) {
      this.appInstance.trackEvent(trackObject);
    }
  }

  trackEventBtnProfile(nameAction) {
    const copy = this.cleanName(nameAction);
    const nameEvent = `Profile_ActionBtn_${copy}`;

    const trackObject = {
      name: nameEvent
    };

    if (this.allowCookies) {
      this.appInstance.trackEvent(trackObject);
    }
  }

  trackEventBtnModal(nameModal, nameAction) {
    const copy1 = this.cleanName(nameModal);
    const copy2 = this.cleanName(nameAction);
    const nameEvent = `Modal_${copy1}_ActionBtn_${copy2}`;

    const trackObject = {
      name: nameEvent
    };

    if (this.allowCookies) {
      this.appInstance.trackEvent(trackObject);
    }
  }

  trackModalView(modalName) {
    const cleanName = this.cleanName(modalName);
    const nameEvent = `Modal_${cleanName}`;

    const trackObject = {
      name: nameEvent
    };

    if (this.allowCookies) {
      this.appInstance.trackPageView(trackObject);
    }
  }

  trackPageView() {
    const copy = this.cleanName(router.currentRoute.value.name);
    const namePage = copy;

    const trackObject = {
      name: namePage
    };

    if (this.allowCookies) {
      this.appInstance.trackPageView(trackObject);
    }
  }

  trackPageViewWithMetaInfo(metaInfo) {
    const copy = this.cleanName(router.currentRoute.value.name);
    const namePage = copy;

    const trackObject = {
      name: namePage,
      properties: metaInfo
    };

    if (this.allowCookies) {
      this.appInstance.trackPageView(trackObject);
      this.appInstance.flush();
    }
  }

  trackEvent(eventName) {
    const cleanEventName = this.cleanName(eventName);
    const nameEvent = `Event_${cleanEventName}`;

    const trackObject = {
      name: nameEvent
    };

    if (this.allowCookies) {
      this.appInstance.trackEvent(trackObject);
    }
  }

  trackEventWithMetaInfo(eventName, metaInfo) {
    const cleanEventName = this.cleanName(eventName);
    const nameEvent = `Event_${cleanEventName}`;

    const trackObject = {
      name: nameEvent,
      properties: metaInfo
    };

    if (this.allowCookies) {
      this.appInstance.trackEvent(trackObject);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  cleanName(name) {
    let copy = name;
    copy = copy.replace(/\s+/g, '');
    return copy;
  }

  // OneTrust Method
  // eslint-disable-next-line class-methods-use-this
  deleteCookie(name) {
    document.cookie = `${name}= ; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
  }

  // One Trust Method
  cookieChange() {
    if (window.otFordHelper && window.otFordHelper.groupConsent(6) === false) {
      this.deleteCookie('ai_session');
      this.deleteCookie('ai_user');
      this.allowCookies = false;
    }
  }

  // OneTrust Method
  addOneTrustHelperExtension() {
    const self = this;

    window.otFordHelper = {
      groupConsent: function(groupID) {
        var consent = true;
        var cookies = document.cookie.split(';').reduce(function(map, obj) {
          var cookieString = obj.trim();
          var firstEqual = cookieString.indexOf('=');
          var key = cookieString.substring(0, firstEqual);
          var value = cookieString.substring(firstEqual + 1);
          map[key] = value;
          return map;
        }, []);
        var otConsentCookie = cookies['OptanonConsent'];
        if (otConsentCookie && otConsentCookie.length > 0) {
          var values = otConsentCookie.split('&').reduce(function(map, obj) {
            var equal = obj.indexOf('=');
            var key = obj.substring(0, equal);
            var value = decodeURIComponent(obj.substring(equal + 1));
            map[key] = value;
            return map;
          }, []);
          var otConsentGroups = values['groups'];
          if (otConsentGroups && otConsentGroups.length > 0) {
            var groups = otConsentGroups.split(',');
            var group = groups.filter(function(group) {
              var id = group.split(':')[0];
              return id === groupID.toString();
            })[0];
            if (typeof group !== 'undefined' && group.split(':')[1] === '0') {
              consent = false;
            }
          }
        }
        return consent;
      }
    };

    window.addEventListener('consent.onetrust', function(e) {
      if (window.otFordHelper && window.otFordHelper.groupConsent(6) === false) {
        self.deleteCookie('ai_session');
        self.deleteCookie('ai_user');
        self.allowCookies = false;
        if (window.appInsights) {
          window.appInsights.config.disableTelemetry = true;
          window.appInsights.config.instrumentationKey = null;
        }
      }
    });
  }
}
