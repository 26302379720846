<template>
  <button
    :type="type"
    :id="`button--${id}`"
    class="fds-button"
    :class="buttonClasses"
    :disabled="disabled"
    :aria-disabled="disabled.toString()"
    @click="clickHandler($event)"
  >
    <i
      v-if="icon && iconPosition === 'left'"
      :class="`fds-icon ${icon} fds-icon--offset-left fds-icon--12`">
    </i>
    {{ label }}
    <i
      v-if="icon && iconPosition === 'right'"
      :class="`fds-icon ${icon} fds-icon--offset-right fds-icon--12`">
    </i>
  </button>
</template>
<script>

export default {
  name: 'FdsButton',
  props: {
    id: { type: String, required: true },
    type: { type: String, default: 'button' },
    label: { type: String, required: true },
    primary: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    isLink: { type: Boolean, default: false },
    icon: {
      type: String,
      default: '',
      validator: (value) => [
        '', 'next', 'previous', 'add', 'remove',
      ].indexOf(value) !== -1,
    },
    iconPosition: {
      type: String,
      default: 'right',
      validator: (value) => [
        'left', 'right',
      ].indexOf(value) !== -1,
    },
  },
  computed: {
    buttonClasses() {
      return {
        primary: this.primary,
        secondary: !this.primary,
        outlined: this.outlined,
        disabled: this.disabled,
        'is-link': this.isLink,
      };
    },
  },
  methods: {
    clickHandler(e) {
      if (!this.disabled) {
        this.$emit('onClick', e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$min-width: 160px;

.fds-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: $min-width;
  height: 40px;
  padding: 0 fds-rem(30px);
  background: none;
  font-family: $fds-font--antenna;
  font-size: $fds-font-size__content1;
  font-stretch: condensed;
  font-weight: 300;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  white-space: nowrap;
  border: none;
  z-index: 1;

  // remove flash on iOS mobile as accessibility indicators are customized in this CSS
  -webkit-tap-highlight-color: var(--fds-color--tertiary-0);

  // prevent button elements from shifting in IE11
  top: 0;
  left: 0;

  &.primary {
    &:not(.outlined) {
      color: $fds-color--white;
      &::before {
        background-color: $fds-color--primary;
      }

      &.disabled {
        &::before {
          background-color: $fds-color--disabled3;
        }
      }
    }
    &.outlined {
      color: $fds-color--primary;
      &::before {
        border-color: $fds-color--primary;
      }

      &.disabled {
        color: $fds-color--disabled3;
        &::before {
          border-color: $fds-color--disabled3;
        }
      }
    }
  }

  &.secondary {
    &:not(.outlined) {
      color: $fds-color--primary;
      &::before {
        background-color: $fds-color--white;
      }

      &.disabled {
        &::before {
          background-color: $fds-color--disabled2;
        }
      }
    }
    &.outlined {
      color: $fds-color--white;
      &::before {
        border-color: $fds-color--white;
      }
      &.disabled {
        color: $fds-color--disabled2;
        &::before {
          border-color: $fds-color--disabled2;
        }
      }
    }
  }

  &.is-link {
    font-weight: 500;

    &::before {
      background-color: transparent !important;
      box-shadow: none !important;
    }

    &:hover {
      text-decoration: underline;

      &:not(.disabled)::before {
        transform: scale(1.07);
      }
      &:not(.disabled) {
        &:not(.outlined) {
          color: $fds-color--secondary;
        }
      }
    }

    &:focus {
      &::after {
        inset: fds-rem(-5px);
      }
    }

    &.disabled {
      &::before {
        background-color: transparent;
        color: $fds-color--disabled2;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid transparent;
    border-radius: fds-rem(20px);
    outline-color: $fds-color--primary;
    will-change: transform, background-color, border-color;
    transition:
      box-shadow 0.3s ease-in-out,
      transform 0.3s ease-in-out,
      background-color 0.3s ease-in-out,
      border-color 0.3s ease-in-out;
    z-index: -1;
  }

  &:not(.outlined):not(.disabled) {
    &::before {
      box-shadow: $fds-elevation__box-shadow--layer2;
    }
  }

  // Disabled
  &.disabled {
    cursor: not-allowed;
  }

  // Hover
  &:hover {
    &:not(.disabled)::before {
      transform: scale(1.07);
    }
    &:not(.disabled) {
      &:not(.outlined) {
        color: $fds-color--white;
        &::before {
          background-color: $fds-color--secondary;
          box-shadow: $fds-elevation__box-shadow--layer3;
        }
      }
    }
  }

  // Active
  &:active {
    &::before {
      transform: scale(1);
    }
  }

  // Focus
  &:focus {
    &::after {
      content: '';
      position: absolute;
      inset: fds-rem(-20px);
      border: 1px solid $fds-color--primary;
    }
  }

  // Icons
  .fds-icon {
    font-family: $fds-font--ford-icons;

    &.next::before {
      content: $fds-font--ford-icons__chevron-right;
    }
    &.previous::before {
      content: $fds-font--ford-icons__chevron-left;
    }
    &.add::before {
      content: $fds-font--ford-icons__plus;
    }
    &.remove::before {
      content: $fds-font--ford-icons__clear;
    }
  }
}
</style>
