import axios from 'axios';
import Login from './modules/login/login';

const success = (response) => response;
const failure = (error) => {
  if (!error.response) {
    throw error;
  }

  if (error.response.status === 401) {
    window.location.href = `${process.env.VUE_APP_BASE_URL}login`;
  }

  if (error.response.status === 403) {
    window.location = `${process.env.VUE_APP_BASE_URL}ineligible`;
  }

  throw error;
};

class ApiManager {
  constructor() {
    this.axiosObject = axios.create({
      baseURL: process.env.VUE_APP_BASE_API_APP_URL,
    });

    this.axiosObject.interceptors.response.use(success, failure);

    this.Login = new Login(this.axiosObject);
  }
}

export default new ApiManager();
