<template>
  <div class="fds-radio">
    <div class="fds-radio__container">
      <span class="fds-radio__title fds-m--r-2">
        <slot />
        <i
          v-if="hasBeenValidated"
          class="fds-icon fds-icon--offset-right fds-icon--16"
          :class="iconClasses">
        </i>
      </span>
      <div class="fds-radio__options">
        <label v-for="option in options" :for="`radio--${option.id}`" :key="option.id">
          <input
            type="radio"
            :id="`radio--${option.id}`"
            :name="name"
            :class="option.isDisabled ? 'disabled' : ''"
            :checked="option.isChecked"
            :disabled="option.isDisabled"
            @change="onChange(option.label)"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
            @click="$emit('click', $event)"
          />
          <span class="box"></span>
          <span class="label">{{ option.label }}</span>
        </label>
      </div>
    </div>
    <span
      v-if="hasBeenValidated
        && hasError
        && required"
      :id="`radio--${id}-error`"
      :class="`error-message ${hasError ? 'show' : ''}`">
      {{ $t('tabs.general.form.requiredField') }}
    </span>
  </div>
</template>

<script>
/**
 * A base checkbox component for creating custom checkbox implementations.
 */
export default {
  name: 'FdsRadio',
  props: {
    id: { type: String, required: true },
    name: { type: String, required: true },
    options: { type: Object, default: () => {} },
    required: { type: Boolean, required: true, default: false },
    submitted: { type: Boolean, required: true, default: false },
  },
  data() {
    return {
      optionSelected: '',
      hasBeenValidated: false,
      hasError: false,
    };
  },
  computed: {
    iconClasses() {
      return {
        'fds-font--ford-icons__success': this.hasBeenValidated && this.optionSelected && !this.hasError,
        'fds-font--ford-icons__error': this.hasBeenValidated && this.hasError,
      };
    },
  },
  methods: {
    checkIfIsValid() {
      this.hasBeenValidated = true;
      if (this.required && this.submitted && !this.optionSelected) {
        this.hasError = true;
        this.$emit('hasError', { name: this.name, error: true });
      } else {
        this.hasError = false;
        this.$emit('hasError', { name: this.name, error: false });
      }
    },
    onChange(e) {
      this.optionSelected = e;
      this.$emit('input', this.optionSelected);
      this.checkIfIsValid();
    },
    populateSelectedOption() {
      const selected = this.options.filter((e) => e.isChecked);
      this.optionSelected = selected.length ? selected[0].label : '';
    },
  },
  watch: {
    submitted() {
      this.checkIfIsValid();
    },
    options() {
      this.populateSelectedOption();
    },
    optionSelected() {
      this.checkIfIsValid();
    },
  },
  created() {
    this.populateSelectedOption();
    this.checkIfIsValid();
  },
};
</script>

<style lang="scss" scoped>
.fds-radio {
  display: block;
  width: auto;

  &__options {
    display: flex;
    align-items: center;
    gap: fds-rem(40px);
  }

  &__title {
    margin-bottom: fds-rem(8px);
    color: $fds-color--gray3;
    font-size: fds-rem(12px);
  }

  label {
    display: flex;
    align-items: flex-start;
    position: relative;
    min-height: fds-rem(17px);
    color: $fds-color--primary;
    font-family: $fds-font--antenna;
    text-align: left;

    .box {
      position: relative;
      min-width: fds-rem(16px);
      min-height: fds-rem(16px);
      margin-top: fds-rem(4px);
      background-color: transparent;
      border-style: solid;
      border-width: fds-rem(2px);
      border-radius: fds-rem(8px);
      border-color: $fds-color--gray2;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        left: 1px;
        top: 1px;
        width: fds-rem(10px);
        height: fds-rem(10px);
        background-color: $fds-color--primary;
        border-radius: fds-rem(5px);
        opacity: 0;
        will-change: opacity;
        transition: opacity 0.3s ease-in-out;
      }
    }

    .label {
      @include fds-get-component-typeset('radio-label');
      margin-left: fds-rem(15px);
      padding: fds-rem(1px);
      line-height: fds-rem(24px);
    }
  }

  input {
    opacity: 0;
    width: fds-rem(16px);
    height: fds-rem(16px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;

    &:checked {
      & ~ .box {
        background-color: $fds-color--white;
        border-color: $fds-color--primary;

        &::before {
          opacity: 1;
        }
      }
    }

    &:focus {
      & ~ .box {
        &::after {
          content: '';
          position: absolute;
          left: -2px;
          top: -2px;
          right: -2px;
          bottom: -2px;
          outline: 1px solid $fds-color--primary;
          outline-offset: fds-rem(5px);
        }
      }
    }
    &:not(.disabled) {
      & ~ .label {
        cursor: pointer;
      }
    }

    &.disabled {
      & ~ .box,
      & ~ .label {
        background-color: $fds-color--gray2;
        color: $fds-color--white;
        cursor: not-allowed;
      }

      & ~ .box {
        background-color: $fds-color--disabled3;
        color: $fds-color--white;
      }
    }
  }

  .error-message {
    display: block;
    margin-top: fds-rem(10px);
    color: $fds-color--error1;
    font-size: $fds-font-size__content2;
  }
}
</style>
