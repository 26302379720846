<template>
  <label class="fds-text-field" :class="InputClasses" :for="`textarea--${id}`">
    <span class="label" :class="labelClasses">
      <slot />
      <i
        v-if="hasBeenValidated"
        class="fds-icon fds-icon--offset-right fds-icon--16"
        :class="iconClasses">
      </i>
    </span>
    <textarea
      class="field"
      :id="`textarea--${id}`"
      :value="value"
      :class="textareaClasses"
      :disabled="disabled"
      :readonly="readonly"
      min="0"
      minlength="3"
      @input="$emit('update:value', $event.target.value)"
      @keyup="onKeyUp"
      @focus="onFocus"
      @blur="onBlur($event.target.value)"
    />
    <span class="error-message" :id="`textarea--${id}-error`">{{ errorMessage }}</span>
  </label>
</template>
<script>
import keyCodes from '../../utilities/constants/keysCodes.const';

export default {
  name: 'FdsTextarea',
  props: {
    id: { type: String, required: true },
    name: { type: String, required: true },
    value: { type: [String, Number], default: '' },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    submitted: { type: Boolean, required: true },
    darkTheme: { type: Boolean, default: false },
  },
  data() {
    return {
      errorMessage: '',
      inFocus: false,
      isFilled: false,
      keyFocused: false,
      hasError: false,
      hasBeenValidated: false,
    };
  },
  computed: {
    InputClasses() {
      return {
        disabled: this.disabled,
        readonly: this.readonly,
        'key-focused': this.keyFocused,
        success: this.hasBeenValidated && this.isFilled && !this.hasError,
        error: this.hasBeenValidated && this.hasError,
        'dark-theme': this.darkTheme,
        'light-theme': !this.darkTheme,
      };
    },
    labelClasses() {
      return {
        active: this.inFocus,
        filled: this.isFilled,
      };
    },
    iconClasses() {
      return {
        'fds-font--ford-icons__success': this.hasBeenValidated && this.isFilled && !this.hasError,
        'fds-font--ford-icons__error': this.hasBeenValidated && this.hasError,
      };
    },
    textareaClasses() {
      return {
        filled: this.isFilled,
        readonly: this.readonly,
        disabled: this.disabled,
      };
    },
  },
  methods: {
    checkIfIsValid() {
      if (this.disabled || !this.required) return;

      this.hasBeenValidated = true;

      if (!this.value) {
        this.hasError = true;
        this.errorMessage = this.$t('tabs.general.form.requiredField');
        this.$emit('hasError', { name: this.name, error: true });
        return;
      }

      this.hasError = false;
      this.$emit('hasError', { name: this.name, error: false });
    },

    onKeyUp(e) {
      if (!this.disabled && e.keyCode === keyCodes.TAB) {
        this.keyFocused = true;
        this.$emit('keyup', e.keyCode);
      }
    },
    onFocus() {
      if (!this.disabled && !this.readonly) {
        this.inFocus = true;
        this.$emit('focus');
      }
    },
    onBlur(value) {
      this.inFocus = false;
      this.keyFocused = false;
      this.$emit('change', value);
      this.isFilled = this.value !== '';
      this.checkIfIsValid();
    },
  },
  watch: {
    submitted() {
      this.checkIfIsValid();
    },
    value(value) {
      this.isFilled = value !== '';
      this.checkIfIsValid();
    },
  },
  created() {
    if (this.value !== '') this.isFilled = true;
    if (this.required && this.value === '') {
      this.$emit('hasError', { name: this.name, error: true });
    }
  },
};
</script>
<style scoped lang="scss">
.fds-text-field {
  position: relative;
  width: 100%;
  max-width: fds-rem(520px);

  &.key-focused {
    outline: 1px solid $fds-color--primary;
    outline-offset: fds-rem(10px);
  }

  .label {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin: fds-rem(0 0 5px 15px);
    white-space: nowrap;
    font-size: fds-rem(16px);
    line-height: 1;
    letter-spacing: fds-rem(1px);
    z-index: 1;
    transform: translate(0, fds-rem(40px));
    will-change: font-size, transform;
    transition:
      font-size 0.3s ease-in-out,
      transform 0.3s ease-in-out;

    &.active,
    &.filled {
      transform: translate(fds-rem(-15px),0);
      font-size: fds-rem(12px);
    }

    .fds-icon {
      font-family: $fds-font--ford-icons;
      margin-bottom: fds-rem(3px);
    }
  }

  .field {
    @extend %fmc-type--body1;
    padding: fds-rem(15px);
    width: 100%;
    height: fds-rem(150px);
    border-width: fds-rem(1px);
    border-style: solid;
    border-radius: fds-rem(3px);
    resize: none;
    outline: none;
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
    -webkit-appearance: none; /* stylelint-disable property-no-vendor-prefix */

    &:focus:not(.disabled):not(.readonly) {
      box-shadow: 0 0 0 transparent inset, $fds-elevation__box-shadow--layer2;
    }

    &.disabled.filled,
    &.readonly.filled {
      font-weight: 300;
    }
  }

  &.light-theme {
    .label {
      color: $fds-color--gray3;
    }
    .field {
      background-color: $fds-color--gray1;
      color: $fds-color--gray3;
      border-color: $fds-color--gray2;

      :-webkit-autofill {
        -webkit-text-fill-color: $fds-color--gray3;
      }

      &.filled,
      &:focus {
        background-color: $fds-color--white;
        color: $fds-color--primary;
      }

      &.disabled,
      &.readonly {
        background-color: $fds-color--disabled3;
        &::placeholder {
          color: $fds-color--white !important;
        }
      }

      &.disabled.filled,
      &.readonly.filled {
        color: $fds-color--white;
      }
    }

    &.disabled,
    &.readonly {
      .label {
        color: $fds-color--white;
        font-weight: 300;
        &.filled {
          color: $fds-color--gray3;
        }
      }
    }
  }

  .error-message {
    position: absolute;
    left: 0;
    bottom: fds-rem(-20px);
    width: 100%;
    color: $fds-color--error1;
    font-size: fds-rem(12px);
    line-height: fds-rem(18px);
    letter-spacing: fds-rem(1px);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &.error {
    .field:not(.readonly) {
      background-color: $fds-color--white;
      border-color: $fds-color--error1;
    }
    .error-message {
      opacity: 1;
    }
  }
  &.success {
    .field {
      background-color: $fds-color--white;
      border-color: $fds-color--success1;
    }
  }
  &.disabled,
  &.readonly {
    pointer-events: none;
    cursor: not-allowed;
  }
  &.readonly {
     &::placeholder {
      color: $fds-color--white !important;
    }
  }
}

</style>
