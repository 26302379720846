<template>
  <div id="main-content">
    <TheHeader />
    <router-view></router-view>
    <TopBanner
      :type="notificationType"
      :content="notificationMessage"
      :status="notificationStyle"
      :show="showNotification"
    />
    <div class="loading" v-show="isLoading">
      <Loading />
    </div>
    <ModalDialog
      :isDecision="modalIsDecision"
      :title="modalTitle"
      :content="modalContent"
      :imgUrl="modalImgUrl"
      :imgAlt="modalImgAlt"
      :continueText="modalContinueText"
      :cancelText="modalCancelText"
      :show="showModalDialog"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import TheHeader from '@/components/TheHeader.vue';
import ModalDialog from '@/components/notifications/ModalDialog.vue';
import TopBanner from '@/components/notifications/TopBanner.vue';
import Loading from '@/components/activity-indicators/CircularIndeterminate.vue';

export default {
  name: 'main-content',
  components: {
    TheHeader,
    ModalDialog,
    TopBanner,
    Loading,
  },
  computed: {
    ...mapGetters({
      // Notification
      showNotification: 'notifications/getNotificationState',
      notificationMessage: 'notifications/getNotificationMessage',
      notificationStyle: 'notifications/getNotificationStyle',
      notificationType: 'notifications/getNotificationType',

      // Modal Dialog
      showModalDialog: 'notifications/getModalState',
      modalIsDecision: 'notifications/getModalIsDecision',
      modalTitle: 'notifications/getModalTitle',
      modalContent: 'notifications/getModalContent',
      modalImgUrl: 'notifications/getModalImgUrl',
      modalImgAlt: 'notifications/getModalImgAlt',
      modalContinueText: 'notifications/getModalContinueText',
      modalCancelText: 'notifications/getModalCancelText',

      // Loading
      isLoading: 'notifications/getIsLoading',

      // Scroll
      scrollLock: 'notifications/getScrollLock',
    }),
  },
  mounted() {
    document.title = this.$t('mainTitle');
  },
};
</script>
<style lang="scss" scoped>
#main-content {
  max-height: 100vh;
  overflow: auto;

  &.scroll-lock {
    overflow: hidden;
  }
}
</style>
