import settings from '@/plugins/pdfSettings';

const stackedColumn = (label, paragraph, config = {}) => ({
  width: config.columnWidth || '25%',
  stack: [
    {
      text: label,
      fontSize: 8,
      margin: [0, 0, 0, 5],
    },
    {
      text: paragraph,
      margin: [0, 0, 0, 0],
    },
  ],
});

export default function (data) {
  return {
    defaultStyle: settings.defaultStyle,
    pageMargins: settings.pageMargins,
    styles: {
      heading: {
        fontSize: 13,
        color: settings.variables.colors.primary,
        margin: [0, 40, 0, 20],
      },
      subheading: {
        fontSize: 12,
        color: settings.variables.colors.primary,
        margin: [0, 40, 0, 32],
      },
    },
    content: [
      settings.components.header,
      settings.components.title(data.labels.subtitle),
      {
        columns: [
          {
            width: '33%',
            text: data.info.customerName,
          },
          {
            width: '33%',
            text: data.info.dealerName,
            alignment: 'center',
          },
          {
            width: '33%',
            text: data.info.date,
            alignment: 'right',
          },
        ],
      },
      {
        text: data.labels.vehicle.title,
        style: 'heading',
      },
      {
        columns: [
          stackedColumn(data.labels.vehicle.yearLabel, data.info.vehicle.year),
          stackedColumn(data.labels.vehicle.makeLabel, data.info.vehicle.make),
          stackedColumn(data.labels.vehicle.modelLabel, data.info.vehicle.model),
          stackedColumn(data.labels.vehicle.netCapCostLabel, data.info.vehicle.netCapCost),
        ],
      },
      {
        text: data.labels.taxes.title,
        style: 'heading',
      },
      {
        columns: [
          stackedColumn(data.labels.taxes.termLabel, data.info.services.term),
          stackedColumn(data.labels.taxes.residualLabel, data.info.services.residual),
          stackedColumn(data.labels.taxes.residualAmountLabel, data.info.services.residualAmount),
          stackedColumn(data.labels.taxes.paymentTimingLabel, data.info.services.paymentTiming),
        ],
      },
      {
        columns: [
          {
            width: '60%',
            text: data.labels.estimated.title1,
            style: 'subheading',
            margin: [0, 50, 0, 0],
          },
          {
            width: '40%',
            text: data.info.monthlyPaymentWithTax,
            style: 'heading',
            margin: [0, 50, 0, 0],
            alignment: 'right',
          },
        ],
      },
      {
        columns: [
          {
            width: '60%',
            text: data.labels.estimated.title2,
            style: 'subheading',
            margin: [0, 15, 0, 0],
          },
          {
            width: '40%',
            text: data.info.monthlyPaymentWithoutTax,
            style: 'heading',
            margin: [0, 15, 0, 0],
            alignment: 'right',
          },
        ],
      },
      {
        columns: [
          {
            width: '55%',
            text: `${data.labels.signature.customerName}: ${data.info.customerName}`,
            noWrap: true,
            color: settings.variables.colors.text,
            margin: [0, 50, 0, 0],
          },
          {
            width: 'auto',
            text: `${data.labels.signature.signature}:`,
            color: settings.variables.colors.text,
            margin: [0, 50, 0, 0],
          },
          {
            svg: `
            <svg width="200" height="1" viewBox="0 0 200 1" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="0.5" x2="180" y2="0.5" stroke="black"/>
            </svg>
            `,
            margin: [0, 60, 0, 0],
          },
        ],
        columnGap: 5,
      },
      {
        columns: [
          {
            width: '55%',
            text: `${data.labels.signature.dealerName}: ${data.info.dealerName}`,
            noWrap: true,
            color: settings.variables.colors.text,
            margin: [0, 15, 0, 0],
          },
          {
            width: 'auto',
            text: `${data.labels.signature.signature}:`,
            color: settings.variables.colors.text,
            margin: [0, 15, 0, 0],
          },
          {
            svg: `
            <svg width="200" height="1" viewBox="0 0 200 1" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="0.5" x2="180" y2="0.5" stroke="black"/>
            </svg>
            `,
            margin: [0, 25, 0, 0],
          },
        ],
        columnGap: 5,
      },
      {
        text: data.labels.signature.disclaimer,
        color: settings.variables.colors.text,
        margin: [0, 50, 0, 0],
        alignment: 'justify',
        fontSize: 10,
        bold: true,
        lineHeight: 1.5,
      },
      {
        text: data.labels.signature.disclaimerText,
        color: settings.variables.colors.text,
        margin: [0, 5, 0, 0],
        alignment: 'justify',
        fontSize: 8,
        lineHeight: 1.5,
      },
      {
        text: data.info.dealerParticipationCode,
        color: settings.variables.colors.text,
        margin: [0, 20, 0, 0],
        alignment: 'justify',
        fontSize: 8,
        lineHeight: 1.5,
      },
    ],
  };
}
