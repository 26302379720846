export default {
  // Info Dealer/Customer
  sectionNames: {
    customerNameLabel: '@:tabs.general.sectionNames.customerNameLabel',
    offeringNumberLabel: 'Offering Number',
  },

  // Info Vehicle
  sectionVehicle: {
    title: '@:tabs.general.sectionVehicle.title',
    yearLabel: '@:tabs.general.sectionVehicle.yearLabel',
    makeLabel: '@:tabs.general.sectionVehicle.makeLabel',
    modelLabel: '@:tabs.general.sectionVehicle.modelLabel',
    vinLabel: '@:tabs.general.sectionVehicle.vinLabel',
  },

  // Equipments
  sectionEquipments: {
    title: '[NT] Added Equipment',
    addEquipExcludedLabel: '[NT] Add’t Equip Excluded From Cost',
    factoryInvoice: '[NT] Factory Invoice',
    capitalizedInitialTitleLabel: '[NT] Capitalized Initial Title, Lic., Reg.',
    capitalizedInitialTitleInfoTax: '[NT] Taxable',
    capitalizedInitialTitleInfoNoTax: '[NT] No Tax',
    upFrontTaxesPaidByDealer: '[NT] Up Front Taxes Paid By Dealer',
    areTaxesCapitalized: '[NT] Are Taxes Capitalized',
  },

  // Up Front
  sectionUpFront: {
    title: '[NT] Up Front Taxes Paid By Customer',
    localTaxLabel: '[NT] Local Tax (%)',
    stateTaxLabel: '[NT] State Tax (%)',
    fetTaxLabel: '[NT] FET Tax (%)',
    otherTaxLabel: '[NT] Other Tax (%)',
  },

  // Services and Taxes
  sectionTaxes: {
    title: '@:tabs.quickQuote.sectionTaxes.title',
    title2: '[NT]  Terms',
    maintenancePlanLabel: '[NT] Maintenance Plan',
    serviceContractLabel: '[NT] Service Contract',
    gapLabel: '[NT] GAP',
    dropdownLabel: '[NT] Label Taxes',
    dropdownOption1: '[NT] Monthly',
    dropdownOption2: '[NT] UpFront',
    dropdownOption3: '[NT] No Tax',
    vehicleMarkupLabel: '[NT] Vehicle Markup',
    capitalizedCostReductionLabel: '[NT] Capitalized Cost Reduction (Cash)',
    rebatesLabel: '[NT] Rebates',
    tradeAllowanceLabel: '[NT] Trade Allowance',
    tradeOwedLabel: '[NT] Trade Owed',
    residualLabel: '@:tabs.quickQuote.sectionTaxes.residualLabel',
    customerLeaseRateLabel: '[NT] Customer Lease Rate (%)',
    customerLeaseRateInfo: '[NT] Includes Dealer Participation',
    dealerParticipationLabel: '[NT] Dealer Participation (%)',
    termLabel: '[NT] Term',
    manufacturingRepurchase: '[NT] Manufacturing Repurchase',
    supplementDateLabel: '[NT] Supplement Date',
    paymentDueDayLabel: '[NT] Payment Due Day',
    paymentTimingLabel: '@:tabs.quickQuote.sectionTaxes.paymentTimingLabel',
    paymentTimingOption1: '@:tabs.quickQuote.sectionTaxes.paymentTimingOption1',
    paymentTimingOption2: '@:tabs.quickQuote.sectionTaxes.paymentTimingOption2',
    vehicleMSRPLabel: '[NT] Vehicle MSRP',
    cityTaxLabel: '[NT] City Tax (%)',
    countyTaxLabel: '[NT] County Tax (%)',
    stateTaxLabel: '[NT] State Tax (%)',
    otherTaxLabel: '[NT] Other Tax (%)',
    totalTaxLabel: '[NT] Total Tax (%)',
  },

  // PDF
  sectionPDF: {
    title: '@:mainTitle',
    subtitle: '[NT] CommerciaLease Capitalized Cost Worksheet',
    customerNameLabel: '@:tabs.capitalCost.sectionNames.customerNameLabel',
    offeringNumberLabel: '@:tabs.capitalCost.sectionNames.offeringNumberLabel',

    // Info Vehicle
    sectionVehicle: {
      title: '@:tabs.general.sectionVehicle.title',
      yearLabel: '@:tabs.general.sectionVehicle.yearLabel',
      makeLabel: '@:tabs.general.sectionVehicle.makeLabel',
      modelLabel: '@:tabs.general.sectionVehicle.modelLabel',
      vinLabel: '@:tabs.general.sectionVehicle.vinLabel',
    },

    // Equipments
    sectionEquipments: {
      title: '[NT] Equipments',
      factoryInvoiceLabel: '[NT] Factory invoice of Vehicle',
      addedEquipmentsLabel: '[NT] Added Equipments',
      equipmentsValueLabel: '[NT] Equipments Value',
      totalCostLabel: '[NT] Total Cost Added Equipments',
      totalInvoiceLabel: '[NT] Total Invoice (Vehicle Plus Added Equipment)',
      vehicleMarkupLabel: '[NT] Vehicle Markup',
      additionalEquipmentLabel: '[NT] Additional Equipment Excluded From Total Invoice',
      capitalizedInitialTitleLabel: '@:tabs.capitalCost.sectionEquipments.capitalizedInitialTitleLabel',
    },

    // Up Front Taxes
    sectionUpFront: {
      title: '[NT] Up Front Taxes',
      table: {
        localTax: '@:tabs.capitalCost.sectionUpFront.localTaxLabel',
        stateTax: '@:tabs.capitalCost.sectionUpFront.stateTaxLabel',
        fetTax: '@:tabs.capitalCost.sectionUpFront.fetTaxLabel',
        otherTax: '@:tabs.capitalCost.sectionUpFront.otherTaxLabel',
        totalTax: '[NT] Total Up Front Taxes',
        upFrontTax: '[NT] (Capitalized)',
      },
      capitalizedCostLabel: '[NT] Capitalized Cost',
      serviceContractLabel: '[NT] Service Contract',
      warrantyCostLabel: '[NT] Warranty Cost',
      gapLabel: '@:tabs.capitalCost.sectionTaxes.gapLabel',
      capitalizedCostReductionLabel: '@:tabs.effectiveRate.sectionTaxes.capitalizedCostReductionLabel',
      paintlessDentRepairLabel: '@:tabs.effectiveRate.sectionTaxes.paintlessDentRepairLabel',
      tireWheelLabel: '@:tabs.effectiveRate.sectionTaxes.tireWheelLabel',
      windshieldLabel: '@:tabs.effectiveRate.sectionTaxes.windshieldLabel',
      bundledProductLabel: '@:tabs.effectiveRate.sectionTaxes.bundledProductLabel',
      rebatesLabel: '@:tabs.effectiveRate.sectionTaxes.rebatesLabel',
      tradeAllowanceLabel: '@:tabs.effectiveRate.sectionTaxes.tradeAllowanceLabel',
      tradeOwedLabel: '@:tabs.effectiveRate.sectionTaxes.tradeOwedLabel',
      netTradeAllowanceLabel: '[NT] Net Trade Allowance',
      finalNetCapitalizedCostLabel: '[NT] Final Net Capitalized Cost (Not to exceed 125% of Total Invoice plus added equipment)',

      subtitle: '[NT] Note: Enter Final Net Capitalized Cost as Capitalized Cost on Supplement',
      assumedResidualPercentLabel: '[NT] Assumed Residual Percent (%)',
      vehicleMSRPLabel: '@:tabs.capitalCost.sectionTaxes.vehicleMSRPLabel',
      table1: {
        leftTitle1: '[NT] Customer Rate',
        leftTitle2: '[NT] Term',
        leftTitle3: '[NT] Supplement Date',
        leftTitle4: '[NT] Commencement Date',
        leftTitle5: '[NT] Payment Due Day',
        leftTitle6: '[NT] Dealer Participation',
        leftTitle7: '[NT] Local Tax',
        leftTitle8: '[NT] State Tax',
        leftTitle9: '[NT] FET',
        leftTitle10: '[NT] Other Tax',
        leftTitle11: '[NT] Total Up Front Taxes',
      },
      table2: {
        topTitle1: '[NT] Base Payment (w/o Tax)',
        topTitle2: '[NT] Tax',
        topTitle3: '[NT] Payment Including Tax',
        topTitle4: '[NT] First Payment Scheduled Due Date*',
        topTitle5: '[NT] Manufacturer Repurchase',
        leftSubtitle1: '[NT] Advance Payment',
        leftSubtitle2: '[NT] Arrears Payment',
        information: '[NT] * Advance first payments are paid at dealership when executing supplement',
        arrearsAttention: '[NT] Please confirm with the Business Center prior to sending Arrears contracts for funding to ensure appropriate billing dates',
      },
    },

    // Signature and Disclaimer
    sectionSignature: {
      customerName: '@:tabs.general.sectionSignature.customerName',
      dealerName: '@:tabs.general.sectionSignature.dealerName',
      signature: '@:tabs.general.sectionSignature.signature',
    },
  },
};
