import settings from '@/plugins/pdfSettings';
import { ConvertToNumber } from '@/utilities/functions/pdf';

const stackedColumn = (label, paragraph, config = {}) => ({
  width: config.columnWidth || '25%',
  stack: [
    {
      text: label,
      fontSize: 8,
      margin: [0, 0, 0, 5],
    },
    {
      text: paragraph,
      margin: [0, 0, 0, 0],
    },
  ],
});
const tableHeaderColumn = (title, config = {}) => ({
  text: title,
  fontSize: 8,
  color: settings.variables.colors.primary,
  alignment: config.alignment || 'center',
  margin: config.margin || [10, 20, 0, 0],
  border: config.border || false,
});
const tableLineColumn = (info, config = {}) => ({
  text: info,
  fontSize: 10,
  color: config.color || settings.variables.colors.text,
  alignment: config.alignment || 'center',
  margin: config.margin || [0, 12, 0, 0],
  border: config.border || false,
});

export default function (data) {
  const tableLabels = data.labels.table;
  const tableInfo = data.info.table;

  return {
    defaultStyle: settings.defaultStyle,
    pageMargins: settings.pageMargins,
    styles: {
      heading: {
        fontSize: 13,
        color: settings.variables.colors.primary,
        margin: [0, 40, 0, 20],
      },
      subheading: {
        fontSize: 12,
        color: settings.variables.colors.primary,
        margin: [0, 40, 0, 32],
      },
    },
    content: [
      settings.components.header,
      settings.components.title(data.labels.subtitle),
      {
        columns: [
          stackedColumn(data.labels.vehicle.yearLabel, data.info.vehicle.year),
          stackedColumn(data.labels.vehicle.makeLabel, data.info.vehicle.make),
          stackedColumn(data.labels.vehicle.modelLabel, data.info.vehicle.model),
          stackedColumn(data.labels.vehicle.vin, data.info.vehicle.vin),
        ],
      },
      {
        margin: [0, 70, 0, 0],
        layout: {
          defaultBorder: false,
        },
        table: {
          headerRows: 0,
          widths: ['20%', '16%', '16%', '16%', '16%', '16%'],
          body: [
            [
              {
                text: '',
                fontSize: 10,
                margin: [10, 5, 0, 5],
              },
              {
                text: data.labels.table.topInfo1,
                fontSize: 10,
                alignment: 'center',
                margin: [0, 5, 0, 5],
                border: [true, true, true, false],
              },
              {
                text: data.labels.table.topInfo2,
                fontSize: 10,
                alignment: 'center',
                margin: [0, 5, 0, 5],
                colSpan: 4,
                border: [true, true, true, false],
              },
            ],
          ],
        },
      },
      {
        layout: {
          fillColor: (rowIndex) => ((rowIndex % 2 === 0) ? '#F2F2F2' : '#FFFFFF'),
          defaultBorder: false,
        },
        table: {
          headerRows: 1,
          widths: ['20%', '16%', '16%', '16%', '16%', '16%'],
          heights: (row) => (row === 0 ? 50 : 20),
          body: [
            [
              tableHeaderColumn(tableLabels.topTitle1, { alignment: 'left' }),
              tableHeaderColumn(tableLabels.topTitle2, { border: [true, false, true, false] }),
              tableHeaderColumn(tableLabels.topTitle3, { margin: [0, 16, 0, 0] }),
              tableHeaderColumn(tableLabels.topTitle4, { margin: [0, 16, 0, 0] }),
              tableHeaderColumn(tableLabels.topTitle5, { alignment: 'left' }),
              tableHeaderColumn(tableLabels.topTitle6, {
                margin: [0, 20, 0, 0],
                border: [false, false, true, false],
              }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle1, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.salePrice.trac, { border: [true, false, true, false] }),
              tableLineColumn(tableInfo.salePrice.tracAdvance),
              tableLineColumn(tableInfo.salePrice.tracArrears),
              tableLineColumn(tableInfo.salePrice.retail),
              tableLineColumn(tableInfo.salePrice.alternateRetail, {
                border: [false, false, true, false],
              }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle2, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.fleetConcessions.trac, {
                border: [true, false, true, false],
              }),
              tableLineColumn(tableInfo.fleetConcessions.tracAdvance),
              tableLineColumn(tableInfo.fleetConcessions.tracArrears),
              tableLineColumn(tableInfo.fleetConcessions.retail),
              tableLineColumn(tableInfo.fleetConcessions.alternateRetail, {
                border: [false, false, true, false],
              }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle3, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.downPayment.trac, {
                border: [true, false, true, false],
                margin: [0, 5, 0, 0],
              }),
              tableLineColumn(tableInfo.downPayment.tracAdvance, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.downPayment.tracArrears, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.downPayment.retail, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.downPayment.alternateRetail, {
                border: [false, false, true, false],
                margin: [0, 5, 0, 0],
              }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle4, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.serviceContract.trac, {
                border: [true, false, true, false],
              }),
              tableLineColumn(tableInfo.serviceContract.tracAdvance),
              tableLineColumn(tableInfo.serviceContract.tracArrears),
              tableLineColumn(tableInfo.serviceContract.retail),
              tableLineColumn(tableInfo.serviceContract.alternateRetail, {
                border: [false, false, true, false],
              }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle5, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.capitalizedCost.trac, {
                border: [true, false, true, false],
              }),
              tableLineColumn(tableInfo.capitalizedCost.tracAdvance),
              tableLineColumn(tableInfo.capitalizedCost.tracArrears),
              tableLineColumn(tableInfo.capitalizedCost.retail),
              tableLineColumn(tableInfo.capitalizedCost.alternateRetail, {
                border: [false, false, true, false],
              }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle6, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.term.trac, {
                border: [true, false, true, false],
                margin: [0, 5, 0, 0],
              }),
              tableLineColumn(tableInfo.term.tracAdvance, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.term.tracArrears, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.term.retail, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.term.alternateRetail, {
                border: [false, false, true, false],
                margin: [0, 5, 0, 0],
              }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle7, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.residualPercent.trac, {
                border: [true, false, true, false],
                margin: [0, 5, 0, 0],
              }),
              tableLineColumn(tableInfo.residualPercent.tracAdvance, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.residualPercent.tracArrears, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.residualPercent.retail, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.residualPercent.alternateRetail, {
                border: [false, false, true, false],
                margin: [0, 5, 0, 0],
              }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle8, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.residualMoney.trac, {
                border: [true, false, true, false],
                margin: [0, 5, 0, 0],
              }),
              tableLineColumn(tableInfo.residualMoney.tracAdvance, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.residualMoney.tracArrears, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.residualMoney.retail, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.residualMoney.alternateRetail, {
                border: [false, false, true, false],
                margin: [0, 5, 0, 0],
              }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle9, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.basePayment.trac, {
                border: [true, false, true, false],
              }),
              tableLineColumn(tableInfo.basePayment.tracAdvance),
              tableLineColumn(tableInfo.basePayment.tracArrears),
              tableLineColumn(tableInfo.basePayment.retail),
              tableLineColumn(tableInfo.basePayment.alternateRetail, {
                border: [false, false, true, false],
              }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle10, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.rateFees.trac, {
                border: [true, false, true, false],
                margin: [0, 5, 0, 0],
              }),
              tableLineColumn(tableInfo.rateFees.tracAdvance, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.rateFees.tracArrears, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.rateFees.retail, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.rateFees.alternateRetail, {
                border: [false, false, true, false],
                margin: [0, 5, 0, 0],
              }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle11, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.upfrontFees.trac, {
                border: [true, false, true, false],
                margin: [0, 5, 0, 0],
              }),
              tableLineColumn(tableInfo.upfrontFees.tracAdvance, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.upfrontFees.tracArrears, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.upfrontFees.retail, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.upfrontFees.alternateRetail, {
                border: [false, false, true, false],
                margin: [0, 5, 0, 0],
              }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle12, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.monthlyManagement.trac, {
                border: [true, false, true, false],
              }),
              tableLineColumn(tableInfo.monthlyManagement.tracAdvance),
              tableLineColumn(tableInfo.monthlyManagement.tracArrears),
              tableLineColumn(tableInfo.monthlyManagement.retail),
              tableLineColumn(tableInfo.monthlyManagement.alternateRetail, {
                border: [false, false, true, false],
              }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle13, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.leaseEndServiceCharge.trac, {
                border: [true, false, true, false],
              }),
              tableLineColumn(tableInfo.leaseEndServiceCharge.tracAdvance),
              tableLineColumn(tableInfo.leaseEndServiceCharge.tracArrears),
              tableLineColumn(tableInfo.leaseEndServiceCharge.retail),
              tableLineColumn(tableInfo.leaseEndServiceCharge.alternateRetail, {
                border: [false, false, true, false],
              }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle14, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.effectiveRateFees.trac, {
                border: [true, false, true, false],
                margin: [0, 5, 0, 0],
              }),
              tableLineColumn(tableInfo.effectiveRateFees.tracAdvance, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.effectiveRateFees.tracArrears, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.effectiveRateFees.retail, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.effectiveRateFees.alternateRetail, {
                border: [false, false, true, false],
                margin: [0, 5, 0, 0],
              }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle15, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.monthlyMaintenance.trac, {
                border: [true, false, true, false],
              }),
              tableLineColumn(tableInfo.monthlyMaintenance.tracAdvance),
              tableLineColumn(tableInfo.monthlyMaintenance.tracArrears),
              tableLineColumn(tableInfo.monthlyMaintenance.retail),
              tableLineColumn(tableInfo.monthlyMaintenance.alternateRetail, {
                border: [false, false, true, false],
              }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle16, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.paymentCharges.trac, {
                border: [true, false, true, true],
              }),
              tableLineColumn(tableInfo.paymentCharges.tracAdvance, {
                border: [true, false, false, true],
              }),
              tableLineColumn(tableInfo.paymentCharges.tracArrears, {
                border: [false, false, false, true],
              }),
              tableLineColumn(tableInfo.paymentCharges.retail, {
                border: [false, false, false, true],
              }),
              tableLineColumn(tableInfo.paymentCharges.alternateRetail, {
                border: [false, false, true, true],
              }),
            ],
          ],
        },
      },
      {
        margin: [0, 50, 0, 0],
        layout: {
          fillColor: (rowIndex) => ((rowIndex % 2 === 0) ? '#F2F2F2' : '#FFFFFF'),
          defaultBorder: false,
        },
        table: {
          headerRows: 0,
          widths: ['20%', '16%', '16%', '16%', '16%', '16%'],
          body: [
            [
              tableLineColumn(tableLabels.leftSubtitle17, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.totalOfPayments.trac, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.totalOfPayments.tracAdvance, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.totalOfPayments.tracArrears, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.totalOfPayments.retail, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.totalOfPayments.alternateRetail, { margin: [0, 5, 0, 0] }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle18, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.totalFees.trac, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.totalFees.tracAdvance, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.totalFees.tracArrears, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.totalFees.retail, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.totalFees.alternateRetail, { margin: [0, 5, 0, 0] }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle19, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.residual.trac, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.residual.tracAdvance, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.residual.tracArrears, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.residual.retail, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.residual.alternateRetail, { margin: [0, 5, 0, 0] }),
            ],
            [
              tableLineColumn(tableLabels.leftSubtitle20, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.totalExpense.trac, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.totalExpense.tracAdvance, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.totalExpense.tracArrears, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.totalExpense.retail, { margin: [0, 5, 0, 0] }),
              tableLineColumn(tableInfo.totalExpense.alternateRetail, { margin: [0, 5, 0, 0] }),
            ],
          ],
        },
      },
      {
        margin: [0, 50, 0, 0],
        layout: {
          fillColor: '#F2F2F2',
          defaultBorder: false,
        },
        table: {
          headerRows: 0,
          widths: ['20%', '16%', '16%', '16%', '16%', '16%'],
          body: [
            [
              tableLineColumn(tableLabels.leftSubtitle21, {
                alignment: 'left',
                margin: [10, 5, 0, 5],
              }),
              tableLineColumn(tableInfo.totalSavings.trac, {
                margin: [0, 5, 0, 0],
                color: ConvertToNumber(tableInfo.totalSavings.trac) < 0
                  ? settings.variables.colors.danger : '',
              }),
              tableLineColumn(tableInfo.totalSavings.tracAdvance, {
                margin: [0, 5, 0, 0],
                color: ConvertToNumber(tableInfo.totalSavings.tracAdvance) < 0
                  ? settings.variables.colors.danger : '',
              }),
              tableLineColumn(tableInfo.totalSavings.tracArrears, {
                margin: [0, 5, 0, 0],
                color: ConvertToNumber(tableInfo.totalSavings.tracArrears) < 0
                  ? settings.variables.colors.danger : '',
              }),
              tableLineColumn(tableInfo.totalSavings.retail, {
                margin: [0, 5, 0, 0],
                color: ConvertToNumber(tableInfo.totalSavings.retail) < 0
                  ? settings.variables.colors.danger : '',
              }),
              tableLineColumn(tableInfo.totalSavings.alternateRetail, {
                margin: [0, 5, 0, 0],
                color: ConvertToNumber(tableInfo.totalSavings.alternateRetail) < 0
                  ? settings.variables.colors.danger : '',
              }),
            ],
          ],
        },
      },
      {
        columns: [
          {
            width: '55%',
            text: `${data.labels.signature.customerName}: ${data.info.customerName}`,
            noWrap: true,
            color: settings.variables.colors.text,
            margin: [0, 50, 0, 0],
          },
          {
            width: 'auto',
            text: `${data.labels.signature.signature}:`,
            color: settings.variables.colors.text,
            margin: [0, 50, 0, 0],
          },
          {
            svg: `
            <svg width="200" height="1" viewBox="0 0 200 1" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="0.5" x2="180" y2="0.5" stroke="black"/>
            </svg>
            `,
            margin: [0, 60, 0, 0],
          },
        ],
        columnGap: 5,
      },
      {
        columns: [
          {
            width: '55%',
            text: `${data.labels.signature.dealerName}: ${data.info.dealerName}`,
            noWrap: true,
            color: settings.variables.colors.text,
            margin: [0, 15, 0, 0],
          },
          {
            width: 'auto',
            text: `${data.labels.signature.signature}:`,
            color: settings.variables.colors.text,
            margin: [0, 15, 0, 0],
          },
          {
            svg: `
            <svg width="200" height="1" viewBox="0 0 200 1" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="0.5" x2="180" y2="0.5" stroke="black"/>
            </svg>
            `,
            margin: [0, 25, 0, 0],
          },
        ],
        columnGap: 5,
      },
      {
        text: data.labels.signature.disclaimer,
        color: settings.variables.colors.text,
        margin: [0, 50, 0, 0],
        alignment: 'justify',
        fontSize: 10,
        bold: true,
        lineHeight: 1.5,
      },
      {
        text: data.labels.signature.disclaimerText1,
        color: settings.variables.colors.text,
        margin: [0, 5, 0, 0],
        alignment: 'justify',
        fontSize: 8,
        lineHeight: 1.5,
      },
      {
        text: data.labels.signature.disclaimerText2,
        color: settings.variables.colors.text,
        margin: [0, 5, 0, 0],
        alignment: 'justify',
        fontSize: 8,
        lineHeight: 1.5,
      },
    ],
  };
}
