<template>
  <button class="tab"
    type="button"
    :class="{ 'tab--active': isActive }"
    @focus="$emit('click', $event.target)"
    @click="$emit('click', $event.target)"
  >
    <span class="tab__text">
      <slot />
    </span>
    <span class="tab__follow-line" />
  </button>
</template>

<script>
export default {
  name: 'TabItem',
  emits: ['click'],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  position: relative;
  display: flex;
  justify-content: center;
  width: 20%;
  padding: 0 fds-rem(30px) fds-rem(10px) fds-rem(30px);
  color: $fds-color--gray3;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid transparent;

  &:hover,
  &--active {
    color: $fds-color--secondary;
  }

  &:hover:not(.tab--active) {
    border-color: $fds-color--secondary;
  }

  &--active {
    .tab__follow-line {
      width: 100%;

      @include media('>=900px') {
        display: none;
      }
    }
  }

  &__follow-line {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 3px;
    background-color: $fds-color--primary;
    transform: translateX(-50%);
    transition: all .3s ease-in;

    @include media('>=900px') {
      display: none;
    }
  }

  &__text {
    pointer-events: none;
  }
}
</style>
