<template>
  <FloatingButton
    v-bind="$attrs"
    :show="show"
    :text="$t('tabs.general.sectionActions.buttonScrollToTop')"
    icon="chevron-up"
    @onClick="scrollUp"
  />
</template>
<script>
import FloatingButton from '@/components/form-elements/FloatingButton.vue';

export default {
  name: 'ScrollToTopButton',
  components: { FloatingButton },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    const elementScroller = document.getElementById('main-content');
    elementScroller.addEventListener('scroll', () => {
      this.show = elementScroller.scrollTop > 300;
    });
  },
  methods: {
    scrollUp() {
      const elementScroller = document.getElementById('main-content');
      elementScroller.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      this.show = false;

      this.$emit('scrollToTop');
    },
  },
};
</script>
