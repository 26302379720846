<template>
  <div :class="dialogClasses">
    <div class="fds-modal-dialog--overlay"></div>
    <div class="fds-modal-dialog--wrapper">
      <span
        class="fds-modal-dialog--close fds-icon--12 fds-font--ford-icons__clear"
        @click="close()"
        aria-hidden
      />
      <div v-if="imgUrl" class="fds-modal-dialog--media">
        <img class="fds-modal-dialog--img" :src="imgUrl" :alt="imgAlt">
      </div>
      <h4 class="fds-modal-dialog--title" v-html="title"></h4>
      <div class="fds-modal-dialog--content" v-html="content"></div>
      <div class="fds-modal-dialog--actions">
        <Button
          class="fds-modal-dialog--button"
          id="primary-dialog"
          :label="continueText"
          @onClick="handleButtonClick('yes')"
        />
        <Button
          v-if="isDecision"
          class="fds-modal-dialog--button"
          id="secondary-dialog"
          :label="cancelText"
          outlined
          @onClick="handleButtonClick('no')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/components/form-elements/Button.vue';

export default {
  components: { Button },
  name: 'ModalDialog',
  props: {
    isDecision: { type: Boolean, default: false },
    title: { type: String, required: true },
    content: { type: String, required: true },
    imgUrl: { type: String, required: false },
    imgAlt: { type: String, required: false },
    continueText: { type: String, required: true },
    cancelText: { type: String, required: false },
    show: { type: Boolean, default: false },
  },
  computed: {
    dialogClasses() {
      return {
        'fds-modal-dialog': true,
        'fds-modal-dialog--active': this.show,
      };
    },
  },
  methods: {
    handleButtonClick(value) {
      this.$store.dispatch('notifications/saveModalResponse', value);
      this.$emit('response');
      this.close();
    },
    close() {
      this.$store.dispatch('notifications/hideModalDialog');
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss">
.fds-modal-dialog {
  pointer-events: none;
  opacity: 0;

  &.fds-modal-dialog--active {
    pointer-events: all;
    opacity: 1;
  }

  .fds-modal-dialog--overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $fds-color--black-70;
    z-index: 100;
  }

  .fds-modal-dialog--wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    width: calc(100% - 6rem);
    max-width: 600px;
    padding: fds-rem(20px 30px 45px);
    background-color: $fds-color--white;
    transform: translate(-50%, -50%);
    box-shadow: $fds-elevation__box-shadow--layer3;
    z-index: 110;

    .fds-modal-dialog--close {
      display: block;
      margin: fds-rem(0 -10px 15px 0);
      text-align: right;
      cursor: pointer;
      z-index: 1;
    }

    .fds-modal-dialog--media,
    .fds-modal-dialog--content {
      margin-bottom: fds-rem(30px);
    }

    .fds-modal-dialog--title {
      margin-bottom: fds-rem(20px);
      color: $fds-color--primary;
      font-size: fds-rem(24px);
    }

    .fds-modal-dialog--content {
      *:not(a) {
        color: $fds-color--gray3;
        font-size: fds-rem(14px);

        @include media('>=1440px') {
          font-size: fds-rem(16px);
        }
      }

      p {
        margin: fds-rem(10px 0);
      }
    }

    .fds-modal-dialog--button {
      margin-right: fds-rem(30px);
    }
  }
}
</style>
