import quickQuote from './modules/quickQuote';
import effectiveRate from './modules/effectiveRate';
import paymentComparison from './modules/paymentComparison';
import capitalCost from './modules/capitalCost';

export default {
  header: {
    logo: {
      alternativeText: 'Ford Pro FinSimple',
    },
    userInfo: {
      icon: 'Log out',
    },
    toggleLang: {
      labelLeft: 'English',
      labelRight: 'French',
    },
  },
  mainTitle: 'Ford Credit Calculatore Conversion',
  tabs: {
    titles: {
      quickQuote: 'Cotation rapide',
      quickQuoteInfo: '',
      effectiveRate: 'Taux effectif',
      effectiveRateInfo: '',
      paymentComparison: 'Payment Comparison',
      paymentComparisonInfo: '',
      capitalCost: 'Capital Cost',
      capitalCostInfo: '',
    },
    general: {
      // Form validation text
      form: {
        requiredField: 'Ce champ est requis',
        dropdownNoOptions: 'Désolé, il n\'y a pas d\'options.',
        toggleLang: {
          en: 'Anglais',
          fr: 'Français',
        },
        tableEquipment: {
          description: 'La description',
          value: 'Évaluer',
          action: 'Action',
          sum: 'Somme de l\'équipement ajouté',
        },
        modalClearForm: {
          title: 'Effacer le formulaire',
          content: `
          <p>Cette action supprimera tous les renseignements entrés dans ce formulaire. Voulez-vous continuer?</p>`,
        },
        modalClearAllForms: {
          title: 'Effacer tous les formulaires',
          content: `
          <p>Cette action supprimera tous les renseignements entrés tous les formulaire. Voulez-vous continuer?</p>`,
        },
        yesLabel: 'Oui',
        noLabel: 'Non',
      },

      // Info Dealer/Customer
      sectionNames: {
        customerNameLabel: 'Nom du client',
        dealerNameLabel: 'Nom du concessionnaire',
      },

      // Info Vehicle
      sectionVehicle: {
        title: 'Information concernant le véhicule',
        yearLabel: 'Année',
        makeLabel: 'Marque',
        modelLabel: 'Modèle',
        netCapCostLabel: 'Coût cap. Net',
        vinLabel: 'NIV',
      },

      // Signature and Disclaimer (For PDFs)
      sectionSignature: {
        customerName: '@:tabs.general.sectionNames.customerNameLabel',
        dealerName: '@:tabs.general.sectionNames.dealerNameLabel',
        signature: 'Signature',
        disclaimer: 'Désistement de Responsabilité',
        disclaimerTextUSA: 'This is not an offer or approval by Ford Motor Credit Company. This is an estimated payment that may be available to a Lessee based on certain lease assumptions. Actual approval by Ford Credit will be based on Ford Credit’s independent analysis of the actual credit-worthiness of the Lessee and the terms of the proposed transaction and is subject to the final review and concurrence of Ford Motor Credit Company.',
        disclaimerTextCAN: 'Ceci ne constitue pas une offre ou une approbation de Compagnie Crédit Ford du Canada. Il s’agit d’une estimation des paiements qui peuvent être disponibles à un Locataire selon certaines hypothèses de location. L’éventuelle approbation de crédit de Crédit Ford sera basée sur une analyse indépendante de Crédit Ford quant à la solvabilité réelle du Locataire et les modalités de la transaction proposée seront soumises à la vérification et d’approbation finale de Compagnie Crédit Ford du Canada.',
      },

      // Actions
      sectionActions: {
        buttonGeneratePDF: 'Générer un PDF',
        buttonClear: 'Effacer le formulaire',
        buttonClearAll: 'Effacer tous les formulaires',
        buttonCloseAndReturn: 'Fermer Et Retourner au Formulaire',
        buttonScrollToTop: 'Retour en haut de la page',
      },
    },
    quickQuote,
    effectiveRate,
    paymentComparison,
    capitalCost,
  },
};
