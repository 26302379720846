<template>
  <div class="render-pdf">
    <a class="download-button"
      :href="pdfSource"
      download
    >
      <span class="pdf-name">{{ name }}</span>
      <!-- eslint-disable -->
      <svg class="download-button-icon" width="30" height="21" viewBox="0 0 30 21">
        <path
          transform="translate(0 -2.25)"
          d="M25.2,11.372A4.505,4.505,0,0,0,21,5.25a4.477,4.477,0,0,0-2.5.759A7.5,7.5,0,0,0,4.5,9.75c0,.127,0,.253.009.38A6.752,6.752,0,0,0,6.75,23.25H24a6,6,0,0,0,1.2-11.878ZM18.97,15.53,14.03,20.47a.753.753,0,0,1-1.059,0L8.03,15.53a.75.75,0,0,1,.53-1.28h3.066V9a.752.752,0,0,1,.75-.75h2.25a.752.752,0,0,1,.75.75v5.25h3.066A.75.75,0,0,1,18.97,15.53Z"
        />
      </svg>
    </a>
    <embed class="embed" :src="pdfSource" />
  </div>
</template>

<script>
import pdfMake from "@/plugins/pdfmake/pdfmake.js"; // eslint-disable-line
import pdfFonts from "@/plugins/pdfmake/vfs_fonts.js"; // eslint-disable-line

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  antenna: {
    normal: 'antenna-regular.woff',
    bold: 'antenna-regular.woff',
    italics: 'antenna-regular.woff',
    bolditalics: 'antenna-regular.woff',
  },
};

export default {
  name: 'RenderPDF',
  props: {
    name: {
      type: String,
      required: true,
    },
    definition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pdfSource: '',
    };
  },
  watch: {
    definition() {
      this.createPdf();
    },
  },
  mounted() {
    this.createPdf();
  },
  methods: {
    createPdf() {
      const pdfGeneration = pdfMake.createPdf(this.definition);

      pdfGeneration.getDataUrl((dataUrl) => {
        this.pdfSource = dataUrl;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.render-pdf {
  display: flex;
  justify-content: center;
  width: 100%;
  .download-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 152px;
    height: 152px;
    background-color: $fds-color--white;
    border: 1px solid #707070;
    padding: 10px;
  }
  .download-button-icon {
    fill: $fds-color--primary;
  }
  .pdf-name {
    margin-bottom: 16px;
    font-size: 14px;
    color: $fds-color--primary;
    text-align: center;
  }
  .embed {
    display: none;
    width: 700px;
    height: 800px;
  }
  @include media('>=1024px') {
    .download-button {
      display: none;
    }
    .embed {
      display: block;
    }
  }
}
</style>
