export default {
  // Info Dealer/Customer
  sectionNames: {
    customerNameLabel: '@:tabs.general.sectionNames.customerNameLabel',
    dealerNameLabel: '@:tabs.general.sectionNames.dealerNameLabel',
  },

  // Info Vehicle
  sectionVehicle: {
    title: '@:tabs.general.sectionVehicle.title',
    yearLabel: '@:tabs.general.sectionVehicle.yearLabel',
    makeLabel: '@:tabs.general.sectionVehicle.makeLabel',
    modelLabel: '@:tabs.general.sectionVehicle.modelLabel',
    vinLabel: '@:tabs.general.sectionVehicle.vinLabel',
  },

  // Services and Taxes
  sectionTaxes: {
    title: '@:tabs.quickQuote.sectionTaxes.title',
    sellingPriceLabel: 'Prix de vente / coût capitalisé',
    sellingPriceInfo: '(Y compris amenagement)',
    leaseTermLabel: 'Période de location commerciale',
    retailTermLabel: 'Période du financement à l’achat',
    alternateRetailTermLabel: 'Période de l’autre alternative de financement à l’achat',
    cashDownPaymentLabel: 'Versement initial en espèces',
    serviceContractLabel: 'Contrat d’entretien / garantie',
    fleetConcessionsLabel: 'Rabais / concessions parc',
    tradeEquityLabel: 'Valeur d’échange',
    residualLabel: '@:tabs.quickQuote.sectionTaxes.residualLabel',
    customerLeaseRateLabel: '(%) Taux de location commerciale du client',
    customerRetailRateLabel: '(%) Taux de financement à l’achat',
    monthlyMaintenanceChargeLabel: 'Frais d’entretien mensuels',
    alternateRetailRateLabel: '(%) Financement à l’achat alternatif',
  },

  // Competitor Details
  sectionCompetitor: {
    title: 'Détails du concurrent',
    sellingPriceLabel: '@:tabs.effectiveRate.sectionTaxes.sellingPriceLabel',
    sellingPriceInfo: '@:tabs.effectiveRate.sectionTaxes.sellingPriceInfo',
    fleetConcessionsLabel: '@:tabs.effectiveRate.sectionTaxes.fleetConcessionsLabel',
    cashDownPaymentLabel: '@:tabs.effectiveRate.sectionTaxes.cashDownPaymentLabel',
    serviceContractLabel: '@:tabs.effectiveRate.sectionTaxes.serviceContractLabel',
    residualAmountLabel: 'Montant résiduel ($)',
    competitorPaymentLabel: 'Paiement du concurrent',
    paymentTimingLabel: '@:tabs.quickQuote.sectionTaxes.paymentTimingLabel',
    paymentTimingOption1: '@:tabs.quickQuote.sectionTaxes.paymentTimingOption1',
    paymentTimingOption2: '@:tabs.quickQuote.sectionTaxes.paymentTimingOption2',
    upfrontFeesLabel: 'Les frais initiaux',
    monthlyManagementFeesLabel: 'Frais de gestion mensuels',
    monthlyMaintenanceChargeLabel: 'Frais d\'entretien mensuels',
    leaseEndServiceChargeLabel: 'Frais de service de fin de bail',

    // Only for CAN side
    alternativeRetailRateLabel: 'Taux de détail alternatif',
    // Only for CAN French side
    monthlyManagementFeesOption1: '@:tabs.quickQuote.sectionTaxes.paymentTimingOption1',
    monthlyManagementFeesOption2: '@:tabs.quickQuote.sectionTaxes.paymentTimingOption2',
  },

  // Descriptions
  sectionDescription: {
    item1: 'Entrez le prix de vente du véhicule (y compris les aménagements)',
    item2: 'Entrez le total des remises ou des concessions de flotte',
    item3: ' Entrez la valeur du versement initial en espèce (peut-être une exigence de la société de gestion de flotte)',
    item4: 'Entrer la période de contrat de Location commerciale et la période de contrat au détail désirés',
    item5: 'Entrez le % de la valeur résiduelle ',
    item6: 'Entrez les frais initiaux (frais non capitalisés)',
    item7: 'Entrer les frais d’entretien mensuels',
    item8: 'Entrez le $ résiduel du concurrent',
    item9: 'Entrez le paiement mensuel du concurrent (incluez les taxes si capitalisées)',
    item10: 'Entrez les frais de gestion mensuels du concurrent',
    item11: 'Entrez les frais de service de fin de bail du concurrent',
    item12: 'Les champs qui requiert un pourcentage doivent contenir une valeur numérique',
  },

  // PDF
  sectionPDF: {
    title: '@:mainTitle',
    subtitle: 'Outil de calcul du taux effectif',

    // Info Vehicle
    sectionVehicle: {
      title: '@:tabs.general.sectionVehicle.title',
      yearLabel: '@:tabs.general.sectionVehicle.yearLabel',
      makeLabel: '@:tabs.general.sectionVehicle.makeLabel',
      modelLabel: '@:tabs.general.sectionVehicle.modelLabel',
      vinLabel: '@:tabs.general.sectionVehicle.vinLabel',
    },

    // Info Table
    sectionTable: {
      topInfo1: 'Concurrent',
      topInfo2: 'Ford Pro FinSimple',
      topTitle1: 'Type de financement',
      topTitle2: 'CRFT (ex. Gestion de flotte)',
      topTitle3: 'Location commerciale - paiement au début',
      topTitle4: 'Location commerciale - paiement à la fin',
      topTitle5: 'Détail',
      topTitle6: 'Autre alternative de financement à l’achat',
      leftSubtitle1: 'Prix de vente (incluant amenagement)',
      leftSubtitle2: 'Concessions et rabais parc',
      leftSubtitle3: 'Versement initial',
      leftSubtitle4: 'Coût contrat d\'entretien / garantie ',
      leftSubtitle5: 'Coût capitalisé (mnt financé)',
      leftSubtitle6: 'Période',
      leftSubtitle7: '% résiduel',
      leftSubtitle8: '$ résiduel',
      leftSubtitle9: 'Paiement de base sans taxes / frais',
      leftSubtitle10: 'Taux sans frais',
      leftSubtitle11: 'Frais initiaux',
      leftSubtitle12: 'Frais de gestion mensuels',
      leftSubtitle13: 'Frais de service fin de bail',
      leftSubtitle14: 'Taux effectif incl. frais',
      leftSubtitle15: 'Frais d’entretien mensuels',
      leftSubtitle16: 'Paiement avec frais d’entretien',
      leftSubtitle17: 'Total des paiements',
      leftSubtitle18: 'Frais totaux',
      leftSubtitle19: 'Résiduel',
      leftSubtitle20: 'Dépense totale',
      leftSubtitle21: 'Total des epargnes',
    },

    // Signature and Disclaimer
    sectionSignature: {
      customerName: '@:tabs.general.sectionSignature.customerName',
      dealerName: '@:tabs.general.sectionSignature.dealerName',
      signature: '@:tabs.general.sectionSignature.signature',
      disclaimer: 'Divulgation',
      disclaimerTextUSA: '@:tabs.general.sectionSignature.disclaimerTextUSA',
      disclaimerTextCAN1: 'Ceci ne constitue pas d’une demande ou d’une approbation par Compagnie Crédit Ford du Canada. Il s’agit d’un paiement estimatif qui pourrait être offert à un locataire selon certaines considérations de location. La vraie approbation de Compagnie Crédit Ford du Canada sera basée sur une analyse indépendante de la solvabilité réelle du locataire et des conditions de la transaction proposée et est assujettie à l’évaluation et à l’accord final de Compagnie Crédit Ford du Canada.',
      disclaimerTextCAN2: 'Cet outil de vente est fourni à titre gracieux par Compagnie Crédit Ford du Canada. Bien que nous avons tenté de le rendre utile, nous ne garantissons pas les résultats de vente ou l’exactitude des calculs, et n’assumons aucune responsabilité quant à la façon dont cet outil est utilisé. La décision d’utiliser cet outil est à l’entière discrétion de votre établissement concessionnaire et la direction de l’établissement doit en effectuer une étude approfondie.',
    },
  },
};
