import settings from '@/plugins/pdfSettings';

const stackedColumn = (label, paragraph, config = {}) => ({
  width: config.columnWidth || '25%',
  stack: [
    {
      text: label,
      fontSize: 8,
      margin: [0, 0, 0, 5],
    },
    {
      text: paragraph,
      margin: [0, 0, 0, 0],
    },
  ],
});

const tableLine = (info) => ({
  text: info,
  fontSize: 10,
  color: '#4D4D4D',
  margin: [10, 5, 0, 4],
});

export default function (data) {
  return {
    defaultStyle: settings.defaultStyle,
    pageMargins: settings.pageMargins,
    styles: {
      heading: {
        fontSize: 13,
        color: settings.variables.colors.primary,
        margin: [0, 40, 0, 20],
      },
      subheading: {
        fontSize: 12,
        color: settings.variables.colors.primary,
        margin: [0, 40, 0, 32],
      },
      notes: {
        fontSize: 13,
        color: settings.variables.colors.primary,
        margin: [5, 5, 5, 0],
      },
    },
    content: [
      settings.components.header,
      settings.components.title(data.labels.subtitle),
      {
        columns: [
          {
            width: '33%',
            text: data.info.customerName,
          },
          {
            width: '33%',
            text: data.info.dealerName,
            alignment: 'center',
          },
          {
            width: '33%',
            text: data.info.date,
            alignment: 'right',
          },
        ],
      },
      {
        text: data.labels.vehicle.title,
        style: 'heading',
      },
      {
        margin: [0, 0, 0, 25],
        columns: [
          stackedColumn(data.labels.vehicle.yearLabel, data.info.vehicle.year),
          stackedColumn(data.labels.vehicle.makeLabel, data.info.vehicle.make),
          stackedColumn(data.labels.vehicle.modelLabel, data.info.vehicle.model),
        ],
      },
      {
        margin: [0, 0, 0, 25],
        columns: [
          stackedColumn(data.labels.vehicle.commercialLeaseTermLabel, data.info.vehicle.clt),
          stackedColumn(data.labels.vehicle.vin, data.info.vehicle.vin),
        ],
      },
      {
        columnGap: 40,
        margin: [0, 0, 0, 10],
        columns: [
          {
            width: '50%',
            text: data.labels.tableRetail.title,
            color: '#00095B',
          },
          {
            width: '50%',
            text: data.labels.tableCommercialLease.title,
            color: '#00095B',
          },
        ],
      },
      {
        columnGap: 40,
        columns: [
          {
            width: '50%',
            layout: {
              fillColor: (rowIndex) => ((rowIndex % 2 === 0) ? '#F2F2F2' : '#FFFFFF'),
              defaultBorder: false,
              vLineColor: '#FFFFFF',
            },
            table: {
              headerRows: 1,
              widths: ['65%', '35%'],
              body: [
                [
                  tableLine(data.labels.tableRetail.topSubtitle1),
                  tableLine(data.info.tableRetail.sellingPrice),
                ],
                [
                  tableLine(data.labels.tableRetail.topSubtitle2),
                  tableLine(data.info.tableRetail.cashDownPayment),
                ],
                [
                  tableLine(data.labels.tableRetail.topSubtitle3),
                  tableLine(data.info.tableRetail.tradeEquity),
                ],
                [
                  tableLine(data.labels.tableRetail.topSubtitle4),
                  tableLine(data.info.tableRetail.rebate),
                ],
                [
                  tableLine(data.labels.tableRetail.topSubtitle5),
                  tableLine(data.info.tableRetail.localSaleTaxRate),
                ],
                [
                  tableLine(data.labels.tableRetail.topSubtitle6),
                  tableLine(data.info.tableRetail.amountFinanced),
                ],
                [
                  tableLine(data.labels.tableRetail.topSubtitle7),
                  tableLine(data.info.tableRetail.term),
                ],
                [
                  tableLine(data.labels.tableRetail.topSubtitle8),
                  tableLine(data.info.tableRetail.customerRate),
                ],
                [
                  tableLine(data.labels.tableRetail.topSubtitle9),
                  tableLine(data.info.tableRetail.financeCharge),
                ],
                [
                  tableLine(data.labels.tableRetail.topSubtitle10),
                  tableLine(data.info.tableRetail.paymentWithTax),
                ],
                [
                  {
                    text: '* This may be taxable please verify with the State',
                    fontSize: 9,
                    color: '#4D4D4D',
                    margin: [10, 40, 0, 4],
                    colSpan: 2,
                    italics: true,
                    fillColor: '#ffffff',
                  },
                ],
              ],
            },
          },
          {
            width: '50%',
            layout: {
              fillColor: (rowIndex) => ((rowIndex % 2 === 0) ? '#F2F2F2' : '#FFFFFF'),
              defaultBorder: false,
              vLineColor: '#FFFFFF',
            },
            table: {
              headerRows: 1,
              widths: ['65%', '35%'],
              body: [
                [
                  tableLine(data.labels.tableCommercialLease.topSubtitle1),
                  tableLine(data.info.tableCommercialLease.capCost),
                ],
                [
                  tableLine(data.labels.tableCommercialLease.topSubtitle2),
                  tableLine(data.info.tableCommercialLease.cashDownPayment),
                ],
                [
                  tableLine(data.labels.tableCommercialLease.topSubtitle3),
                  tableLine(data.info.tableCommercialLease.tradeEquity),
                ],
                [
                  tableLine(data.labels.tableCommercialLease.topSubtitle4),
                  tableLine(data.info.tableCommercialLease.capCostReduction),
                ],
                [
                  tableLine(data.labels.tableCommercialLease.topSubtitle5),
                  tableLine(data.info.tableCommercialLease.localSalesTaxRate),
                ],
                [
                  tableLine(data.labels.tableCommercialLease.topSubtitle6),
                  tableLine(data.info.tableCommercialLease.netCapCost),
                ],
                [
                  tableLine(data.labels.tableCommercialLease.topSubtitle7),
                  tableLine(data.info.tableCommercialLease.term),
                ],
                [
                  tableLine(data.labels.tableCommercialLease.topSubtitle8),
                  tableLine(data.info.tableCommercialLease.residual),
                ],
                [
                  tableLine(data.labels.tableCommercialLease.topSubtitle9),
                  tableLine(data.info.tableCommercialLease.paymentsInAdvance),
                ],
                [
                  tableLine(data.labels.tableCommercialLease.topSubtitle10),
                  tableLine(data.info.tableCommercialLease.customerRate),
                ],
                [
                  tableLine(data.labels.tableCommercialLease.topSubtitle11),
                  tableLine(data.info.tableCommercialLease.residualAmount),
                ],
                [
                  tableLine(data.labels.tableCommercialLease.topSubtitle12),
                  tableLine(data.info.tableCommercialLease.basePayment),
                ],
                [
                  tableLine(data.labels.tableCommercialLease.topSubtitle13),
                  tableLine(data.info.tableCommercialLease.paymentWithTax),
                ],
              ],
            },
          },
        ],
      },
      { text: '&nbsp;', color: '#FFF' },
      {
        columnGap: 40,
        margin: [0, 100, 0, 0],
        columns: [
          {
            width: '100%',
            layout: {
              fillColor: '#F2F2F2',
              defaultBorder: false,
            },
            table: {
              widths: '100%',
              body: [
                [{
                  text: data.labels.notes.title,
                  style: 'notes',
                  margin: [10, 10, 5, 0],
                }],
                [{
                  text: data.info.notes.text,
                  margin: [10, 5, 10, 10],
                  fontSize: 10,
                }],
              ],
            },
          },
        ],
      },
      {
        text: data.labels.signature.disclaimer,
        color: settings.variables.colors.text,
        margin: [0, 30, 0, 0],
        alignment: 'justify',
        fontSize: 10,
        bold: true,
        lineHeight: 1.5,
      },
      {
        text: data.labels.signature.disclaimerText,
        color: settings.variables.colors.text,
        margin: [0, 5, 0, 0],
        alignment: 'justify',
        fontSize: 8,
        lineHeight: 1.5,
      },
    ],
  };
}
