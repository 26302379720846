export default {
  namespaced: true,
  state: {
    notification: {
      show: false,
      message: '',
      style: '',
      type: 'dismissible',
    },
    modalDialog: {
      show: false,
      isDecision: false,
      title: '',
      content: '',
      imgUrl: '',
      imgAlt: '',
      ContinueText: '',
      CancelText: '',
      response: '',
      type: '',
    },
    isLoading: false,
    scrollLock: false,
  },
  mutations: {
    // Notification
    setNotification: (state, info) => {
      state.notification.show = true;
      state.notification.message = info.message;
      state.notification.style = info.style;
    },
    setHideNotification: (state) => {
      state.notification.show = false;
      state.notification.message = '';
      state.notification.style = '';
    },

    // Modal Dialog
    setModalDialog: (state, info) => {
      state.modalDialog.show = true;
      state.modalDialog.isDecision = info.isDecision || false;
      state.modalDialog.title = info.title;
      state.modalDialog.content = info.content;
      state.modalDialog.imgUrl = info.imgUrl || '';
      state.modalDialog.imgAlt = info.imgAlt || '';
      state.modalDialog.ContinueText = info.continueText;
      state.modalDialog.CancelText = info.cancelText || '';
      state.modalDialog.type = info.type || '';
    },
    setHideModalDialog: (state) => {
      state.modalDialog.show = false;
      state.modalDialog.isDecision = false;
      state.modalDialog.title = '';
      state.modalDialog.content = '';
      state.modalDialog.imgUrl = '';
      state.modalDialog.imgAlt = '';
      state.modalDialog.ContinueText = '';
      state.modalDialog.CancelText = '';
      setTimeout(() => {
        state.modalDialog.response = '';
      }, 500);
    },
    setModalResponse: (state, value) => {
      state.modalDialog.response = { value, type: state.modalDialog.type };
    },

    // Loading
    setLoading: (state) => {
      state.isLoading = true;
    },
    setHideLoading: (state) => {
      state.isLoading = false;
    },

    // Scroll
    setScrollLock: (state) => {
      state.scrollLock = true;
    },
    setEnableScroll: (state) => {
      state.scrollLock = false;
    },
  },
  actions: {
    // Notification
    showNotification({ dispatch, commit }, notification) {
      commit('setNotification', notification);
      if (notification.style !== 'error') {
        setTimeout(() => {
          dispatch('hideNotification');
        }, 5000);
      }
    },
    showDefaultErrorAPI({ commit }) {
      commit('setNotification', {
        message: 'Sorry. We have an internal problem. Try again later.',
        style: 'error',
      });
    },
    showDefaultErrorForm({ commit }) {
      commit('setNotification', {
        message: 'Ooops! Something is missing.',
        style: 'error',
      });
    },
    hideNotification({ commit }) {
      commit('setHideNotification');
    },

    // Modal Dialog
    showModalDialog({ commit }, content) {
      commit('setModalDialog', content);
      commit('setScrollLock');
    },
    hideModalDialog({ commit }) {
      commit('setHideModalDialog');
      commit('setEnableScroll');
    },
    saveModalResponse({ commit }, response) {
      commit('setModalResponse', response);
    },

    // Loading
    showLoading({ commit }) {
      commit('setLoading');
    },
    hideLoading({ commit }) {
      commit('setHideLoading');
    },
  },
  getters: {
    // Notification
    getNotificationState: (state) => state.notification.show,
    getNotificationMessage: (state) => state.notification.message,
    getNotificationStyle: (state) => state.notification.style,
    getNotificationType: (state) => state.notification.type,

    // Modal Dialog
    getModalState: (state) => state.modalDialog.show,
    getModalIsDecision: (state) => state.modalDialog.isDecision,
    getModalTitle: (state) => state.modalDialog.title,
    getModalContent: (state) => state.modalDialog.content,
    getModalImgUrl: (state) => state.modalDialog.imgUrl,
    getModalImgAlt: (state) => state.modalDialog.imgAlt,
    getModalCancelText: (state) => state.modalDialog.CancelText,
    getModalContinueText: (state) => state.modalDialog.ContinueText,
    getModalResponse: (state) => state.modalDialog.response,

    // Loading
    getIsLoading: (state) => state.isLoading,

    // Scroll
    getScrollLock: (state) => state.scrollLock,
  },
};
