<template>
  <main id="home" class="home">
    <div class="home__container">
      <div class="home__title-group">
        <h1 class="home__title fmc-type--h3">{{ setTitlePageByTab }}</h1>
        <div v-if="tabs.length" class="home__title-actions">
          <Button
            id="clear-all-form"
            class="fds-m--b-3 fds-m--r-3"
            :label="componentText.buttonClearAll"
            :primary="false"
            isLink
            @click="canClearAllForms()"
          />
          <Button
            id="clear-form"
            class="fds-m--b-3"
            :label="componentText.buttonClear"
            :primary="false"
            @click="canClearForm()"
          />
        </div>
      </div>
      <TabGroup
        :tabs="tabs"
        :activeTab="tabActiveIndex"
        @updateIndex="tabActiveIndex = $event"
      />
      <TabCapitalCost v-show="tabActiveIndex === 0" :key="keyCapitalCost"/>
      <TabQuickQuote v-show="tabActiveIndex === 1" :key="keyQuickQuote"/>
      <TabPaymentComparison v-show="tabActiveIndex === 2" :key="keyPaymentComparison"/>
      <TabEffectiveRate v-show="tabActiveIndex === 3" :key="keyEffectiveRate"/>
    </div>
    <ScrollToTopButton />
  </main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import TabGroup from '@/components/content-controls/TabGroup.vue';
import TabQuickQuote from '@/components/calculators/quick-quote/Form.vue';
import TabEffectiveRate from '@/components/calculators/effective-rate/Form.vue';
import TabPaymentComparison from '@/components/calculators/payment-comparison/Form.vue';
import TabCapitalCost from '@/components/calculators/capital-cost/Form.vue';
import Button from '@/components/form-elements/Button.vue';
import ScrollToTopButton from '@/components/form-elements/ScrollToTopButton.vue';

export default {
  name: 'HomeView',
  components: {
    TabGroup,
    TabQuickQuote,
    TabEffectiveRate,
    TabPaymentComparison,
    TabCapitalCost,
    Button,
    ScrollToTopButton,
  },
  data() {
    return {
      tabs: [],
      tabActiveIndex: 0,
      keyCapitalCost: 0,
      keyQuickQuote: 0,
      keyPaymentComparison: 0,
      keyEffectiveRate: 0,
    };
  },
  mounted() {
    // this.$insights.trackPageView();
    const token = localStorage.getItem('tokenLoginInformation');
    if (token !== null) {
      this.$store.dispatch('user/decodeToken', token);
    }
  },
  computed: {
    ...mapGetters({
      locations: 'user/locations',
      activeLocation: 'user/activeLocation',
      getModalResponse: 'notifications/getModalResponse',
      getModalTitle: 'notifications/getModalTitle',
    }),
    componentText() {
      const sectionActions = 'tabs.general.sectionActions';
      const tabsTitles = 'tabs.titles';
      return {
        mainTitle: this.$t('mainTitle'),
        tabs: {
          quickQuote: this.$t(`${tabsTitles}.quickQuote`),
          effectiveRate: this.$t(`${tabsTitles}.effectiveRate`),
          paymentComparison: this.$t(`${tabsTitles}.paymentComparison`),
          capitalCost: this.$t(`${tabsTitles}.capitalCost`),
        },
        buttonClear: this.$t(`${sectionActions}.buttonClear`),
        buttonClearAll: this.$t(`${sectionActions}.buttonClearAll`),
      };
    },
    routePath() {
      return this.$route.path;
    },
    setTitlePageByTab() {
      switch (this.tabActiveIndex) {
        case 0:
          return this.componentText.tabs.capitalCost;
        case 1:
          return this.componentText.tabs.quickQuote;
        case 2:
          return this.componentText.tabs.paymentComparison;
        case 3:
          return this.componentText.tabs.effectiveRate;
        default:
          return '';
      }
    },
  },
  methods: {
    ...mapActions({
      showModalDialog: 'notifications/showModalDialog',
      clearCapitalCost: 'capitalCost/clearCapitalCost',
      clearQuickQuote: 'quickQuote/clearQuickQuote',
      clearPaymentComparison: 'paymentComparison/clearPaymentComparison',
      clearEffectiveRate: 'effectiveRate/clearEffectiveRate',
    }),
    updateTabsList() {
      // Set Tabs
      const ALL_TABS = [
        {
          id: 0,
          name: this.componentText.tabs.capitalCost,
          route: '/capital-cost',
          component: 'TabCapitalCost',
          canShow: this.activeLocation === this.locations.usa,
        },
        {
          id: 1,
          name: this.componentText.tabs.quickQuote,
          route: '/quick-quote',
          component: 'TabQuickQuote',
          canShow: this.activeLocation !== this.locations.none,
        },
        {
          id: 2,
          name: this.componentText.tabs.paymentComparison,
          route: '/payment-comparison',
          component: 'TabPaymentComparison',
          canShow: this.activeLocation === this.locations.usa,
        },
        {
          id: 3,
          name: this.componentText.tabs.effectiveRate,
          route: '/effective-rate',
          component: 'TabEffectiveRate',
          canShow: this.activeLocation !== this.locations.none,
        },
      ];
      const NEW_ALL_TABS = JSON.parse(JSON.stringify(ALL_TABS));
      const TABS_AVAILABLE = NEW_ALL_TABS.filter((e) => e.canShow);

      this.tabs = TABS_AVAILABLE;

      // Set Tab Index By Route
      if (TABS_AVAILABLE.length) {
        const TABS = TABS_AVAILABLE.filter((e) => e.route === this.routePath);
        this.tabActiveIndex = TABS.length ? TABS[0].id : 1;
      }
    },
    canClearForm() {
      const modalClearForm = 'tabs.general.form.modalClearForm';
      this.showModalDialog({
        isDecision: true,
        title: this.$t(`${modalClearForm}.title`),
        content: this.$t(`${modalClearForm}.content`),
        continueText: this.$t('tabs.general.form.yesLabel'),
        cancelText: this.$t('tabs.general.form.noLabel'),
        type: 'clearForm',
      });
    },
    canClearAllForms() {
      const modalClearAllForms = 'tabs.general.form.modalClearAllForms';
      this.showModalDialog({
        isDecision: true,
        title: this.$t(`${modalClearAllForms}.title`),
        content: this.$t(`${modalClearAllForms}.content`),
        continueText: this.$t('tabs.general.form.yesLabel'),
        cancelText: this.$t('tabs.general.form.noLabel'),
        type: 'clearAllForm',
      });
    },
    async clearForm() {
      switch (this.routePath) {
        case '/capital-cost':
          await this.clearCapitalCost();
          this.keyCapitalCost += 1;
          break;
        case '/quick-quote':
          await this.clearQuickQuote();
          this.keyQuickQuote += 1;
          break;
        case '/payment-comparison':
          await this.clearPaymentComparison();
          this.keyPaymentComparison += 1;
          break;
        case '/effective-rate':
          await this.clearEffectiveRate();
          this.keyEffectiveRate += 1;
          break;
        default:
      }
    },
    async clearAllForm() {
      await this.clearCapitalCost();
      await this.clearQuickQuote();
      await this.clearPaymentComparison();
      await this.clearEffectiveRate();
      this.keyCapitalCost += 1;
      this.keyQuickQuote += 1;
      this.keyPaymentComparison += 1;
      this.keyEffectiveRate += 1;
    },
  },
  watch: {
    componentText() {
      this.updateTabsList();
    },
    activeLocation() {
      this.updateTabsList();
    },
    routePath() {
      this.updateTabsList();
    },
    getModalResponse() {
      if (this.getModalResponse.type === 'clearForm' && this.getModalResponse.value === 'yes') this.clearForm();
      if (this.getModalResponse.type === 'clearAllForm' && this.getModalResponse.value === 'yes') this.clearAllForm();
    },
  },
  created() {
    this.updateTabsList();
  },
};
</script>
<style lang="scss" scoped>
.home {
  flex-wrap: wrap;

  &__container {
    position: relative;
    max-width: 1640px;
    margin: 0 auto;
    padding: 0 fds-rem(30px) fds-rem(60px);
  }

  &__title-group,
  &__title-actions {
    @include media('>=600px') {
      display: flex;
      justify-content: space-between;
    }
  }

  &__title {
    margin-bottom: fds-rem(60px);
    font-size: fds-rem(36px);
  }

  &__loading {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $fds-color--white;
    z-index: 10;
  }
}
</style>
