<template>
  <section id="effective-rate-content" class="effective-rate">
    <form
      v-if="!showPDF"
      ref="effectiverateform"
      @submit.prevent="generatePDF()"
    >
      <div class="first-info fds-m--b-3">
        <Input
          id="effective-rate-costumer-name"
          class="field"
          :name="'effective-rate-costumer-name'"
          :submitted="hasSubmitted"
          v-model:value="customerName"
          @hasError="validateField($event)"
          required
        >{{ componentText.customerName }}</Input>
        <Input
          id="effective-rate-dealer-name"
          class="field"
          :name="'effective-rate-dealer-name'"
          :submitted="hasSubmitted"
          v-model:value="dealerName"
          v-maska="{ mask: 'EEEEEEEEEEEEEEEEEEEEEEEEE',
          tokens: { 'E': { pattern: /[0-9a-zA-Z-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]/}} }"
          @hasError="validateField($event)"
          required
        >{{ componentText.dealerName }}</Input>
      </div>

      <h3 class="effective-rate__subtitle fmc-type--subtitle1">
        {{ componentText.vehicle.title }}
      </h3>

      <div class="second-info fds-m--b-3">
        <Input
          id="effective-rate-vi-year"
          class="field"
          :name="'effective-rate-vi-year'"
          :submitted="hasSubmitted"
          minlength="4"
          v-model:value="vehicle.year"
          v-maska="'####'"
          @hasError="validateField($event)"
          required
        >{{ componentText.vehicle.yearLabel }}</Input>
        <Input
          id="effective-rate-vi-make"
          class="field"
          :name="'effective-rate-vi-make'"
          :submitted="hasSubmitted"
          v-model:value="vehicle.make"
          @hasError="validateField($event)"
          required
        >{{ componentText.vehicle.makeLabel }}</Input>
        <Input
          id="effective-rate-vi-model"
          class="field"
          :name="'effective-rate-vi-model'"
          :submitted="hasSubmitted"
          v-model:value="vehicle.model"
          @hasError="validateField($event)"
          required
        >{{ componentText.vehicle.modelLabel }}</Input>
        <Input
          id="effective-rate-vi-vin"
          class="field"
          :name="'effective-rate-vi-vin'"
          :submitted="hasSubmitted"
          v-model:value="vehicle.vin"
          @hasError="validateField($event)"
          required
        >{{ componentText.vehicle.vinLabel }}</Input>
      </div>

      <h3 class="effective-rate__subtitle fmc-type--subtitle1">
        {{ componentText.taxes.title }}
      </h3>

      <div class="third-info fds-m--b-3">
        <InputCurrency
          id="effective-rate-st-sellingPrice"
          class="field"
          :name="'effective-rate-st-sellingPrice'"
          :submitted="hasSubmitted"
          v-model:value="taxes.sellingPrice"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.sellingPriceLabel }} <sup>1</sup></InputCurrency>
        <Input
          id="effective-rate-st-leaseTerm"
          class="field"
          :name="'effective-rate-st-leaseTerm'"
          :submitted="hasSubmitted"
          v-model:value="taxes.leaseTerm"
          v-maska="'##'"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.leaseTermLabel }} <sup>4</sup></Input>
        <Input
          id="effective-rate-st-retailTerm"
          class="field"
          :name="'effective-rate-st-retailTerm'"
          :submitted="hasSubmitted"
          v-model:value="taxes.retailTerm"
          v-maska="'##'"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.retailTermLabel }} <sup>4</sup></Input>
        <Input
          id="effective-rate-st-alternateRetailTerm"
          class="field"
          :name="'effective-rate-st-alternateRetailTerm'"
          :submitted="hasSubmitted"
          v-model:value="taxes.alternateRetailTerm"
          v-maska="'##'"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.alternateRetailTermLabel }} <sup>4</sup></Input>
        <InputCurrency
          id="effective-rate-st-cashDownPayment"
          class="field"
          :name="'effective-rate-st-cashDownPayment'"
          :submitted="hasSubmitted"
          v-model:value="taxes.cashDownPayment"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.cashDownPaymentLabel }} <sup>1</sup></InputCurrency>
        <InputCurrency
          id="effective-rate-st-serviceContract"
          class="field"
          :name="'effective-rate-st-serviceContract'"
          :submitted="hasSubmitted"
          v-model:value="taxes.serviceContract"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.serviceContractLabel }}</InputCurrency>
        <InputCurrency
          id="effective-rate-st-fleetConcessions"
          class="field"
          :name="'effective-rate-st-fleetConcessions'"
          :submitted="hasSubmitted"
          v-model:value="taxes.fleetConcessions"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.fleetConcessionsLabel }} <sup>2</sup></InputCurrency>
        <InputCurrency
          id="effective-rate-st-tradeEquity"
          class="field"
          :name="'effective-rate-st-tradeEquity'"
          :submitted="hasSubmitted"
          v-model:value="taxes.tradeEquity"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.tradeEquityLabel }}</InputCurrency>
        <InputPercent
          id="effective-rate-st-residual"
          class="field"
          :name="'effective-rate-st-residual'"
          :submitted="hasSubmitted"
          v-model:value="taxes.residual"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.residualLabel }} <sup>5</sup> <sup>12</sup></InputPercent>
        <InputPercent
          id="effective-rate-st-customerLeaseRate"
          class="field"
          :name="'effective-rate-st-customerLeaseRate'"
          :submitted="hasSubmitted"
          v-model:value="taxes.customerLeaseRate"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.customerLeaseRateLabel }} <sup>12</sup></InputPercent>
        <InputPercent
          id="effective-rate-st-customerRetailRate"
          class="field"
          :name="'effective-rate-st-customerRetailRate'"
          :submitted="hasSubmitted"
          v-model:value="taxes.customerRetailRate"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.customerRetailRateLabel }} <sup>12</sup></InputPercent>
        <InputCurrency
          id="effective-rate-st-monthlyMaintenanceCharge"
          class="field"
          :name="'effective-rate-st-monthlyMaintenanceCharge'"
          :submitted="hasSubmitted"
          v-model:value="taxes.monthlyMaintenanceCharge"
          @hasError="validateField($event)"
          required
        >
          {{ componentText.taxes.monthlyMaintenanceChargeLabel }} <sup>7</sup>
        </InputCurrency>
        <InputPercent
          id="effective-rate-st-alternateRetailRate"
          class="field"
          :name="'effective-rate-st-alternateRetailRate'"
          :submitted="hasSubmitted"
          v-model:value="taxes.alternateRetailRate"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.alternateRetailRateLabel }} <sup>12</sup>
        </InputPercent>
      </div>

      <h3 class="effective-rate__subtitle fmc-type--subtitle1">
        {{ componentText.competitor.title }}
      </h3>

      <div class="fourth-info fds-m--b-3">
        <InputCurrency
          id="effective-rate-cd-sellingPrice"
          class="field"
          :name="'effective-rate-cd-sellingPrice'"
          :submitted="hasSubmitted"
          v-model:value="competitor.sellingPrice"
          @hasError="validateField($event)"
          required
        >{{ componentText.competitor.sellingPriceLabel }} <sup>1</sup></InputCurrency>
        <InputCurrency
          id="effective-rate-cd-fleetConcessions"
          class="field"
          :name="'effective-rate-cd-fleetConcessions'"
          :submitted="hasSubmitted"
          v-model:value="competitor.fleetConcessions"
          @hasError="validateField($event)"
          required
        >{{ componentText.competitor.fleetConcessionsLabel }} <sup>2</sup></InputCurrency>
        <InputCurrency
          id="effective-rate-cd-cashDownPayment"
          class="field"
          :name="'effective-rate-cd-cashDownPayment'"
          :submitted="hasSubmitted"
          v-model:value="competitor.cashDownPayment"
          @hasError="validateField($event)"
          required
        >{{ componentText.competitor.cashDownPaymentLabel }} <sup>3</sup></InputCurrency>
        <InputCurrency
          id="effective-rate-cd-serviceContract"
          class="field"
          :name="'effective-rate-cd-serviceContract'"
          :submitted="hasSubmitted"
          v-model:value="competitor.serviceContract"
          @hasError="validateField($event)"
          required
        >{{ componentText.competitor.serviceContractLabel }}</InputCurrency>
        <InputCurrency
          id="effective-rate-cd-residualAmount"
          class="field"
          :name="'effective-rate-cd-residualAmount'"
          :submitted="hasSubmitted"
          v-model:value="competitor.residualAmount"
          @hasError="validateField($event)"
          required
        >{{ componentText.competitor.residualAmountLabel }} <sup>8</sup></InputCurrency>
        <InputCurrency
          id="effective-rate-cd-competitorPaymentLabel"
          class="field"
          :name="'effective-rate-cd-competitorPaymentLabel'"
          :submitted="hasSubmitted"
          v-model:value="competitor.competitorPayment"
          @hasError="validateField($event)"
          required
        >{{ componentText.competitor.competitorPaymentLabel }} <sup>9</sup></InputCurrency>
        <Dropdown
          id="effective-rate-st-payment-timing"
          class="field"
          :name="'effective-rate-st-payment-timing'"
          :label="componentText.competitor.paymentTimingLabel"
          :submitted="hasSubmitted"
          :options="[
            {
              label:componentText.competitor.paymentTimingOption1,
              value: 'advance',
            },
            {
              label: componentText.competitor.paymentTimingOption2,
              value: 'arrears',
            },
          ]"
          :selectedOption="competitor.paymentTiming"
          v-model:value="competitor.paymentTiming"
          @select="competitor.paymentTiming = $event"
          @hasError="validateField($event)"
          required
        ></Dropdown>
        <InputCurrency
          id="effective-rate-cd-upfrontFees"
          class="field"
          :name="'effective-rate-cd-upfrontFees'"
          :submitted="hasSubmitted"
          v-model:value="competitor.upfrontFees"
          @hasError="validateField($event)"
          required
        >{{ componentText.competitor.upfrontFeesLabel }} <sup>6</sup></InputCurrency>
        <InputCurrency
          id="effective-rate-cd-monthlyManagementFees"
          class="field"
          :name="'effective-rate-cd-monthlyManagementFees'"
          :submitted="hasSubmitted"
          v-model:value="competitor.monthlyManagementFees"
          @hasError="validateField($event)"
          required
        >
          {{ componentText.competitor.monthlyManagementFeesLabel }} <sup>10</sup>
        </InputCurrency>
        <InputCurrency
          id="effective-rate-cd-monthlyMaintenanceCharge"
          class="field"
          :name="'effective-rate-cd-monthlyMaintenanceCharge'"
          :submitted="hasSubmitted"
          v-model:value="competitor.monthlyMaintenanceCharge"
          @hasError="validateField($event)"
          required
        >{{ componentText.competitor.monthlyMaintenanceChargeLabel }} <sup>7</sup></InputCurrency>
        <InputCurrency
          id="effective-rate-cd-leaseEndServiceCharge"
          class="field"
          :name="'effective-rate-cd-leaseEndServiceCharge'"
          :submitted="hasSubmitted"
          v-model:value="competitor.leaseEndServiceCharge"
          @hasError="validateField($event)"
          required
        >{{ componentText.competitor.leaseEndServiceChargeLabel }} <sup>11</sup></InputCurrency>
      </div>
      <div class="description fds-m--b-3">
        <ul>
            <li><sup>1</sup>{{ componentText.description.item1 }}</li>
            <li><sup>2</sup>{{ componentText.description.item2 }}</li>
            <li><sup>3</sup>{{ componentText.description.item3 }}</li>
            <li><sup>4</sup>{{ componentText.description.item4 }}</li>
            <li><sup>5</sup>{{ componentText.description.item5 }}</li>
            <li><sup>6</sup>{{ componentText.description.item6 }}</li>
            <li><sup>7</sup>{{ componentText.description.item7 }}</li>
            <li><sup>8</sup>{{ componentText.description.item8 }}</li>
            <li><sup>9</sup>{{ componentText.description.item9 }}</li>
            <li><sup>10</sup>{{ componentText.description.item10 }}</li>
            <li><sup>11</sup>{{ componentText.description.item11 }}</li>
            <li><sup>12</sup>{{ componentText.description.item12 }}</li>
        </ul>
      </div>
      <Button
        id="effective-rate-generate-pdf"
        class="fds-m--b-3"
        :type="'submit'"
        :label="componentText.buttonGeneratePDF"
        icon="next"
        @click="generatePDF()"
      />
    </form>
    <div class="pdf" v-if="showPDF">
      <GeneratePDF />
      <Button
        id="effective-rate-return-to-form"
        class="fds-m--t-3"
        :type="'submit'"
        :label="componentText.buttonCloseAndReturn"
        icon="next"
        @onClick="showPDF = false"
      />
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Input from '@/components/form-elements/Input.vue';
import InputPercent from '@/components/form-elements/InputPercent.vue';
import InputCurrency from '@/components/form-elements/InputCurrency.vue';
import Dropdown from '@/components/form-elements/Dropdown.vue';
import Button from '@/components/form-elements/Button.vue';
import GeneratePDF from './GeneratePDF.vue';

export default {
  name: 'EffectiveRateForm',
  components: {
    Input,
    InputPercent,
    InputCurrency,
    Dropdown,
    Button,
    GeneratePDF,
  },
  data() {
    return {
      showPDF: false,
      hasSubmitted: false,
      errors: [],
      changeStore: false,

      // Dealer & Customer
      customerName: '',
      dealerName: '',

      // Vehicle Information
      vehicle: {
        year: '',
        make: '',
        model: '',
        vin: '',
      },

      // Services and Taxes
      taxes: {
        sellingPrice: '',
        leaseTerm: '',
        retailTerm: '',
        alternateRetailTerm: '',
        cashDownPayment: '',
        serviceContract: '',
        fleetConcessions: '',
        tradeEquity: '',
        residual: '',
        customerLeaseRate: '',
        customerRetailRate: '',
        monthlyMaintenanceCharge: '',
        alternateRetailRate: '',
      },

      // Competidor Details
      competitor: {
        sellingPrice: '',
        fleetConcessions: '',
        cashDownPayment: '',
        serviceContract: '',
        residualAmount: '',
        competitorPayment: '',
        paymentTiming: {
          label: '',
          value: '',
        },
        upfrontFees: '',
        monthlyManagementFees: '',
        monthlyMaintenanceCharge: '',
        leaseEndServiceCharge: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      customerNameStore: 'effectiveRate/customerName',
      dealerNameStore: 'effectiveRate/dealerName',
      vehicleStore: 'effectiveRate/vehicle',
      taxesStore: 'effectiveRate/taxes',
      competitorStore: 'effectiveRate/competitor',
      getModalResponse: 'notifications/getModalResponse',
    }),

    componentText() {
      const sectionNames = 'tabs.effectiveRate.sectionNames';
      const sectionVehicle = 'tabs.effectiveRate.sectionVehicle';
      const sectionTaxes = 'tabs.effectiveRate.sectionTaxes';
      const sectionCompetitor = 'tabs.effectiveRate.sectionCompetitor';
      const sectionDescription = 'tabs.effectiveRate.sectionDescription';
      const sectionActions = 'tabs.general.sectionActions';

      return {
        customerName: this.$t(`${sectionNames}.customerNameLabel`),
        dealerName: this.$t(`${sectionNames}.dealerNameLabel`),

        vehicle: {
          title: this.$t(`${sectionVehicle}.title`),
          yearLabel: this.$t(`${sectionVehicle}.yearLabel`),
          makeLabel: this.$t(`${sectionVehicle}.makeLabel`),
          modelLabel: this.$t(`${sectionVehicle}.modelLabel`),
          vinLabel: this.$t(`${sectionVehicle}.vinLabel`),
        },

        taxes: {
          title: this.$t(`${sectionTaxes}.title`),
          sellingPriceLabel: this.$t(`${sectionTaxes}.sellingPriceLabel`),
          leaseTermLabel: this.$t(`${sectionTaxes}.leaseTermLabel`),
          retailTermLabel: this.$t(`${sectionTaxes}.retailTermLabel`),
          alternateRetailTermLabel: this.$t(`${sectionTaxes}.alternateRetailTermLabel`),
          cashDownPaymentLabel: this.$t(`${sectionTaxes}.cashDownPaymentLabel`),
          serviceContractLabel: this.$t(`${sectionTaxes}.serviceContractLabel`),
          fleetConcessionsLabel: this.$t(`${sectionTaxes}.fleetConcessionsLabel`),
          tradeEquityLabel: this.$t(`${sectionTaxes}.tradeEquityLabel`),
          residualLabel: this.$t(`${sectionTaxes}.residualLabel`),
          customerLeaseRateLabel: this.$t(`${sectionTaxes}.customerLeaseRateLabel`),
          customerRetailRateLabel: this.$t(`${sectionTaxes}.customerRetailRateLabel`),
          monthlyMaintenanceChargeLabel: this.$t(`${sectionTaxes}.monthlyMaintenanceChargeLabel`),
          alternateRetailRateLabel: this.$t(`${sectionTaxes}.alternateRetailRateLabel`),
        },

        competitor: {
          title: this.$t(`${sectionCompetitor}.title`),
          sellingPriceLabel: this.$t(`${sectionCompetitor}.sellingPriceLabel`),
          fleetConcessionsLabel: this.$t(`${sectionCompetitor}.fleetConcessionsLabel`),
          cashDownPaymentLabel: this.$t(`${sectionCompetitor}.cashDownPaymentLabel`),
          serviceContractLabel: this.$t(`${sectionCompetitor}.serviceContractLabel`),
          residualAmountLabel: this.$t(`${sectionCompetitor}.residualAmountLabel`),
          competitorPaymentLabel: this.$t(`${sectionCompetitor}.competitorPaymentLabel`),
          paymentTimingLabel: this.$t(`${sectionCompetitor}.paymentTimingLabel`),
          paymentTimingOption1: this.$t(`${sectionCompetitor}.paymentTimingOption1`),
          paymentTimingOption2: this.$t(`${sectionCompetitor}.paymentTimingOption2`),
          upfrontFeesLabel: this.$t(`${sectionCompetitor}.upfrontFeesLabel`),
          monthlyManagementFeesLabel: this.$t(`${sectionCompetitor}.monthlyManagementFeesLabel`),
          monthlyMaintenanceChargeLabel: this.$t(`${sectionCompetitor}.monthlyMaintenanceChargeLabel`),
          leaseEndServiceChargeLabel: this.$t(`${sectionCompetitor}.leaseEndServiceChargeLabel`),
        },

        description: {
          item1: this.$t(`${sectionDescription}.item1`),
          item2: this.$t(`${sectionDescription}.item2`),
          item3: this.$t(`${sectionDescription}.item3`),
          item4: this.$t(`${sectionDescription}.item4`),
          item5: this.$t(`${sectionDescription}.item5`),
          item6: this.$t(`${sectionDescription}.item6`),
          item7: this.$t(`${sectionDescription}.item7`),
          item8: this.$t(`${sectionDescription}.item8`),
          item9: this.$t(`${sectionDescription}.item9`),
          item10: this.$t(`${sectionDescription}.item10`),
          item11: this.$t(`${sectionDescription}.item11`),
          item12: this.$t(`${sectionDescription}.item12`),
        },

        buttonGeneratePDF: this.$t(`${sectionActions}.buttonGeneratePDF`),
        buttonClear: this.$t(`${sectionActions}.buttonClear`),
        buttonClearAll: this.$t(`${sectionActions}.buttonClearAll`),
        buttonCloseAndReturn: this.$t(`${sectionActions}.buttonCloseAndReturn`),
      };
    },
  },
  watch: {
    customerNameStore() {
      this.changeStore = true;
    },
    'vehicleStore.year': function () {
      this.changeStore = true;
    },
    'vehicleStore.make': function () {
      this.changeStore = true;
    },
    'vehicleStore.model': function () {
      this.changeStore = true;
    },
    'vehicleStore.vin': function () {
      this.changeStore = true;
    },
    'taxesStore.residual': function () {
      this.changeStore = true;
    },
    changeStore(value) {
      if (value === true) {
        this.populateSharedFields();
      }
    },
  },
  mounted() {
    this.populateForm();
  },
  methods: {
    ...mapActions({
      updateEffectiveRate: 'effectiveRate/updateEffectiveRate',
      clearEffectiveRate: 'effectiveRate/clearEffectiveRate',
      showNotification: 'notifications/showNotification',
      showDefaultErrorForm: 'notifications/showDefaultErrorForm',
      showLoading: 'notifications/showLoading',
      hideLoading: 'notifications/hideLoading',
      showModalDialog: 'notifications/showModalDialog',
    }),

    /**
     * Controls fields with error
     * @param {Object} field - Field data
     * @param {String} field.name - Name of Field
     * @param {Boolean} field.error - Field has error?
     */
    validateField(field) {
      if (field.error) {
        this.errors.push(field.name);
      } else {
        this.errors = this.errors.filter((e) => e !== field.name);
      }
    },

    /**
     * Get and Set values from store
     */
    populateForm() {
      this.hasSubmitted = false;

      this.customerName = this.customerNameStore;
      this.dealerName = this.dealerNameStore;

      this.vehicle.year = this.vehicleStore.year;
      this.vehicle.make = this.vehicleStore.make;
      this.vehicle.model = this.vehicleStore.model;
      this.vehicle.vin = this.vehicleStore.vin;

      this.taxes.sellingPrice = this.taxesStore.sellingPrice;
      this.taxes.leaseTerm = this.taxesStore.leaseTerm;
      this.taxes.retailTerm = this.taxesStore.retailTerm;
      this.taxes.alternateRetailTerm = this.taxesStore.alternateRetailTerm;
      this.taxes.cashDownPayment = this.taxesStore.cashDownPayment;
      this.taxes.serviceContract = this.taxesStore.serviceContract;
      this.taxes.fleetConcessions = this.taxesStore.fleetConcessions;
      this.taxes.tradeEquity = this.taxesStore.tradeEquity;
      this.taxes.residual = this.taxesStore.residual;
      this.taxes.customerLeaseRate = this.taxesStore.customerLeaseRate;
      this.taxes.customerRetailRate = this.taxesStore.customerRetailRate;
      this.taxes.monthlyMaintenanceCharge = this.taxesStore.monthlyMaintenanceCharge;
      this.taxes.alternateRetailRate = this.taxesStore.alternateRetailRate;

      this.competitor.sellingPrice = this.competitorStore.sellingPrice;
      this.competitor.fleetConcessions = this.competitorStore.fleetConcessions;
      this.competitor.cashDownPayment = this.competitorStore.cashDownPayment;
      this.competitor.serviceContract = this.competitorStore.serviceContract;
      this.competitor.residualAmount = this.competitorStore.residualAmount;
      this.competitor.competitorPayment = this.competitorStore.competitorPayment;
      this.competitor.paymentTiming = this.competitorStore.paymentTiming;
      this.competitor.upfrontFees = this.competitorStore.upfrontFees;
      this.competitor.monthlyManagementFees = this.competitorStore.monthlyManagementFees;
      this.competitor.monthlyMaintenanceCharge = this.competitorStore.monthlyMaintenanceCharge;
      this.competitor.leaseEndServiceCharge = this.competitorStore.leaseEndServiceCharge;

      this.changeStore = false;
    },

    populateSharedFields() {
      this.customerName = this.customerNameStore;

      this.vehicle.year = this.vehicleStore.year;
      this.vehicle.make = this.vehicleStore.make;
      this.vehicle.model = this.vehicleStore.model;
      this.vehicle.vin = this.vehicleStore.vin;

      this.taxes.residual = this.taxesStore.residual;

      this.changeStore = false;
    },

    async generatePDF() {
      this.hasSubmitted = true;
      if (this.errors.length) {
        this.showDefaultErrorForm();
        return;
      }

      this.showLoading();
      await this.updateEffectiveRate({
        customerName: this.customerName,
        dealerName: this.dealerName,
        vehicle: this.vehicle,
        taxes: this.taxes,
        competitor: this.competitor,
      });

      this.showPDF = true;
      this.hideLoading();

      // Report action to Application Insights
      const userData = this.$store.getters['user/userData'];
      userData.calculator = 'Effective Rate';
      this.$insights.trackEventBtnPageWithMetaInfo('Effective Rate PDF', userData);
    },
  },
};
</script>
<style lang="scss" scoped>
.effective-rate {
  &__title {
    margin-bottom: 40px;
  }

  &__subtitle {
    margin-bottom: 20px;
  }

  .field {
    margin-bottom: fds-rem(40px);
    @include media('>=600px'){
      width: calc(50% - 2rem);
    }
    @include media('>=1024px'){
      width: calc(33.33% - 2rem);
    }
  }

.first-info,
.fourth-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @include media('>=1024px'){
    justify-content: flex-start;
    gap: 0 3rem;
  }
}

.second-info,
.third-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

.description {
    max-height: none;
    ul{
        @include media('>=600px'){
          width: calc(100% - 2rem);
          justify-content: center;
        }
        @include media('>=1024px'){
          width: calc(50% - 2rem);
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          max-height: 180px;
          gap: fds-rem(0 20px);
        }
      }

    ul li{
      display: block;
      list-style: none;
      font-size: fds-rem(14px);
      font-weight: var(--fmc-type__weight-regular);
      color: $fds-color--black;
      box-sizing: border-box;
      margin-bottom: 10px;
      @include media('>=1024px'){
        margin: 0;
      }
    }

    sup{
      letter-spacing: fds-rem(0px);
      margin: 2px 5px 0 0;
      float: left;
    }
  }
}
</style>
