const currencyMask = [
  '$ ###,###.##',
  '$ ##,###.##',
  '$ #,###.##',
  '$ ###.##',
  '$ ##.##',
  '$ #.##',
  '$ #.#',
  '$ #',
];

const percentMask = [
  '###.##',
  '##.##',
  '#.##',
];

export default {
  data() {
    return {
      currencyMask,
      percentMask,
    };
  },
  methods: {
    /**
     * @param {string} amount The value that will be formatted
     * @param {string} prefix The value displayed before the value (default: $)
     * @param {string} suffix The value displayed after the value
     * @param {number} minDecimals The min of decimals (default: 2)
     * @param {number} maxDecimals The max of decimals (default: 2)
     * Format the value in the en-us pattern 0,000.00
     */
    formatCurrency(amount, prefix = '$ ', suffix = '', minDecimals = 2, maxDecimals = 2) {
      const newAmount = Number(amount).toLocaleString('en-us', {
        minimumFractionDigits: Number(minDecimals),
        maximumFractionDigits: Number(maxDecimals),
      });
      return `${prefix.concat(newAmount).concat(suffix)}`;
    },
  },
};
