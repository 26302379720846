 <template>
  <RenderPDF
    name="CommerciaLease Quick Quote"
    :definition="pdfConfig"
    :data-customerName="customerName"
    :data-dealerName="dealerName"
    :data-vehicle-year="vehicle.year"
    :data-vehicle-make="vehicle.make"
    :data-vehicle-model="vehicle.model"
    :data-vehicle-vin="vehicle.vin"
    :data-salePrice-trac="competitor.sellingPrice"
    :data-salePrice-tracAdvance="taxes.sellingPrice"
    :data-salePrice-tracArrears="taxes.sellingPrice"
    :data-salePrice-retail="taxes.sellingPrice"
    :data-salePrice-alternateRetail="taxes.sellingPrice"
    :data-fleetConcessions-trac="competitor.fleetConcessions"
    :data-fleetConcessions-tracAdvance="taxes.fleetConcessions"
    :data-fleetConcessions-tracArrears="taxes.fleetConcessions"
    :data-fleetConcessions-retail="taxes.fleetConcessions"
    :data-fleetConcessions-alternateRetail="taxes.fleetConcessions"
    :data-downPayment-trac="competitor.cashDownPayment"
    :data-downPayment-tracAdvance="taxes.cashDownPayment"
    :data-downPayment-tracArrears="taxes.cashDownPayment"
    :data-downPayment-retail="taxes.cashDownPayment"
    :data-downPayment-alternateRetail="taxes.cashDownPayment"
    :data-serviceContract-trac="competitor.serviceContract"
    :data-serviceContract-tracAdvance="taxes.serviceContract"
    :data-serviceContract-tracArrears="taxes.serviceContract"
    :data-serviceContract-retail="taxes.serviceContract"
    :data-serviceContract-alternateRetail="taxes.serviceContract"
    :data-capitalizedCost-trac="capitalizedCost.trac"
    :data-capitalizedCost-tracAdvance="capitalizedCost.tracAdvance"
    :data-capitalizedCost-tracArrears="capitalizedCost.tracArrears"
    :data-capitalizedCost-retail="capitalizedCost.retail"
    :data-capitalizedCost-alternateRetail="capitalizedCost.alternateRetail"
    :data-term-trac="taxes.leaseTerm"
    :data-term-tracAdvance="taxes.leaseTerm"
    :data-term-tracArrears="taxes.leaseTerm"
    :data-term-retail="taxes.retailTerm"
    :data-term-alternateRetail="taxes.alternateRetailTerm"
    :data-residualPercent-trac="formatCurrency(
      (residualPercent.trac.toFixed(2) * 100), '', '%'
    )"
    :data-residualPercent-tracAdvance="formatCurrency(
      residualPercent.tracAdvance.toFixed(2), '', '%'
    )"
    :data-residualPercent-tracArrears="formatCurrency(
      residualPercent.tracArrears.toFixed(2), '', '%'
    )"
    :data-residualPercent-retail="residualPercent.retail"
    :data-residualPercent-alternateRetail="residualPercent.alternateRetail"
    :data-residualMoney-trac="formatCurrency(Math.round(residualMoney.trac))"
    :data-residualMoney-tracAdvance="formatCurrency(Math.round(residualMoney.tracAdvance))"
    :data-residualMoney-tracArrears="formatCurrency(Math.round(residualMoney.tracArrears))"
    :data-residualMoney-retail="residualMoney.retail"
    :data-residualMoney-alternateRetail="residualMoney.alternateRetail"
    :data-basePayment-trac="formatCurrency(basePayment.trac)"
    :data-basePayment-tracAdvance="formatCurrency(basePayment.tracAdvance)"
    :data-basePayment-tracArrears="formatCurrency(basePayment.tracArrears)"
    :data-basePayment-retail="formatCurrency(basePayment.retail)"
    :data-basePayment-alternateRetail="formatCurrency(basePayment.alternateRetail)"
    :data-rateFees-trac="formatCurrency(Number(rateFees.trac).toFixed(2), '', '%')"
    :data-rateFees-tracAdvance="formatCurrency(Number(rateFees.tracAdvance).toFixed(2), '', '%')"
    :data-rateFees-tracArrears="formatCurrency(Number(rateFees.tracArrears).toFixed(2), '', '%')"
    :data-rateFees-retail="formatCurrency(Number(rateFees.retail).toFixed(2), '', '%')"
    :data-rateFees-alternateRetail="formatCurrency(
      Number(rateFees.alternateRetail).toFixed(2), '', '%'
    )"
    :data-upfrontFees-trac="competitor.upfrontFees"
    data-upfrontFees-tracAdvance="-"
    data-upfrontFees-tracArrears="-"
    data-upfrontFees-retail="-"
    data-upfrontFees-alternateRetail="-"
    :data-monthlyManagement-trac="competitor.monthlyManagementFees"
    data-monthlyManagement-tracAdvance="-"
    data-monthlyManagement-tracArrears="-"
    data-monthlyManagement-retail="-"
    data-monthlyManagement-alternateRetail="-"
    :data-leaseEndServiceCharge-trac="competitor.leaseEndServiceCharge"
    data-leaseEndServiceCharge-tracAdvance="-"
    data-leaseEndServiceCharge-tracArrears="-"
    data-leaseEndServiceCharge-retail="-"
    data-leaseEndServiceCharge-alternateRetail="-"
    :data-effectiveRateFees-trac="effectiveRateFees.trac"
    :data-effectiveRateFees-tracAdvance="effectiveRateFees.tracAdvance"
    :data-effectiveRateFees-tracArrears="effectiveRateFees.tracArrears"
    :data-effectiveRateFees-retail="effectiveRateFees.retail"
    :data-effectiveRateFees-alternateRetail="effectiveRateFees.alternateRetail"
    :data-monthlyMaintenance-trac="competitor.monthlyMaintenanceCharge"
    :data-monthlyMaintenance-tracAdvance="taxes.monthlyMaintenanceCharge"
    :data-monthlyMaintenance-tracArrears="taxes.monthlyMaintenanceCharge"
    :data-monthlyMaintenance-retail="taxes.monthlyMaintenanceCharge"
    :data-monthlyMaintenance-alternateRetail="taxes.monthlyMaintenanceCharge"
    :data-paymentCharges-trac="formatCurrency(paymentCharges.trac)"
    :data-paymentCharges-tracAdvance="formatCurrency(paymentCharges.tracAdvance)"
    :data-paymentCharges-tracArrears="formatCurrency(paymentCharges.tracArrears)"
    :data-paymentCharges-retail="formatCurrency(paymentCharges.retail)"
    :data-paymentCharges-alternateRetail="formatCurrency(paymentCharges.alternateRetail)"
    :data-totalOfPayments-trac="formatCurrency(Math.round(totalOfPayments.trac))"
    :data-totalOfPayments-tracAdvance="formatCurrency(Math.round(totalOfPayments.tracAdvance))"
    :data-totalOfPayments-tracArrears="formatCurrency(Math.round(totalOfPayments.tracArrears))"
    :data-totalOfPayments-retail="formatCurrency(Math.round(totalOfPayments.retail))"
    :data-totalOfPayments-alternateRetail="formatCurrency(
      Math.round(totalOfPayments.alternateRetail)
    )"
    :data-totalFees-trac="formatCurrency(Math.round(totalFees.trac))"
    :data-totalFees-tracAdvance="formatCurrency(Math.round(totalFees.tracAdvance))"
    :data-totalFees-tracArrears="formatCurrency(Math.round(totalFees.tracArrears))"
    :data-totalFees-retail="formatCurrency(Math.round(totalFees.retail))"
    :data-totalFees-alternateRetail="formatCurrency(Math.round(totalFees.alternateRetail))"
    :data-residual-trac="formatCurrency(residualMoney.trac)"
    :data-residual-tracAdvance="formatCurrency(Math.round(residualMoney.tracAdvance))"
    :data-residual-tracArrears="formatCurrency(Math.round(residualMoney.tracArrears))"
    data-residual-retail="-"
    data-residual-alternateRetail="-"
    :data-totalExpense-trac="formatCurrency(Math.round(totalExpense.trac))"
    :data-totalExpense-tracAdvance="formatCurrency(Math.round(totalExpense.tracAdvance))"
    :data-totalExpense-tracArrears="formatCurrency(Math.round(totalExpense.tracArrears))"
    :data-totalExpense-retail="formatCurrency(Math.round(totalExpense.retail))"
    :data-totalExpense-alternateRetail="formatCurrency(Math.round(totalExpense.alternateRetail))"
    :data-totalSavings-trac="totalSavings.trac"
    :data-totalSavings-tracAdvance="formatCurrency(
      Math.round(Math.abs(totalSavings.tracAdvance))
    )"
    :data-totalSavings-tracArrears="formatCurrency(
      Math.round(Math.abs(totalSavings.tracArrears))
    )"
    :data-totalSavings-retail="formatCurrency(Math.round(Math.abs(totalSavings.retail)))"
    :data-totalSavings-alternateRetail="this.formatCurrency(
      Math.round(Math.abs(totalSavings.alternateRetail)),
    )"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { ExcelRate, ExcelPMT, ConvertToNumber } from '@/utilities/functions/pdf';
import RenderPDF from '@/components/calculators/RenderPDF.vue';
import definePDF from './TemplatePDF';

export default {
  name: 'FinishEffectiveRate',
  components: {
    RenderPDF,
  },
  data() {
    return {
      pdfConfig: {},
    };
  },
  computed: {
    ...mapGetters({
      activeLocale: 'locales/activeLocale',
      customerName: 'effectiveRate/customerName',
      dealerName: 'effectiveRate/dealerName',
      vehicle: 'effectiveRate/vehicle',
      taxes: 'effectiveRate/taxes',
      competitor: 'effectiveRate/competitor',
      activeLocationIsUsa: 'user/activeLocationIsUsa',
    }),

    capitalizedCost() {
      const competitorSelling = ConvertToNumber(this.competitor.sellingPrice);
      const competitorFleet = ConvertToNumber(this.competitor.fleetConcessions);
      const competitorCash = ConvertToNumber(this.competitor.cashDownPayment);
      const competitorWarranty = ConvertToNumber(this.competitor.serviceContract);
      const totalTrac = competitorSelling
      - (competitorFleet)
      - (competitorCash)
      + competitorWarranty;

      const taxesSelling = ConvertToNumber(this.taxes.sellingPrice);
      const taxesFleet = ConvertToNumber(this.taxes.fleetConcessions);
      const taxesCash = ConvertToNumber(this.taxes.cashDownPayment);
      const taxesWarranty = ConvertToNumber(this.taxes.serviceContract);
      const totalOthers = taxesSelling - (taxesFleet) - (taxesCash) + taxesWarranty;

      return {
        trac: totalTrac,
        tracAdvance: totalOthers,
        tracArrears: totalOthers,
        retail: totalOthers,
        alternateRetail: totalOthers,
      };
    },
    residualPercent() {
      const residual = ConvertToNumber(this.taxes.residual);
      const residualAmount = ConvertToNumber(this.competitor.residualAmount);
      const capitalizedCost = this.capitalizedCost.trac;
      const totalCalc = residualAmount / capitalizedCost;

      return {
        trac: totalCalc,
        tracAdvance: residual,
        tracArrears: residual,
        retail: '-',
        alternateRetail: '-',
      };
    },
    residualMoney() {
      const capitalizedCost = this.capitalizedCost.tracAdvance;
      const residualPercent = this.residualPercent.tracAdvance / 100;
      const totalTracAdvance = capitalizedCost * residualPercent;

      return {
        trac: ConvertToNumber(this.competitor.residualAmount),
        tracAdvance: totalTracAdvance,
        tracArrears: totalTracAdvance,
        retail: '-',
        alternateRetail: '-',
      };
    },
    basePayment() {
      const customerLeaseRate = ConvertToNumber(this.taxes.customerLeaseRate);
      const customerRetailRate = ConvertToNumber(this.taxes.customerRetailRate);
      const alternateRetailRate = ConvertToNumber(this.taxes.alternateRetailRate);
      const customerLeaseRateCalculated = (customerLeaseRate / 12) / 100;
      const customerRetailRateCalculated = (customerRetailRate / 12) / 100;
      const alternateRetailRateCalculated = (alternateRetailRate / 12) / 100;

      const termAdvance = ConvertToNumber(this.taxes.leaseTerm);
      const capitalizedAdvance = this.capitalizedCost.tracAdvance;
      const residualMoneyAdvance = this.residualMoney.tracAdvance;
      const termArrears = termAdvance;
      const capitalizedArrears = this.capitalizedCost.tracArrears;
      const residualMoneyArrears = this.residualMoney.tracArrears;
      const termRetail = ConvertToNumber(this.taxes.retailTerm);
      const capitalizedRetail = this.capitalizedCost.retail;
      const termAlternateRetail = ConvertToNumber(this.taxes.alternateRetailTerm);
      const capitalizedAlternateRetail = this.capitalizedCost.alternateRetail;

      const totalAdvance = ExcelPMT(
        customerLeaseRateCalculated,
        termAdvance,
        -Math.abs(capitalizedAdvance),
        residualMoneyAdvance,
        1,
      );
      const totalArrears = ExcelPMT(
        customerLeaseRateCalculated,
        termArrears,
        -Math.abs(capitalizedArrears),
        residualMoneyArrears,
        0,
      );
      const totalRetail = ExcelPMT(
        customerRetailRateCalculated,
        termRetail,
        -Math.abs(capitalizedRetail),
        0,
        0,
      );
      const totalAlternateRetail = ExcelPMT(
        alternateRetailRateCalculated,
        termAlternateRetail,
        -Math.abs(capitalizedAlternateRetail),
        0,
        0,
      );
      return {
        trac: ConvertToNumber(this.competitor.competitorPayment),
        tracAdvance: totalAdvance,
        tracArrears: totalArrears,
        retail: totalRetail,
        alternateRetail: totalAlternateRetail,
      };
    },
    rateFees() {
      const paymentTiming = this.competitor.paymentTiming.label; // eslint-disable-line
      const leaseTerm = ConvertToNumber(this.taxes.leaseTerm); // eslint-disable-line
      const retailTerm = ConvertToNumber(this.taxes.retailTerm); // eslint-disable-line
      const alternateRetailTerm = ConvertToNumber(this.taxes.alternateRetailTerm); // eslint-disable-line

      const trac = (
        ExcelRate(
          leaseTerm,
          -Math.abs(this.basePayment.trac),
          this.capitalizedCost.trac,
          -Math.abs(this.residualMoney.trac),
          paymentTiming === 'Advance' ? 1 : 0,
        ) * 12) * 100;

      const tracAdvance = (
        ExcelRate(
          leaseTerm,
          -Math.abs(this.basePayment.tracAdvance),
          this.capitalizedCost.tracAdvance,
          -Math.abs(this.residualMoney.tracAdvance),
          1,
        ) * 12) * 100;

      const tracArrears = (
        ExcelRate(
          leaseTerm,
          -Math.abs(this.basePayment.tracArrears),
          this.capitalizedCost.tracArrears,
          -Math.abs(this.residualMoney.tracArrears),
          0,
        ) * 12) * 100;

      const retail = (
        ExcelRate(
          retailTerm,
          -Math.abs(this.basePayment.retail),
          this.capitalizedCost.retail,
          0,
          0,
        ) * 12) * 100;

      const alternateRetail = (
        ExcelRate(
          alternateRetailTerm,
          -Math.abs(this.basePayment.alternateRetail),
          this.capitalizedCost.alternateRetail,
          0,
          0,
        ) * 12) * 100;

      return {
        trac,
        tracAdvance,
        tracArrears,
        retail,
        alternateRetail,
      };
    },
    effectiveRateFees() {
      const taxLeaseTerm = ConvertToNumber(this.taxes.leaseTerm);
      const basePaymentTrac = -Math.abs(this.basePayment.trac);
      const capitalizedCostTrac = this.capitalizedCost.trac;
      const upFrontFees = ConvertToNumber(this.competitor.upfrontFees);
      const monthlyManagementFees = ConvertToNumber(this.competitor.monthlyManagementFees);

      const paymentTiming = this.competitor.paymentTiming.label;
      const leaseEndServiceCharge = ConvertToNumber(this.competitor.leaseEndServiceCharge);
      const rateFeeTrac = this.rateFees.trac;

      const calc = leaseEndServiceCharge / ((1 + (rateFeeTrac / 100))
        ** (taxLeaseTerm / 12)).toFixed(8);

      const trac = (
        ExcelRate(
          taxLeaseTerm,
          basePaymentTrac,
          capitalizedCostTrac
          - (upFrontFees)
          - (monthlyManagementFees * this.taxes.leaseTerm)
          - (calc),
          -(this.residualMoney.trac),
          paymentTiming === 'Advance' ? 1 : 0,
        ) * 12) * 100;

      return {
        trac: this.formatCurrency(Number(trac), '', '%'),
        tracAdvance: '-',
        tracArrears: '-',
        retail: '-',
        alternateRetail: '-',
      };
    },
    paymentCharges() {
      const basePaymentTrac = this.basePayment.trac;
      const basePaymentAdvance = this.basePayment.tracAdvance;
      const basePaymentArrears = this.basePayment.tracArrears;
      const basePaymentRetail = this.basePayment.retail;
      const basePaymentAlternateRetail = this.basePayment.alternateRetail;
      const competitorMaintenace = ConvertToNumber(this.competitor.monthlyMaintenanceCharge);
      const taxesMaintenace = ConvertToNumber(this.taxes.monthlyMaintenanceCharge);
      return {
        trac: basePaymentTrac + competitorMaintenace,
        tracAdvance: basePaymentAdvance + taxesMaintenace,
        tracArrears: basePaymentArrears + taxesMaintenace,
        retail: basePaymentRetail + taxesMaintenace,
        alternateRetail: basePaymentAlternateRetail + taxesMaintenace,
      };
    },
    totalOfPayments() {
      const leaseTerm = ConvertToNumber(this.taxes.leaseTerm);
      const retailTerm = ConvertToNumber(this.taxes.retailTerm);
      const alternateRetailTerm = ConvertToNumber(this.taxes.alternateRetailTerm);
      const paymentChargesTrac = this.paymentCharges.trac;
      const paymentChargesAdvance = this.paymentCharges.tracAdvance;
      const paymentChargesArrears = this.paymentCharges.tracArrears;
      const paymentChargesRetail = this.paymentCharges.retail;
      const paymentChargesAlternateRetail = this.paymentCharges.alternateRetail;
      return {
        trac: leaseTerm * paymentChargesTrac,
        tracAdvance: leaseTerm * paymentChargesAdvance,
        tracArrears: leaseTerm * paymentChargesArrears,
        retail: retailTerm * paymentChargesRetail,
        alternateRetail: alternateRetailTerm * paymentChargesAlternateRetail,
      };
    },
    totalFees() {
      const leaseTerm = ConvertToNumber(this.taxes.leaseTerm);
      const upfrontFees = ConvertToNumber(this.competitor.upfrontFees);
      const monthlyManagementFees = ConvertToNumber(this.competitor.monthlyManagementFees);
      const leaseEndServiceCharge = ConvertToNumber(this.competitor.leaseEndServiceCharge);

      const totalMonthly = leaseTerm * monthlyManagementFees;
      return {
        trac: upfrontFees + totalMonthly + leaseEndServiceCharge,
        tracAdvance: 0,
        tracArrears: 0,
        retail: 0,
        alternateRetail: 0,
      };
    },
    totalExpense() {
      const paymentTrac = this.totalOfPayments.trac;
      const paymentAdvance = this.totalOfPayments.tracAdvance;
      const paymentArrears = this.totalOfPayments.tracArrears;
      const paymentRetail = this.totalOfPayments.retail;
      const paymentAlternateRetail = this.totalOfPayments.alternateRetail;
      const feesTrac = this.totalFees.trac;
      const feesAdvance = this.totalFees.tracAdvance;
      const feesArrears = this.totalFees.tracArrears;
      const feesReatail = this.totalFees.retail;
      const feesAlternateRetail = this.totalFees.alternateRetail;
      const residualTrac = this.residualMoney.trac;
      const residualAdvance = this.residualMoney.tracAdvance;
      const residualArrears = this.residualMoney.tracArrears;
      return {
        trac: paymentTrac + feesTrac + residualTrac,
        tracAdvance: paymentAdvance + feesAdvance + residualAdvance,
        tracArrears: paymentArrears + feesArrears + residualArrears,
        retail: paymentRetail + feesReatail,
        alternateRetail: paymentAlternateRetail + feesAlternateRetail,
      };
    },
    totalSavings() {
      const totalExpenseTrac = this.totalExpense.trac;
      const totalExpenseAdvance = this.totalExpense.tracAdvance;
      const totalExpenseArrears = this.totalExpense.tracArrears;
      const totalExpenseRetail = this.totalExpense.retail;
      const totalExpenseAlternateRetail = this.totalExpense.alternateRetail;
      return {
        trac: '-',
        tracAdvance: totalExpenseTrac - (totalExpenseAdvance),
        tracArrears: totalExpenseTrac - (totalExpenseArrears),
        retail: totalExpenseTrac - (totalExpenseRetail),
        alternateRetail: totalExpenseTrac - (totalExpenseAlternateRetail),
      };
    },
  },
  mounted() {
    this.pdfConfig = definePDF({
      labels: {
        title: this.$t('tabs.effectiveRate.sectionPDF.title'),
        subtitle: this.$t('tabs.effectiveRate.sectionPDF.subtitle'),
        vehicle: {
          yearLabel: this.$t('tabs.effectiveRate.sectionPDF.sectionVehicle.yearLabel'),
          makeLabel: this.$t('tabs.effectiveRate.sectionPDF.sectionVehicle.makeLabel'),
          modelLabel: this.$t('tabs.effectiveRate.sectionPDF.sectionVehicle.modelLabel'),
          vin: this.$t('tabs.effectiveRate.sectionPDF.sectionVehicle.vinLabel'),
        },

        table: {
          topInfo1: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.topInfo1'),
          topInfo2: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.topInfo2'),
          topTitle1: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.topTitle1'),
          topTitle2: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.topTitle2'),
          topTitle3: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.topTitle3'),
          topTitle4: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.topTitle4'),
          topTitle5: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.topTitle5'),
          topTitle6: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.topTitle6'),
          leftSubtitle1: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle1'),
          leftSubtitle2: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle2'),
          leftSubtitle3: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle3'),
          leftSubtitle4: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle4'),
          leftSubtitle5: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle5'),
          leftSubtitle6: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle6'),
          leftSubtitle7: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle7'),
          leftSubtitle8: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle8'),
          leftSubtitle9: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle9'),
          leftSubtitle10: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle10'),
          leftSubtitle11: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle11'),
          leftSubtitle12: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle12'),
          leftSubtitle13: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle13'),
          leftSubtitle14: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle14'),
          leftSubtitle15: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle15'),
          leftSubtitle16: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle16'),
          leftSubtitle17: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle17'),
          leftSubtitle18: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle18'),
          leftSubtitle19: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle19'),
          leftSubtitle20: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle20'),
          leftSubtitle21: this.$t('tabs.effectiveRate.sectionPDF.sectionTable.leftSubtitle21'),
        },

        signature: {
          customerName: this.$t('tabs.effectiveRate.sectionPDF.sectionSignature.customerName'),
          dealerName: this.$t('tabs.effectiveRate.sectionPDF.sectionSignature.dealerName'),
          signature: this.$t('tabs.effectiveRate.sectionPDF.sectionSignature.signature'),
          disclaimer: this.$t('tabs.effectiveRate.sectionPDF.sectionSignature.disclaimer'),
          disclaimerText1: this.activeLocationIsUsa
            ? this.$t('tabs.effectiveRate.sectionPDF.sectionSignature.disclaimerTextUSA')
            : this.$t('tabs.effectiveRate.sectionPDF.sectionSignature.disclaimerTextCAN1'),
          disclaimerText2: this.$t('tabs.effectiveRate.sectionPDF.sectionSignature.disclaimerTextCAN2'),
        },
      },
      info: {
        customerName: this.customerName,
        dealerName: this.dealerName,
        vehicle: {
          year: this.vehicle.year,
          make: this.vehicle.make,
          model: this.vehicle.model,
          vin: this.vehicle.vin,
        },
        table: {
          salePrice: {
            trac: this.competitor.sellingPrice,
            tracAdvance: this.taxes.sellingPrice,
            tracArrears: this.taxes.sellingPrice,
            retail: this.taxes.sellingPrice,
            alternateRetail: this.taxes.sellingPrice,
          },
          fleetConcessions: {
            trac: this.competitor.fleetConcessions,
            tracAdvance: this.taxes.fleetConcessions,
            tracArrears: this.taxes.fleetConcessions,
            retail: this.taxes.fleetConcessions,
            alternateRetail: this.taxes.fleetConcessions,
          },
          downPayment: {
            trac: this.competitor.cashDownPayment,
            tracAdvance: this.taxes.cashDownPayment,
            tracArrears: this.taxes.cashDownPayment,
            retail: this.taxes.cashDownPayment,
            alternateRetail: this.taxes.cashDownPayment,
          },
          serviceContract: {
            trac: this.competitor.serviceContract,
            tracAdvance: this.taxes.serviceContract,
            tracArrears: this.taxes.serviceContract,
            retail: this.taxes.serviceContract,
            alternateRetail: this.taxes.serviceContract,
          },
          capitalizedCost: {
            trac: this.formatCurrency(this.capitalizedCost.trac),
            tracAdvance: this.formatCurrency(this.capitalizedCost.tracAdvance),
            tracArrears: this.formatCurrency(this.capitalizedCost.tracArrears),
            retail: this.formatCurrency(this.capitalizedCost.retail),
            alternateRetail: this.formatCurrency(this.capitalizedCost.alternateRetail),
          },
          term: {
            trac: this.taxes.leaseTerm,
            tracAdvance: this.taxes.leaseTerm,
            tracArrears: this.taxes.leaseTerm,
            retail: this.taxes.retailTerm,
            alternateRetail: this.taxes.alternateRetailTerm,
          },
          residualPercent: {
            trac: this.formatCurrency((this.residualPercent.trac.toFixed(2) * 100), '', '%'),
            tracAdvance: this.formatCurrency(this.residualPercent.tracAdvance.toFixed(2), '', '%'),
            tracArrears: this.formatCurrency(this.residualPercent.tracArrears.toFixed(2), '', '%'),
            retail: this.residualPercent.retail,
            alternateRetail: this.residualPercent.alternateRetail,
          },
          residualMoney: {
            trac: this.formatCurrency(Math.round(this.residualMoney.trac)),
            tracAdvance: this.formatCurrency(Math.round(this.residualMoney.tracAdvance)),
            tracArrears: this.formatCurrency(Math.round(this.residualMoney.tracArrears)),
            retail: this.residualMoney.retail,
            alternateRetail: this.residualMoney.alternateRetail,
          },
          basePayment: {
            trac: this.formatCurrency(this.basePayment.trac),
            tracAdvance: this.formatCurrency(this.basePayment.tracAdvance),
            tracArrears: this.formatCurrency(this.basePayment.tracArrears),
            retail: this.formatCurrency(this.basePayment.retail),
            alternateRetail: this.formatCurrency(this.basePayment.alternateRetail),
          },
          rateFees: {
            trac: this.formatCurrency(Number(this.rateFees.trac).toFixed(2), '', '%'),
            tracAdvance: this.formatCurrency(Number(this.rateFees.tracAdvance).toFixed(2), '', '%'),
            tracArrears: this.formatCurrency(Number(this.rateFees.tracArrears).toFixed(2), '', '%'),
            retail: this.formatCurrency(Number(this.rateFees.retail).toFixed(2), '', '%'),
            alternateRetail: this.formatCurrency(Number(this.rateFees.alternateRetail).toFixed(2), '', '%'),
          },
          upfrontFees: {
            trac: this.competitor.upfrontFees,
            tracAdvance: '-',
            tracArrears: '-',
            retail: '-',
            alternateRetail: '-',
          },
          monthlyManagement: {
            trac: this.competitor.monthlyManagementFees,
            tracAdvance: '-',
            tracArrears: '-',
            retail: '-',
            alternateRetail: '-',
          },
          leaseEndServiceCharge: {
            trac: this.competitor.leaseEndServiceCharge,
            tracAdvance: '-',
            tracArrears: '-',
            retail: '-',
            alternateRetail: '-',
          },
          effectiveRateFees: {
            trac: this.effectiveRateFees.trac,
            tracAdvance: this.effectiveRateFees.tracAdvance,
            tracArrears: this.effectiveRateFees.tracArrears,
            retail: this.effectiveRateFees.retail,
            alternateRetail: this.effectiveRateFees.alternateRetail,
          },
          monthlyMaintenance: {
            trac: this.competitor.monthlyMaintenanceCharge,
            tracAdvance: this.taxes.monthlyMaintenanceCharge,
            tracArrears: this.taxes.monthlyMaintenanceCharge,
            retail: this.taxes.monthlyMaintenanceCharge,
            alternateRetail: this.taxes.monthlyMaintenanceCharge,
          },
          paymentCharges: {
            trac: this.formatCurrency(this.paymentCharges.trac),
            tracAdvance: this.formatCurrency(this.paymentCharges.tracAdvance),
            tracArrears: this.formatCurrency(this.paymentCharges.tracArrears),
            retail: this.formatCurrency(this.paymentCharges.retail),
            alternateRetail: this.formatCurrency(this.paymentCharges.alternateRetail),
          },

          // Totals
          totalOfPayments: {
            trac: this.formatCurrency(Math.round(this.totalOfPayments.trac)),
            tracAdvance: this.formatCurrency(Math.round(this.totalOfPayments.tracAdvance)),
            tracArrears: this.formatCurrency(Math.round(this.totalOfPayments.tracArrears)),
            retail: this.formatCurrency(Math.round(this.totalOfPayments.retail)),
            alternateRetail: this.formatCurrency(Math.round(this.totalOfPayments.alternateRetail)),
          },
          totalFees: {
            trac: this.formatCurrency(Math.round(this.totalFees.trac)),
            tracAdvance: this.formatCurrency(Math.round(this.totalFees.tracAdvance)),
            tracArrears: this.formatCurrency(Math.round(this.totalFees.tracArrears)),
            retail: this.formatCurrency(Math.round(this.totalFees.retail)),
            alternateRetail: this.formatCurrency(Math.round(this.totalFees.alternateRetail)),
          },
          residual: {
            trac: this.formatCurrency(this.residualMoney.trac),
            tracAdvance: this.formatCurrency(Math.round(this.residualMoney.tracAdvance)),
            tracArrears: this.formatCurrency(Math.round(this.residualMoney.tracArrears)),
            retail: '-',
            alternateRetail: '-',
          },
          totalExpense: {
            trac: this.formatCurrency(Math.round(this.totalExpense.trac)),
            tracAdvance: this.formatCurrency(Math.round(this.totalExpense.tracAdvance)),
            tracArrears: this.formatCurrency(Math.round(this.totalExpense.tracArrears)),
            retail: this.formatCurrency(Math.round(this.totalExpense.retail)),
            alternateRetail: this.formatCurrency(Math.round(this.totalExpense.alternateRetail)),
          },

          // Last Total
          totalSavings: {
            trac: this.totalSavings.trac,
            tracAdvance: this.formatCurrency(Math.round(this.totalSavings.tracAdvance)),
            tracArrears: this.formatCurrency(Math.round(this.totalSavings.tracArrears)),
            retail: this.formatCurrency(Math.round(this.totalSavings.retail)),
            alternateRetail: this.formatCurrency(
              Math.round(this.totalSavings.alternateRetail),
            ),
          },
        },
      },
    });
  },
  methods: {
    /**
     * Convert a value to a number
     * @param {String} value - Your percentual or amount
     */
    convertToNumber(value) {
      return Number(value
        .replace(/[^0-9.]/, '')
        .replace('%', '')
        .replace(',', ''));
    },

    /**
     * Payment per period
     * @param {Number} ratePerPeriod - tax per period
     * @param {Number} numOfPayments - total number of payments
     * @param {Number} presentValue - present value
     * @param {Number} futureValue - future value
     * @param {Number} type - 0 period end term and 1 period start term
     */
    excelPMT(ratePerPeriod, numOfPayments, presentValue, futureValue, type) {
      const validFutureValue = typeof futureValue !== 'undefined' ? futureValue : 0;
      const validType = typeof type !== 'undefined' ? type : 0;

      if (ratePerPeriod !== 0.0) {
        // Interest rate exists
        const q = (1 + ratePerPeriod) ** numOfPayments;
        const z = validFutureValue + (q * presentValue);
        return -(ratePerPeriod * z) / ((-1 + q) * (1 + ratePerPeriod * (validType)));
      }
      if (numOfPayments !== 0.0) {
        // No interest rate, but number of payments exists
        return -(validFutureValue + presentValue) / numOfPayments;
      }

      return 0;
    },
  },
};
</script>
