<template>
  <Input
    :id="id"
    :name="name"
    :value="value"
    :infoText="infoText"
    :errorText="errorText"
    v-maska="{
      mask: [
        '$ C##,###.##',
        '$ C#,###.##',
        '$ #,###.##',
        '$ C##.##',
        '$ C#.##',
        '$ #.##',
      ],
      tokens: {
        'C': { pattern: /(\-|[0-9])/ }
      },
    }"
    v-model:value="currentValue"
    @keyup="$emit('keyup', $event)"
    @focus="$emit('focus', $event)"
    @change="validateCurrency()"
    :placeholder="placeholder"
    :disabled="disabled"
    :readonly="readonly"
    :required="required"
    :submitted="submitted"
    :hasLabel="hasLabel"
    :darkTheme="darkTheme"
  ><slot /></Input>
</template>
<script>
import Input from './Input.vue';

export default {
  name: 'InputCurrency',
  components: { Input },
  props: {
    id: { type: String, required: true },
    name: { type: String, required: true },
    value: { type: [String, Number], default: '' },
    infoText: { type: String, default: '' },
    errorText: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    submitted: { type: Boolean, required: true },
    hasLabel: { type: Boolean, default: true },
    darkTheme: { type: Boolean, default: false },
    wantToRound: { type: Boolean, default: false },
  },
  data() {
    return {
      currentValue: '',
    };
  },
  methods: {
    validateCurrency() {
      const value = this.currentValue;
      if (value) {
        let newValue = value.replace(/\$/g, '');
        newValue = newValue.replace(',', '');
        this.currentValue = this.wantToRound
          ? `${this.formatCurrency(Math.round(newValue))}`
          : `${this.formatCurrency(newValue)}`;
      }
      this.$emit('update:value', this.currentValue);
    },
  },
  mounted() {
    if (this.value) this.currentValue = this.value;
  },
};
</script>
