<template>
  <RenderPDF
    name="CommerciaLease Quick Quote"
    :definition="pdfConfig"
    :data-customerName="customerName"
    :data-dealerName="dealerName"
    :data-date="datePDF"
    :data-vehicle-year="vehicle.year"
    :data-vehicle-make="vehicle.make"
    :data-vehicle-model="vehicle.model"
    :data-vehicle-netCapCost="vehicle.netCapCost"
    :data-services-term="taxes.term"
    :data-services-residual="taxes.residual"
    :data-services-residualAmount="formatCurrency(residualAmount)"
    :data-services-paymentTiming="taxes.paymentTiming.label"
    :data-monthlyPaymentWithTax="formatCurrency(estimatedMonthlyPaymentWithTax)"
    :data-monthlyPaymentWithoutTax="formatCurrency(estimatedMonthlyPaymentWithoutTax)"
  />
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { ExcelPMT, ConvertToNumber } from '@/utilities/functions/pdf';
import RenderPDF from '@/components/calculators/RenderPDF.vue';
import definePDF from './TemplatePDF';

export default {
  name: 'FinishQuickQuote',
  components: {
    RenderPDF,
  },
  data() {
    return {
      pdfConfig: {},
    };
  },
  computed: {
    ...mapGetters({
      activeLocation: 'user/activeLocation',
      customerName: 'quickQuote/customerName',
      dealerName: 'quickQuote/dealerName',
      vehicle: 'quickQuote/vehicle',
      taxes: 'quickQuote/taxes',
    }),
    datePDF() {
      return moment(new Date()).format('MMM DD, YYYY');
    },
    netCapCostNumber() {
      return ConvertToNumber(this.vehicle.netCapCost);
    },
    paymentTimingIsAdvance() {
      return this.taxes.paymentTiming.value === 'advance';
    },

    /**
     * Calc of the Residual Amount
     */
    residualAmount() {
      const residual = ConvertToNumber(this.taxes.residual); // eslint-disable-line
      const total = this.netCapCostNumber * (residual / 100);

      return total;
    },

    /**
     * Calc of the Estimated Monthly Payment Before Tax
     */
    estimatedMonthlyPaymentWithTax() {
      const customerRate = ConvertToNumber(this.taxes.customerRate) / 100;
      const term = ConvertToNumber(this.taxes.term);
      const newRateMonthly = customerRate / 12;
      const netCapCost = this.netCapCostNumber;

      return ExcelPMT(
        newRateMonthly,
        term,
        netCapCost * -1,
        this.residualAmount,
        this.paymentTimingIsAdvance,
      );
    },

    /**
     * Calc of the Estimated Monthly Payment Without Tax
     */
    estimatedMonthlyPaymentWithoutTax() {
      const taxRate = ConvertToNumber(this.taxes.taxRate) / 100;
      const customerRate = ConvertToNumber(this.taxes.customerRate) / 100;
      const { term } = this.taxes;
      const newRateMonthly = customerRate / 12;
      const netCapCost = this.netCapCostNumber;

      const payment = ExcelPMT(
        newRateMonthly,
        term,
        netCapCost * -1,
        this.residualAmount,
        this.paymentTimingIsAdvance,
      );

      return taxRate ? (payment * taxRate) + payment : payment;
    },

    /**
     * Dealer Paticipation Code
     */
    dealerParticipationCode() {
      const customerRate = ConvertToNumber(this.taxes.customerRate) / 100;
      const dealerParticipation = ConvertToNumber(this.taxes.dealerParticipation) / 100;
      const { term } = this.taxes;
      const netCapCost = this.netCapCostNumber;
      const n25 = ExcelPMT(
        (customerRate - dealerParticipation) / 12,
        term,
        netCapCost * -1,
        this.residualAmount,
        this.paymentTimingIsAdvance,
      );
      const m25 = ExcelPMT(
        customerRate / 12,
        term,
        netCapCost * -1,
        this.residualAmount,
        this.paymentTimingIsAdvance,
      );
      const dpNumber = Number((m25 - n25) * term);

      // Calc of Quantity numbers on DP Value
      const qtyDecimalsStr = (value) => value.toString().substring(0, 16);
      const dpNumberValue = qtyDecimalsStr(dpNumber);

      const startDate = new Date('01/01/1900').getTime();
      const currentDate = new Date(new Date().setHours(23, 59, 59, 0));
      currentDate.setDate(currentDate.getDate() + 1);
      const differenceBetweenDates = Math.abs(currentDate.getTime() - startDate);
      const countDays = Math.ceil(differenceBetweenDates / (1000 * 3600 * 24));

      return `DP${Math.abs(dpNumberValue)} ${countDays}`;
    },
  },
  mounted() {
    this.pdfConfig = definePDF({
      labels: {
        title: this.$t('tabs.quickQuote.sectionPDF.title'),
        subtitle: this.$t('tabs.quickQuote.sectionPDF.subtitle'),
        vehicle: {
          title: this.$t('tabs.quickQuote.sectionPDF.sectionVehicle.title'),
          yearLabel: this.$t('tabs.quickQuote.sectionPDF.sectionVehicle.yearLabel'),
          makeLabel: this.$t('tabs.quickQuote.sectionPDF.sectionVehicle.makeLabel'),
          modelLabel: this.$t('tabs.quickQuote.sectionPDF.sectionVehicle.modelLabel'),
          netCapCostLabel: this.$t('tabs.quickQuote.sectionPDF.sectionVehicle.netCapCostLabel'),
        },

        taxes: {
          title: this.$t('tabs.quickQuote.sectionPDF.sectionTaxes.title'),
          termLabel: this.$t('tabs.quickQuote.sectionPDF.sectionTaxes.termLabel'),
          residualLabel: this.$t('tabs.quickQuote.sectionPDF.sectionTaxes.residualLabel'),
          residualAmountLabel: this.$t('tabs.quickQuote.sectionPDF.sectionTaxes.residualAmountLabel'),
          paymentTimingLabel: this.$t('tabs.quickQuote.sectionPDF.sectionTaxes.paymentTimingLabel'),
        },

        estimated: {
          title1: this.$t('tabs.quickQuote.sectionPDF.sectionEstimated.title1'),
          title2: this.$t('tabs.quickQuote.sectionPDF.sectionEstimated.title2'),
        },

        signature: {
          customerName: this.$t('tabs.quickQuote.sectionPDF.sectionSignature.customerName'),
          dealerName: this.$t('tabs.quickQuote.sectionPDF.sectionSignature.dealerName'),
          signature: this.$t('tabs.quickQuote.sectionPDF.sectionSignature.signature'),
          disclaimer: this.$t('tabs.quickQuote.sectionPDF.sectionSignature.disclaimer'),
          disclaimerText: this.activeLocation === 'USA'
            ? this.$t('tabs.quickQuote.sectionPDF.sectionSignature.disclaimerTextUSA')
            : this.$t('tabs.quickQuote.sectionPDF.sectionSignature.disclaimerTextCAN'),
        },
      },
      info: {
        customerName: this.customerName,
        dealerName: this.dealerName,
        date: this.datePDF,
        vehicle: {
          year: this.vehicle.year,
          make: this.vehicle.make,
          model: this.vehicle.model,
          netCapCost: this.formatCurrency(this.netCapCostNumber),
        },
        services: {
          term: this.taxes.term,
          residual: this.taxes.residual,
          residualAmount: this.formatCurrency(this.residualAmount),
          paymentTiming: this.taxes.paymentTiming.label,
        },
        monthlyPaymentWithTax: this.formatCurrency(this.estimatedMonthlyPaymentWithTax),
        monthlyPaymentWithoutTax: this.formatCurrency(this.estimatedMonthlyPaymentWithoutTax),
        dealerParticipationCode: this.dealerParticipationCode,
      },
    });
  },
};
</script>
