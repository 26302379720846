import VueJwtDecode from 'vue-jwt-decode';
import apiManager from '@/api';
import CountryCodeHelper from '@/helpers/countryCodes';

const DEFAULT_LOCATIONS = {
  none: '',
  usa: 'USA',
  can: 'Canada',
};

const userStore = {
  namespaced: true,
  state: {
    locations: DEFAULT_LOCATIONS,
    activeLocation: DEFAULT_LOCATIONS.none,
    token: '',
    expires_in: 0,
    dealerName: '',
    dealerPandaCode: '',
    dealerCity: '',
    dealerAddress: '',
    dealerState: '',
    dealerZip: '',
    dealerPreferedLanguage: 'en',
    dealerPhone: '',
    dealerEmail: '',
    dealerCountry: '',
    isDealer: false,
  },
  getters: {
    locations: (state) => state.locations,
    activeLocation: (state) => state.activeLocation,
    activeLocationIsCan: (state) => state.activeLocation === DEFAULT_LOCATIONS.can,
    activeLocationIsUsa: (state) => state.activeLocation === DEFAULT_LOCATIONS.usa,
    token: (state) => state.token,
    expires_in: (state) => state.expires_in,
    dealerName: (state) => state.dealerName,
    dealerPandaCode: (state) => state.dealerPandaCode,
    dealerAddress: (state) => state.dealerAddress,
    dealerCity: (state) => state.dealerCity,
    dealerState: (state) => state.dealerState,
    dealerZip: (state) => state.dealerZip,
    dealerPreferedLanguage: (state) => state.dealerPreferedLanguage,
    dealerPhone: (state) => state.dealerPhone,
    dealerCountry: (state) => state.dealerCountry,
    dealerEmail: (state) => state.dealerEmail,
    isDealer: (state) => state.isDealer,
    tokenExpired: (state) => Date.now() / 1000 >= state.expires_in * 1000,
    logged_in: (state) => !state.tokenExpired && state.token !== '',
    userData: (state) => {
      const result = {
        name: state.dealerName,
        isDealer: state.isDealer ? 'yes' : 'no',
        email: state.dealerEmail,
        country: state.activeLocation,
        pandaCode: state.dealerPandaCode,
      };
      return result;
    },
  },
  mutations: {
    setActiveLocation: (state, location) => {
      // Checks if location is valid
      if (!Object.values(state.locations).includes(location)) return;

      state.activeLocale = location;
    },
    defineTokenData: (state, tokenData) => {
      const tokenContent = VueJwtDecode.decode(tokenData);
      state.token = tokenData;
      state.dealerName = tokenContent.DealerName;
      state.dealerEmail = tokenContent.email;
      state.dealerPandaCode = tokenContent.DealerCode;
      state.dealerPhone = tokenContent.DealerPhone;
      state.dealerAddress = tokenContent.DealerAddress;
      state.dealerCity = tokenContent.DealerCity;
      state.dealerState = tokenContent.DealerState;
      state.dealerZip = tokenContent.DealerZip;
      state.dealerPreferedLanguage = tokenContent.DealerPreferedLanguage;
      state.expires_in = tokenContent.exp;
      state.isDealer = tokenContent.IsDealer === '1' || false;
      state.activeLocation = DEFAULT_LOCATIONS.usa;
      if (tokenContent.DealerCountry === 'USA') state.activeLocation = DEFAULT_LOCATIONS.usa;
      if (tokenContent.DealerCountry === 'CAN') state.activeLocation = DEFAULT_LOCATIONS.can;
      const countries = new CountryCodeHelper();
      const foundCountry = countries.data.find((x) => x.code === tokenContent.DealerCountry);
      state.dealerCountry = foundCountry.name;
    },
    resetTokenData: (state) => {
      state.token = '';
      state.dealerName = '';
      state.dealerEmail = '';
      state.dealerPandaCode = '';
      state.dealerPhone = '';
      state.dealerAddress = '';
      state.dealerCity = '';
      state.dealerState = '';
      state.dealerZip = '';
      state.dealerPreferedLanguage = '';
      state.expires_in = 0;
      state.isDealer = false;
      state.activeLocation = DEFAULT_LOCATIONS.none;
      state.dealerCountry = '';
    },
  },
  actions: {
    change({ commit }, location) {
      commit('setActiveLocation', location);
    },
    async doLogin() {
      const result = await apiManager.Login.getAuth();
      return result;
    },
    decodeToken({ commit }, token) {
      commit('defineTokenData', token);
    },
    reset({ commit }) {
      commit('resetTokenData');
    },
  },
};

export default userStore;
