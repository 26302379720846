 <template>
  <RenderPDF
    name="CommerciaLease Capital Cost"
    :definition="pdfConfig"
    :data-customerName="customerName"
    :data-offeringNumber="offeringNumber"
    :data-date="datePDF"
    :data-paymentTiming="taxes.paymentTiming.label"
    :data-vehicle-year="vehicle.year"
    :data-vehicle-make="vehicle.make"
    :data-vehicle-model="vehicle.model"
    :data-vehicle-vin="vehicle.vin"
    :data-equipments-list="equipments.list"
    :data-equipments-factoryInvoice="equipments.factoryInvoice"
    :data-equipments-totalInvoice="formatCurrency(totalInvoice)"
    :data-equipments-totalCost="formatCurrency(totalCostOfVehicle)"
    :data-equipments-vehicleMarkup="taxes.vehicleMarkup"
    :data-equipments-addEquipment="equipments.addEquipExcluded"
    :data-equipments-capInitial="formatCurrency(capitalizedInitialTitleSum)"
    :data-upFront-localTax="upFront.localTax"
    :data-upFront-stateTax="upFront.stateTax"
    :data-upFront-fetTax="upFront.fetTax"
    :data-upFront-otherTax="upFront.otherTax"
    :data-upFront-upFrontTax="formatCurrency(totalUpfrontTaxes)"
    :data-upFront-capitalizedCost="formatCurrency(capitalizedCost)"
    :data-upFront-serviceContract="taxes.serviceContract"
    :data-upFront-gap="taxes.gap"
    :data-upFront-capitalizedCostReduction="taxes.capitalizedCostReduction"
    :data-upFront-rebates="taxes.rebates"
    :data-upFront-tradeAllowance="taxes.tradeAllowance"
    :data-upFront-tradeOwed="taxes.tradeOwed"
    :data-upFront-netTradeAllowance="formatCurrency(netTradeAllowance)"
    :data-upFront-finalNetCapCost="formatCurrency(finalNetCapCost)"
    :data-upFront-percentFinalNetCapCost="formatCurrency(
      percentFinalNetCapCost.toFixed(2), '', '%'
    )"
    :data-upFront-assumedRP="taxes.residual"
    :data-upFront-assumedRPValue="formatCurrency(assumedRPValue)"
    :data-upFront-vehicleMSRP="taxes.rebates"
    :data-customerRate="taxes.customerLeaseRate"
    :data-term="taxes.term"
    :data-supplementDate="taxes.supplementDate"
    :data-commencementDate="commencementDate"
    :data-paymentDueDay="taxes.paymentDueDay"
    :data-dealerParticipation="formatCurrency(dealerParticipation)"
    :data-cityTax="taxes.cityTax"
    :data-countyTax="taxes.countyTax"
    :data-stateTax="taxes.stateTax"
    :data-otherTax="taxes.otherTax"
    :data-totalTax="totalTaxFromTaxes"
    :data-advance-basePayment="formatCurrency(advanceBasePayment)"
    :data-advance-tax="formatCurrency(advanceTax.toFixed(2))"
    :data-advance-paymentIncluding="formatCurrency(advancePaymentIncludingTax)"
    :data-advance-firstPayment="commencementDate"
    :data-advance-manufacturerRepurchase="taxes.manufacturingRepurchase"
    :data-arrears-basePayment="formatCurrency(arrearsBasePayment)"
    :data-arrears-tax="formatCurrency(arrearsTax.toFixed(2))"
    :data-arrears-paymentIncluding="formatCurrency(arrearsPaymentIncludingTax)"
    :data-arrears-firstPayment="formatCurrency(firstPaymentScheduleDateArrears)"
    data-arrears-manufacturerRepurchase="-"
  />
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { ExcelPMT, ConvertToNumber } from '@/utilities/functions/pdf';
import RenderPDF from '@/components/calculators/RenderPDF.vue';
import definePDF from './TemplatePDF';

export default {
  name: 'FinishCapitalCost  ',
  components: {
    RenderPDF,
  },
  data() {
    return {
      pdfConfig: {},
    };
  },
  computed: {
    ...mapGetters({
      activeLocale: 'locales/activeLocale',
      customerName: 'capitalCost/customerName',
      offeringNumber: 'capitalCost/offeringNumber',
      equipments: 'capitalCost/equipments',
      upFront: 'capitalCost/upFront',
      vehicle: 'capitalCost/vehicle',
      taxes: 'capitalCost/taxes',
      table1: 'capitalCost/table1',
    }),

    datePDF() {
      return moment(new Date()).format('MMM DD, YYYY');
    },

    totalCostOfVehicle() {
      const equipments = this.equipments.list;
      if (equipments.length > 0) {
        return equipments.map((item) => ConvertToNumber(item.value))
          .reduce((prev, next) => prev + next);
      }

      return [];
    },

    totalInvoice() {
      return ConvertToNumber(this.equipments.factoryInvoice) + this.totalCostOfVehicle;
    },

    capitalizedInitialTitleSum() {
      return ConvertToNumber(this.equipments.capitalizedInitialTitleTax)
        + ConvertToNumber(this.equipments.capitalizedInitialTitleNoTax);
    },

    totalUpfrontTaxes() {
      const localTax = ConvertToNumber(this.upFront.localTax);
      const stateTax = ConvertToNumber(this.upFront.stateTax);
      const fetTax = ConvertToNumber(this.upFront.fetTax);
      const otherTax = ConvertToNumber(this.upFront.otherTax);
      return localTax + stateTax + fetTax + otherTax;
    },

    capitalizedCost() {
      const vehicleMarkup = ConvertToNumber(this.taxes.vehicleMarkup);
      const addEquipExcluded = ConvertToNumber(this.equipments.addEquipExcluded);
      if (this.equipments.areTaxesCapitalized === 'Yes') {
        return this.totalInvoice + vehicleMarkup + this.totalUpfrontTaxes
         + addEquipExcluded + this.capitalizedInitialTitleSum;
      }
      return this.totalInvoice + vehicleMarkup + addEquipExcluded
          + this.capitalizedInitialTitleSum;
    },
    netTradeAllowance() {
      return ConvertToNumber(this.taxes.tradeOwed) - (ConvertToNumber(this.taxes.tradeAllowance));
    },
    finalNetCapCost() {
      const serviceContract = ConvertToNumber(this.taxes.serviceContract);
      const maintenancePlan = ConvertToNumber(this.taxes.maintenancePlan);
      const capitalizedCostReduction = ConvertToNumber(this.taxes.capitalizedCostReduction);
      const rebates = ConvertToNumber(this.taxes.rebates);
      return this.capitalizedCost + serviceContract + maintenancePlan
      - (capitalizedCostReduction) - (rebates)
      + this.netTradeAllowance + ConvertToNumber(this.taxes.gap);
    },
    percentFinalNetCapCost() {
      return ((this.finalNetCapCost / this.totalInvoice) * 100);
    },
    assumedRPValue() {
      return (this.finalNetCapCost) * (ConvertToNumber(this.taxes.residual) / 100);
    },
    totalTaxFromTaxes() {
      const cityTax = ConvertToNumber(this.taxes.cityTax);
      const stateTax = ConvertToNumber(this.taxes.stateTax);
      const county = ConvertToNumber(this.taxes.countyTax);
      const otherTax = ConvertToNumber(this.taxes.otherTax);
      return cityTax + stateTax + county + otherTax;
    },
    firstPaymentScheduleDateArrears() {
      const paymentDueDay = ConvertToNumber(this.taxes.paymentDueDay);
      const { supplementDate } = this.taxes;
      if (paymentDueDay < moment().date()) {
        return moment(supplementDate).add(2, 'M').set('date', paymentDueDay).format('MM/DD/YYYY');
      }
      return moment(supplementDate).add(1, 'M').set('date', paymentDueDay).format('MM/DD/YYYY');
    },
    calc() {
      const serviceContract = ConvertToNumber(this.taxes.serviceContract);
      const maintenancePlan = ConvertToNumber(this.taxes.maintenancePlan);
      const gap = ConvertToNumber(this.taxes.gap);
      const rebates = ConvertToNumber(this.taxes.rebates);
      const addEquipExcluded = ConvertToNumber(this.equipments.addEquipExcluded);
      const vehicleMarkup = ConvertToNumber(this.taxes.vehicleMarkup);
      const y = this.equipments.areTaxesCapitalized === 'Yes' ? this.totalUpfrontTaxes : 0;

      const x = (this.totalInvoice + addEquipExcluded + vehicleMarkup)
      + (this.taxes.dropdownMaintenancePlan.value === 'monthly' ? serviceContract + maintenancePlan : 0)
      + (this.taxes.dropdownGap.value === 'monthly' ? 0 : -(gap))
      - (rebates)
      + this.netTradeAllowance
      + ConvertToNumber(this.equipments.capitalizedInitialTitleTax)
      + y;
      return x;
    },
    arrearsBasePayment() {
      const customerLR = ConvertToNumber(this.taxes.customerLeaseRate) / 100;
      const term = ConvertToNumber(this.taxes.term);
      return ExcelPMT(customerLR / 12, term, -(this.finalNetCapCost), this.assumedRPValue, 0);
    },
    arrearsTax() {
      const p53 = this.calc;

      const customerLR = ConvertToNumber(this.taxes.customerLeaseRate) / 100;
      const term = ConvertToNumber(this.taxes.term);
      const x = ExcelPMT(customerLR / 12, term, -(p53), this.assumedRPValue, 0);
      const totalTax = this.totalTaxFromTaxes;
      return x * (totalTax / 100);
    },

    arrearsPaymentIncludingTax() {
      return Number(this.arrearsTax.toFixed(2)) + Number(this.arrearsBasePayment.toFixed(2));
    },

    advanceBasePayment() {
      const customerLR = ConvertToNumber(this.taxes.customerLeaseRate) / 100;
      const term = ConvertToNumber(this.taxes.term);
      return ExcelPMT(customerLR / 12, term, -(this.finalNetCapCost), this.assumedRPValue, 1);
    },

    advanceTax() {
      const p53 = this.calc;

      const customerLR = ConvertToNumber(this.taxes.customerLeaseRate) / 100;
      const term = ConvertToNumber(this.taxes.term);
      const x = ExcelPMT(customerLR / 12, term, -(p53), this.assumedRPValue, 1);
      const totalTax = this.totalTaxFromTaxes;
      return x * (totalTax / 100);
    },

    advancePaymentIncludingTax() {
      return Number(this.advanceTax.toFixed(2)) + Number(this.advanceBasePayment.toFixed(2));
    },

    commencementDate() {
      const paymentDueDay = ConvertToNumber(this.taxes.paymentDueDay);
      const { supplementDate } = this.taxes;
      if (paymentDueDay < moment().date()) {
        return moment(supplementDate).add(1, 'M').set('date', paymentDueDay).format('MM/DD/YYYY');
      }
      return moment(supplementDate).set('date', paymentDueDay).format('MM/DD/YYYY');
    },
    dealerParticipation() {
      const customerLR = ConvertToNumber(this.taxes.customerLeaseRate) / 100;
      const term = ConvertToNumber(this.taxes.term);
      const dealerParticipation = ConvertToNumber(this.taxes.dealerParticipation) / 100;
      const advanceL = ExcelPMT(
        customerLR / 12,
        term,
        -(this.finalNetCapCost),
        this.assumedRPValue,
        1,
      );
      const advanceR = ExcelPMT(
        (customerLR - (dealerParticipation)) / 12,
        term,
        -(this.finalNetCapCost),
        this.assumedRPValue,
        1,
      );
      const arrearsL = ExcelPMT(
        customerLR / 12,
        term,
        -(this.finalNetCapCost),
        this.assumedRPValue,
        0,
      );
      const arrearsR = ExcelPMT(
        (customerLR - (dealerParticipation)) / 12,
        term,
        -(this.finalNetCapCost),
        this.assumedRPValue,
        0,
      );

      if (this.taxes.paymentTiming.value === 'advance') {
        return (advanceL - (advanceR)) * term;
      }
      return (arrearsL - (arrearsR)) * term;
    },
  },
  mounted() {
    const sectionPdf = 'tabs.capitalCost.sectionPDF';
    const sectionVehicle = `${sectionPdf}.sectionVehicle`;
    const sectionEquipments = `${sectionPdf}.sectionEquipments`;
    const sectionUpFront = `${sectionPdf}.sectionUpFront`;
    this.pdfConfig = definePDF({
      labels: {
        title: this.$t(`${sectionPdf}.title`),
        subtitle: this.$t(`${sectionPdf}.subtitle`),
        customerNameLabel: this.$t(`${sectionPdf}.customerNameLabel`),
        offeringNumberLabel: this.$t(`${sectionPdf}.offeringNumberLabel`),
        vehicle: {
          title: this.$t(`${sectionVehicle}.title`),
          yearLabel: this.$t(`${sectionVehicle}.yearLabel`),
          makeLabel: this.$t(`${sectionVehicle}.makeLabel`),
          modelLabel: this.$t(`${sectionVehicle}.modelLabel`),
          vinLabel: this.$t(`${sectionVehicle}.vinLabel`),
        },
        equipments: {
          title: this.$t(`${sectionEquipments}.title`),
          addedEquipmentsLabel: this.$t(`${sectionEquipments}.addedEquipmentsLabel`),
          equipmentsValueLabel: this.$t(`${sectionEquipments}.equipmentsValueLabel`),
          factoryInvoiceLabel: this.$t(`${sectionEquipments}.factoryInvoiceLabel`),
          totalInvoiceLabel: this.$t(`${sectionEquipments}.totalInvoiceLabel`),
          totalCostLabel: this.$t(`${sectionEquipments}.totalCostLabel`),
          vehicleMarkupLabel: this.$t(`${sectionEquipments}.vehicleMarkupLabel`),
          addEquipmentLabel: this.$t(`${sectionEquipments}.additionalEquipmentLabel`),
          capInitialLabel: this.$t(`${sectionEquipments}.capitalizedInitialTitleLabel`),
        },
        upFront: {
          title: this.$t(`${sectionUpFront}.title`),
          localTaxLabel: this.$t(`${sectionUpFront}.table.localTax`),
          stateTaxLabel: this.$t(`${sectionUpFront}.table.stateTax`),
          totalTaxLabel: this.$t(`${sectionUpFront}.table.totalTax`),
          fetTaxLabel: this.$t(`${sectionUpFront}.table.fetTax`),
          otherTaxLabel: this.$t(`${sectionUpFront}.table.otherTax`),
          upFrontTaxLabel: this.$t(`${sectionUpFront}.table.upFrontTax`),
          cCostLabel: this.$t(`${sectionUpFront}.capitalizedCostLabel`),
          serviceCLabel: this.$t(`${sectionUpFront}.serviceContractLabel`),
          warrantyCostLabel: this.$t(`${sectionUpFront}.warrantyCostLabel`),
          gapLabel: this.$t(`${sectionUpFront}.gapLabel`),
          cCostRLabel: this.$t(`${sectionUpFront}.capitalizedCostReductionLabel`),
          rebatesLabel: this.$t(`${sectionUpFront}.rebatesLabel`),
          tradeALabel: this.$t(`${sectionUpFront}.tradeAllowanceLabel`),
          tradeOwedLabel: this.$t(`${sectionUpFront}.tradeOwedLabel`),
          netTALabel: this.$t(`${sectionUpFront}.netTradeAllowanceLabel`),
          finalNetCCostLabel: this.$t(`${sectionUpFront}.finalNetCapitalizedCostLabel`),
          subtitle: this.$t(`${sectionUpFront}.subtitle`),
          assumedRPLabel: this.$t(`${sectionUpFront}.assumedResidualPercentLabel`),
          vehicleMSRPLabel: this.$t(`${sectionUpFront}.vehicleMSRPLabel`),
          table1: {
            leftTitle1: this.$t(`${sectionUpFront}.table1.leftTitle1`),
            leftTitle2: this.$t(`${sectionUpFront}.table1.leftTitle2`),
            leftTitle3: this.$t(`${sectionUpFront}.table1.leftTitle3`),
            leftTitle4: this.$t(`${sectionUpFront}.table1.leftTitle4`),
            leftTitle5: this.$t(`${sectionUpFront}.table1.leftTitle5`),
            leftTitle6: this.$t(`${sectionUpFront}.table1.leftTitle6`),
            leftTitle7: this.$t(`${sectionUpFront}.table1.leftTitle7`),
            leftTitle8: this.$t(`${sectionUpFront}.table1.leftTitle8`),
            leftTitle9: this.$t(`${sectionUpFront}.table1.leftTitle9`),
            leftTitle10: this.$t(`${sectionUpFront}.table1.leftTitle10`),
            leftTitle11: this.$t(`${sectionUpFront}.table1.leftTitle11`),
          },
          table2: {
            topTitle1: this.$t(`${sectionUpFront}.table2.topTitle1`),
            topTitle2: this.$t(`${sectionUpFront}.table2.topTitle2`),
            topTitle3: this.$t(`${sectionUpFront}.table2.topTitle3`),
            topTitle4: this.$t(`${sectionUpFront}.table2.topTitle4`),
            topTitle5: this.$t(`${sectionUpFront}.table2.topTitle5`),
            leftSubtitle1: this.$t(`${sectionUpFront}.table2.leftSubtitle1`),
            leftSubtitle2: this.$t(`${sectionUpFront}.table2.leftSubtitle2`),
            information: this.$t(`${sectionUpFront}.table2.information`),
            arrearsAttention: this.$t(`${sectionUpFront}.table2.arrearsAttention`),
          },
        },
      },
      info: {
        customerName: this.customerName,
        offeringNumber: this.offeringNumber,
        date: this.datePDF,
        paymentTiming: this.taxes.paymentTiming.value,
        vehicle: {
          year: this.vehicle.year,
          make: this.vehicle.make,
          model: this.vehicle.model,
          vin: this.vehicle.vin,
        },
        equipments: {
          list: this.equipments.list,
          factoryInvoice: this.equipments.factoryInvoice,
          totalInvoice: this.formatCurrency(this.totalInvoice),
          totalCost: this.formatCurrency(this.totalCostOfVehicle),
          vehicleMarkup: this.taxes.vehicleMarkup,
          addEquipment: this.equipments.addEquipExcluded,
          capInitial: this.formatCurrency(this.capitalizedInitialTitleSum),
        },
        upFront: {
          table: {
            localTax: this.upFront.localTax,
            stateTax: this.upFront.stateTax,
            fetTax: this.upFront.fetTax,
            otherTax: this.upFront.otherTax,
            upFrontTax: this.formatCurrency(this.totalUpfrontTaxes),
          },
          cCost: this.formatCurrency(this.capitalizedCost),
          serviceC: this.taxes.serviceContract,
          warrantyCost: this.taxes.maintenancePlan,
          gap: this.taxes.gap,
          cCostR: this.taxes.capitalizedCostReduction,
          rebates: this.taxes.rebates,
          tradeA: this.taxes.tradeAllowance,
          tradeOwed: this.taxes.tradeOwed,
          netTA: this.formatCurrency(this.netTradeAllowance),
          finalNetCCost: this.formatCurrency(this.finalNetCapCost),
          percentFinalNetCCost: this.formatCurrency(this.percentFinalNetCapCost.toFixed(2), '', '%'),
          assumedRP: this.formatCurrency(ConvertToNumber(this.taxes.residual).toFixed(2), '', '%'),
          assumedRPValue: this.formatCurrency(this.assumedRPValue),
          vehicleMSRP: this.taxes.vehicleMSRP,
        },
        table1: {
          customerRate: this.formatCurrency(ConvertToNumber(this.taxes.customerLeaseRate).toFixed(2), '', '%'),
          term: ConvertToNumber(this.taxes.term),
          supplementDate: this.taxes.supplementDate,
          commencementDate: this.commencementDate,
          paymentDueDay: ConvertToNumber(this.taxes.paymentDueDay),
          dealerParticipation: this.formatCurrency(this.dealerParticipation),
          dealerParticipationPercent: this.taxes.dealerParticipation,
          cityTax: this.formatCurrency(ConvertToNumber(this.taxes.cityTax).toFixed(2), '', '%'),
          countyTax: this.formatCurrency(ConvertToNumber(this.taxes.countyTax).toFixed(2), '', '%'),
          stateTax: this.formatCurrency(ConvertToNumber(this.taxes.stateTax).toFixed(2), '', '%'),
          otherTax: this.formatCurrency(ConvertToNumber(this.taxes.otherTax).toFixed(2), '', '%'),
          totalTax: this.formatCurrency(this.totalTaxFromTaxes, '', '%'),
        },
        table2: {
          advance: {
            basePayment: this.formatCurrency(this.advanceBasePayment),
            tax: this.formatCurrency(this.advanceTax.toFixed(2)),
            paymentIncluding: this.formatCurrency(this.advancePaymentIncludingTax),
            firstPayment: this.commencementDate,
            manufacturerRepurchase: this.taxes.manufacturingRepurchase,
          },
          arrears: {
            basePayment: this.formatCurrency(this.arrearsBasePayment),
            tax: this.formatCurrency(this.arrearsTax.toFixed(2)),
            paymentIncluding: this.formatCurrency(this.arrearsPaymentIncludingTax),
            firstPayment: this.firstPaymentScheduleDateArrears,
            manufacturerRepurchase: '-',
          },
        },
      },
    });
  },
};
</script>
