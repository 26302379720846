export default {
  // Info Dealer/Customer
  sectionNames: {
    customerNameLabel: '@:tabs.general.sectionNames.customerNameLabel',
    dealerNameLabel: '@:tabs.general.sectionNames.dealerNameLabel',
  },

  // Info Vehicle
  sectionVehicle: {
    title: '@:tabs.general.sectionVehicle.title',
    yearLabel: '@:tabs.general.sectionVehicle.yearLabel',
    makeLabel: '@:tabs.general.sectionVehicle.makeLabel',
    modelLabel: '@:tabs.general.sectionVehicle.modelLabel',
    vinLabel: '@:tabs.general.sectionVehicle.vinLabel',
  },

  // Services and Taxes
  sectionTaxes: {
    title: '@:tabs.quickQuote.sectionTaxes.title',
    sellingPriceLabel: '@:tabs.effectiveRate.sectionTaxes.sellingPriceLabel',
    sellingPriceInfo: '@:tabs.effectiveRate.sectionTaxes.sellingPriceInfo',
    leaseTermLabel: '@:tabs.effectiveRate.sectionTaxes.leaseTermLabel',
    retailTermLabel: '@:tabs.effectiveRate.sectionTaxes.retailTermLabel',
    cashDownPaymentLabel: '@:tabs.effectiveRate.sectionTaxes.cashDownPaymentLabel',
    commercialLeaseRebateLabel: 'CommerciaLease Rebate',
    retailRebateLabel: 'Retail Rebate',
    tradeEquityLabel: '@:tabs.effectiveRate.sectionTaxes.tradeEquityLabel',
    taxesUpfrontLabel: 'Taxes Upfront',
    residualLabel: '@:tabs.quickQuote.sectionTaxes.residualLabel',
    customerLeaseRateLabel: '@:tabs.effectiveRate.sectionTaxes.customerLeaseRateLabel',
    customerRetailRateLabel: '@:tabs.effectiveRate.sectionTaxes.customerRetailRateLabel',
    paymentsInAdvanceLabel: 'Payments in Advance',
    taxRateLabel: 'Tax Rate (%)',
    notesLabel: 'Notes',
  },

  // PDF
  sectionPDF: {
    title: '@:mainTitle',
    subtitle: 'Payment Comparison Calculator',

    // Info Vehicle
    sectionVehicle: {
      title: '@:tabs.general.sectionVehicle.title',
      yearLabel: '@:tabs.general.sectionVehicle.yearLabel',
      makeLabel: '@:tabs.general.sectionVehicle.makeLabel',
      modelLabel: '@:tabs.general.sectionVehicle.modelLabel',
      commercialLeaseTermLabel: 'CommerciaLease Term',
      vinLabel: '@:tabs.general.sectionVehicle.vinLabel',
    },

    // Info Table Retail
    sectionTableRetail: {
      title: 'Purchase',
      topSubtitle1: 'Selling Price',
      topSubtitle2: 'Cash Down Payment',
      topSubtitle3: 'Trade Equity',
      topSubtitle4: 'Rebate',
      topSubtitle5: 'Local Sales Tax Rate',
      topSubtitle6: 'Amount Financed (w/Tax)',
      topSubtitle7: 'Term',
      topSubtitle8: 'Customer Rate',
      topSubtitle9: 'Finance Charge',
      topSubtitle10: 'Payment With Tax',
      information: '* This may be taxable please verify with the State',
    },

    // Info Table CommerciaLease
    sectionTableCommercialLease: {
      title: 'CommerciaLease',
      topSubtitle1: 'Cap Cost',
      topSubtitle2: 'Cash Down Payment',
      topSubtitle3: 'Trade Equity',
      topSubtitle4: 'Cap Cost Reduction (Rebate)*',
      topSubtitle5: 'Local Sales Tax Rate',
      topSubtitle6_1: 'Net Cap Cost (amount Financed)',
      topSubtitle6_2: 'Net Cap Cost (amount Financed w/Tax)',
      topSubtitle7: 'Term',
      topSubtitle8: 'Residual (%)',
      topSubtitle9: 'Payments in Advance',
      topSubtitle10: 'Customer Rate',
      topSubtitle11: 'Residual Amount',
      topSubtitle12: 'Base Payment',
      topSubtitle13: 'Payment With Tax',
    },

    // Notes
    sectionNotes: {
      title: 'Notes:',
    },

    // Signature and Disclaimer
    sectionSignature: {
      customerName: '@:tabs.general.sectionSignature.customerName',
      dealerName: '@:tabs.general.sectionSignature.dealerName',
      signature: '@:tabs.general.sectionSignature.signature',
      disclaimer: '@:tabs.general.sectionSignature.disclaimer',
      disclaimerTextUSA: '@:tabs.general.sectionSignature.disclaimerTextUSA',
    },
  },
};
