export default {
  // Info Dealer/Customer
  sectionNames: {
    customerNameLabel: '@:tabs.general.sectionNames.customerNameLabel',
    dealerNameLabel: '@:tabs.general.sectionNames.dealerNameLabel',
  },

  // Info Vehicle
  sectionVehicle: {
    title: '@:tabs.general.sectionVehicle.title',
    yearLabel: '@:tabs.general.sectionVehicle.yearLabel',
    makeLabel: '@:tabs.general.sectionVehicle.makeLabel',
    modelLabel: '@:tabs.general.sectionVehicle.modelLabel',
    vinLabel: '@:tabs.general.sectionVehicle.vinLabel',
  },

  // Services and Taxes
  sectionTaxes: {
    title: '@:tabs.quickQuote.sectionTaxes.title',
    sellingPriceLabel: '@:tabs.effectiveRate.sectionTaxes.sellingPriceLabel',
    sellingPriceInfo: '@:tabs.effectiveRate.sectionTaxes.sellingPriceInfo',
    leaseTermLabel: '@:tabs.effectiveRate.sectionTaxes.leaseTermLabel',
    retailTermLabel: '@:tabs.effectiveRate.sectionTaxes.retailTermLabel',
    cashDownPaymentLabel: '@:tabs.effectiveRate.sectionTaxes.cashDownPaymentLabel',
    commercialLeaseRebateLabel: 'CommerciaLease Rebate',
    retailRebateLabel: 'Retail Rebate',
    tradeEquityLabel: '@:tabs.effectiveRate.sectionTaxes.tradeEquityLabel',
    taxesUpfrontLabel: 'Taxes Upfront',
    residualLabel: '@:tabs.quickQuote.sectionTaxes.residualLabel',
    customerLeaseRateLabel: '@:tabs.effectiveRate.sectionTaxes.customerLeaseRateLabel',
    customerRetailRateLabel: '@:tabs.effectiveRate.sectionTaxes.customerRetailRateLabel',
    paymentsInAdvanceLabel: 'Payments in Advance',
    taxRateLabel: '[NT] Tax Rate (%)',
    notesLabel: '[NT] Notes',
  },

  // PDF
  sectionPDF: {
    title: '@:mainTitle',
    subtitle: '[NT] Payment Comparison Calculator',

    // Info Vehicle
    sectionVehicle: {
      title: '@:tabs.general.sectionVehicle.title',
      yearLabel: '@:tabs.general.sectionVehicle.yearLabel',
      makeLabel: '@:tabs.general.sectionVehicle.makeLabel',
      modelLabel: '@:tabs.general.sectionVehicle.modelLabel',
      commercialLeaseTermLabel: '[NT] CommerciaLease Term',
      vinLabel: '@:tabs.general.sectionVehicle.vinLabel',
    },

    // Info Table Retail
    sectionTableRetail: {
      title: '[NT] Purchase',
      topSubtitle1: '[NT] Selling Price',
      topSubtitle2: '[NT] Cash Down Payment',
      topSubtitle3: '[NT] Trade Equity',
      topSubtitle4: '[NT] Rebate',
      topSubtitle5: '[NT] Local Sales Tax Rate',
      topSubtitle6: '[NT] Amount Financed (w/Tax)',
      topSubtitle7: '[NT] Term',
      topSubtitle8: '[NT] Customer Rate',
      topSubtitle9: '[NT] Finance Charge',
      topSubtitle10: '[NT] Payment With Tax',
      information: '[NT] * This may be taxable please verify with the State',
    },

    // Info Table CommercialLease
    sectionTableCommercialLease: {
      title: '[NT] CommerciaLease',
      topSubtitle1: '[NT] Cap Cost',
      topSubtitle2: '[NT] Cash Down Payment',
      topSubtitle3: '[NT] Trade Equity',
      topSubtitle4: '[NT] Cap Cost Reduction (Rebate)*',
      topSubtitle5: '[NT] Local Sales Tax Rate',
      topSubtitle6_1: '[NT] Net Cap Cost (amount Financed)',
      topSubtitle6_2: '[NT] Net Cap Cost (amount Financed w/Tax)',
      topSubtitle7: '[NT] Term',
      topSubtitle8: '[NT] Residual (%)',
      topSubtitle9: '[NT] Payments in Advance',
      topSubtitle10: '[NT] Customer Rate',
      topSubtitle11: '[NT] Residual Amount',
      topSubtitle12: '[NT] Base Payment',
      topSubtitle13: '[NT] Payment With Tax',
    },

    // Notes
    sectionNotes: {
      title: '[NT] Notes',
    },

    // Signature and Disclaimer
    sectionSignature: {
      customerName: '@:tabs.general.sectionSignature.customerName',
      dealerName: '@:tabs.general.sectionSignature.dealerName',
      signature: '@:tabs.general.sectionSignature.signature',
    },
  },
};
