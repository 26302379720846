<template>
  <div class="fds-datepicker" :class="datepickerClasses">
    <DatePicker
      class="v-calendar"
      ref="datePicker"
      v-model="dateSelected"
      :min-date="minDate"
      :max-date="maxDate"
      :model-config="modelConfig"
      locale="en-US"
      @dayclick="isClickedDate($event)">
      <template v-slot="{ inputValue, inputEvents }">
        <label
          :for="`datepicker--${id}`"
          class="button-wrapper"
          @click.stop="openDatepicker"
          aria-hidden
          >
          <span class="label" :class="labelClasses">
            {{ label }}
            <i
              v-if="hasBeenValidated"
              class="fds-icon fds-icon--offset-right fds-icon--16"
              :class="iconClasses">
            </i>
          </span>
          <input
            :id="`datepicker--${id}`"
            :name="`datepicker--${name}`"
            class="field"
            :class="inputClasses"
            :value="inputValue"
            @keyup="onKeyUp"
            @blur="onBlur"
            v-on="inputEvents"
            :disabled="disabled"/>
          <span class="error-message" :id="`datepicker--${id}-error`">{{ errorMessage }}</span>
        </label>
      </template>
    </DatePicker>
  </div>
</template>
<script>
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';
import keyCodes from '../../utilities/constants/keysCodes.const';

export default {
  name: 'FdsDatepicker',
  components: { DatePicker },
  props: {
    id: { type: String, required: true },
    name: { type: String, required: true },
    label: { type: String, default: '' },
    setDate: { type: String },
    minDate: { type: Date },
    maxDate: { type: Date },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    submitted: { type: Boolean, required: true, default: false },
    darkTheme: { type: Boolean, default: false },
  },
  data() {
    return {
      showDatepicker: false,
      dateSelected: '',
      isFilled: false,
      keyFocused: false,
      hasBeenValidated: false,
      hasBeenOpened: false,
      hasError: false,
      errorMessage: '',
      modelConfig: {
        type: 'string',
        mask: 'MM/DD/YYYY',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: new Date(),
        },
      ],
    };
  },
  computed: {
    datepickerClasses() {
      return {
        'key-focused': this.keyFocused,
        success: this.hasBeenValidated && this.isFilled && !this.hasError,
        error: this.hasBeenValidated && this.hasError,
        'dark-theme': this.darkTheme,
        'light-theme': !this.darkTheme,
      };
    },
    labelClasses() {
      return {
        active: this.showDatepicker,
        filled: this.isFilled,
      };
    },
    iconClasses() {
      return {
        'fds-font--ford-icons__success': this.hasBeenValidated && this.isFilled && !this.hasError,
        'fds-font--ford-icons__error': this.hasBeenValidated && this.hasError,
      };
    },
    inputClasses() {
      return {
        active: this.showDatepicker,
        filled: this.isFilled,
        disabled: this.disabled,
      };
    },
    calendarClasses() {
      return {
        active: this.showDatepicker,
        success: this.hasBeenValidated && this.isFilled && !this.hasError,
        error: this.hasBeenValidated && this.hasError,
      };
    },
  },
  methods: {
    checkIfIsValid() {
      if (this.disabled || !this.required) return;
      this.hasBeenValidated = true;
      if (this.required && this.dateSelected === '') {
        this.errorMessage = this.$t('tabs.general.form.requiredField');
        this.hasError = true;
        this.$emit('hasError', { name: this.name, error: true });
      } else {
        this.hasError = false;
        this.$emit('hasError', { name: this.name, error: false });
      }
    },
    openDatepicker() {
      if (!this.disabled) {
        if (this.hasBeenOpened) {
          this.checkIfIsValid();
        } else {
          this.hasBeenOpened = true;
        }
        this.showDatepicker = !this.showDatepicker;
      }
    },
    isClickedDate() {
      this.checkIfIsValid();
      this.isFilled = true;
      this.showDatepicker = false;
      this.$emit('select', this.dateSelected);
    },
    zeroBefore(num) {
      return num <= 9 ? `0${num}` : num;
    },
    onKeyUp(e) {
      if (!this.disabled && e.keyCode === keyCodes.TAB) {
        this.keyFocused = true;
        this.openDatepicker();
        this.$emit('keyup', e.keyCode);
      }
    },
    async onBlur() {
      if (!this.disabled && this.keyFocused) {
        this.keyFocused = false;
        this.openDatepicker();
        this.checkIfIsValid();
      }

      if (this.dateSelected) this.isClickedDate();

      this.$emit('blur');
    },
    formatDate(date) {
      if (date.length === 0) return;
      const newDate = date.includes('/') ? new Date(date) : date;
      this.isFilled = true;
      const year = newDate.getFullYear();
      const month = this.zeroBefore((newDate.getMonth() + 1).toString());
      const day = this.zeroBefore(newDate.getDate().toString());
      this.dateSelected = `${month}/${day}/${year}`;
      this.$emit('select', this.dateSelected);
    },
  },
  watch: {
    required(value) {
      if (value) {
        this.checkIfIsValid();
      } else {
        this.hasError = false;
        this.$emit('hasError', { name: this.name, error: false });
      }
    },
    submitted() {
      this.checkIfIsValid();
    },
    dateSelected() {
      this.checkIfIsValid();
    },
  },
  mounted() {
    if (this.required && this.dateSelected === '') {
      this.$emit('hasError', { name: this.name, error: true });
    }
    if (this.setDate) this.formatDate(this.setDate);
    window.addEventListener('click', () => { this.showDropdown = false; });
  },
};
</script>
<style lang="scss">
.fds-datepicker {
  position: relative;
  width: 100%;
  max-width: 280px;

  .label {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin: fds-rem(0 0 5px 15px);
    white-space: nowrap;
    font-size: fds-rem(16px);
    line-height: fds-rem(16px);
    letter-spacing: fds-rem(1px);
    pointer-events: none;
    z-index: 1;
    transform: translate(0, fds-rem(40px));
    will-change: font-size, transform;
    transition:
      font-size 0.3s ease-in-out,
      transform 0.3s ease-in-out;

    &.active,
    &.filled {
      transform: translate(fds-rem(-15px),0);
      font-size: fds-rem(12px);
    }

    &.active {
      & ~ .fds-icon-wrapper {
        transform: rotate(180deg);
      }
    }

    .fds-icon {
      font-family: $fds-font--ford-icons;

      &.error::before {
        content: $fds-font--ford-icons__error;
      }
      &.success::before {
        content: $fds-font--ford-icons__success;
      }
    }
  }

  .field {
    @extend %fmc-type--body1;
    padding: 0 fds-rem(15px);
    width: 100%;
    height: fds-rem(50px);
    background-color: $fds-color--gray1;
    color: $fds-color--gray3;
    border: solid fds-rem(1px) $fds-color--gray2;
    border-radius: fds-rem(3px);
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
    -webkit-appearance: none; /* stylelint-disable property-no-vendor-prefix */

    :-webkit-autofill {
      -webkit-text-fill-color: $fds-color--gray3;
    }

    &.filled,
    &.active {
      background-color: $fds-color--white;
      color: $fds-color--primary;
    }

    &.active:not(.disabled) {
      border-radius: fds-rem(3px 3px 0 0);
    }
    &.disabled {
      background-color: $fds-color--disabled3;
      cursor: not-allowed;
      &::placeholder {
        color: $fds-color--white !important;
      }
    }
  }

  .error-message {
    position: absolute;
    left: 0;
    bottom: fds-rem(-18px);
    width: 100%;
    color: $fds-color--error1;
    font-size: fds-rem(12px);
    line-height: fds-rem(18px);
    letter-spacing: fds-rem(1px);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &.light-theme {
    .label {
      color: $fds-color--gray3;
    }
    .field {
      background-color: $fds-color--gray1;
      color: $fds-color--gray3;
    }

    &.disabled {
      .label {
        font-weight: 300;
      }

      .label,
      .fds-icon {
        color: $fds-color--white;
      }
    }
  }

  &.dark-theme {
    .field {
      background-color: $fds-color--white;
      color: $fds-color--gray3;
    }

    &.disabled {
      .label {
        font-weight: 300;
      }

      .label {
        color: $fds-color--white;
      }
    }
  }

  &.success {
    .field,
    .list {
      border-color: var(--fds-color--success1);
    }
  }

  &.error {
    .field,
    .list {
      border-color: var(--fds-color--error1);
    }
    .error-message {
      opacity: 1;
    }
  }
  &.key-focused {
    .calendar::before {
      content: '';
      position: absolute;
      left: fds-rem(-10px);
      top: fds-rem(-100px);
      right: fds-rem(-10px);
      bottom: fds-rem(-10px);
      border: 1px solid $fds-color--primary;
    }
  }
}
</style>
