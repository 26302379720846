<template>
  <button
    id="floating-button"
    class="floating-button"
    :class="buttonClasses"
    @click="handleClick"
    role="Button"
    :aria-label="text"
  >
    <span class="floating-button__text">
      <i
        v-if="icon"
        class="floating-button__icon fds-icon--12"
        :class="`fds-font--ford-icons__${icon}`"
      />
      <span class="floating-button__label">{{ text }}</span>
    </span>
  </button>
</template>

<script>

export default {
  name: 'FloatingButton',
  emits: ['click'],
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    buttonClasses() {
      return {
        'floating-button--active': this.show,
        'floating-button--primary': !this.secondary,
        'floating-button--secondary': this.secondary,
      };
    },
  },
  methods: {
    handleClick() {
      this.$emit('onClick');
    },
  },
};
</script>

<style lang="scss" scoped>
.floating-button {
  position: fixed;
  right: 24px;
  bottom: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 40px;
  height: 40px;
  padding: 0;
  background: transparent;
  color: $fds-color--white;
  letter-spacing: inherit;
  border: 0;
  outline: none;
  opacity: 0;
  transition:
    padding .3s linear,
    opacity .3s linear;
  z-index: 1000;

  @include media('>=1440px') {
    right: calc((100vw - 1440px) / 2);
  }

  &--active {
    opacity: 1;
  }

  &--primary {
    &:focus-visible::after {
      outline-color: $fds-color--primary;
    }
    &::before {
      background-color: $fds-color--primary;
    }
    &:disabled::before {
      background-color: $fds-color--disabled3;
    }
    &:not(:disabled):focus-visible::before,
    &:not(:disabled):hover::before {
      background-color: $fds-color--secondary;
    }
  }

  &--secondary {
    color: $fds-color--primary;
    &:focus-visible::after {
      outline-color: $fds-color--white;
    }
    &::before {
      background-color: $fds-color--white;
    }
    &:disabled {
      color: $fds-color--black;
      &::before {
        background-color: $fds-color--disabled2;
      }
    }
    &:not(:disabled):focus-visible::before,
    &:not(:disabled):hover::before {
      background-color: $fds-color--gray1;
    }
    &.floating-button--outline {
      color: $fds-color--white;
      &::before {
        box-shadow: none !important;
        background-color: transparent;
        border-color: $fds-color--white;
      }
      &:not(:disabled):focus-visible::before,
      &:not(:disabled):hover::before {
        background-color: rgba($fds-color--white, 0.05);
      }
      &:disabled {
        color: $fds-color--disabled2;
        &::before {
          border-color: $fds-color--disabled2;
        }
      }
    }
  }

  &::before {
    content: '';
    border: 1px solid transparent;
    border-radius: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: $fds-elevation__box-shadow--layer2;
    transition:
      box-shadow 0.3s ease-in-out,
      transform 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:disabled::before {
    box-shadow: $fds-elevation__box-shadow--layer1;
  }

  &:focus-visible::after {
    outline: 1px solid;
    outline-offset: 10px;
  }

  &:not(:disabled):focus-visible,
  &:not(:disabled):hover {
    padding: 0 32px;

    &::before {
      transform: scale(1.07, 1.04);
      box-shadow: $fds-elevation__box-shadow--layer3;
    }

    .floating-button__label {
      max-width: 200px;
      padding-left: 16px;
    }
  }

  &__label {
    display: inline-block;
    max-width: 0;
    font-family: $fds-font--antenna;
    font-size: fds-rem(16px);
    font-weight: normal;
    overflow: hidden;
    transition: max-width .3s linear, padding-left .3s linear;
  }

  &__text {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    white-space: nowrap;
  }
}
</style>
