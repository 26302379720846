<template>
  <header id="header">
    <div class="container header-area">
      <div class="logo-area">
        <img class="logo" src="../assets/ford-pro-finsimple-logo.svg" alt="Logo Ford Pro FinSimple">
      </div>
      <div class="dealership-info">
        <p class="address">
          <span>{{ address }}</span>
          <span>{{ cityStateCountry }}</span>
          <span>{{ zip }}</span>
          <span>P&A Code: {{ pandaCode }}</span>
          <span>{{ phone }}</span>
        </p>
        <div class="name" id="dealership--name">
          <span id="span--dealership--name">{{ currentDealerName }}</span>
          <button class="logout" @click="signOut()">
            <img class="logout-icon" src="../assets/icon-logout.svg" alt="Icon Logout">
          </button>
        </div>
      </div>
    </div>
    <div class="menu-bar">
      <div class="container">
        <div class="title">{{ currentLocation }}</div>
        <Toggle
          v-if="!activeLocationIsUsa"
          name="header-locale"
          :labelLeft="$t('tabs.general.form.toggleLang.en')"
          :labelRight="$t('tabs.general.form.toggleLang.fr')"
          :isChecked="defaultLanguage"
          theme="dark"
          @update="changeLocale($event)"
        />
      </div>
    </div>
  </header>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Toggle from '@/components/form-elements/ToggleDoubleLabel.vue';

export default {
  name: 'TheHeader',
  components: { Toggle },
  data() {
    return {
      dealerName: this.$store.getters['user/dealerName'],
    };
  },
  computed: {
    ...mapGetters({
      currentDealerName: 'user/dealerName',
      currentLocation: 'user/activeLocation',
      activeLocationIsUsa: 'user/activeLocationIsUsa',
      locations: 'user/locations',
      pandaCode: 'user/dealerPandaCode',
      phone: 'user/dealerPhone',
      dealerPreferedLanguage: 'user/dealerPreferedLanguage',
      activeLocale: 'locales/activeLocale',
      locales: 'locales/locales',
    }),
    defaultLanguage() {
      return !(this.activeLocale === this.locales.en);
    },
    pandaCode() {
      return this.$store.getters['user/dealerPandaCode'];
    },
    phone() {
      const dPhone = this.$store.getters['user/dealerPhone'];
      if (dPhone) return `Phone: ${dPhone}`;
      return '';
    },
    address() {
      return this.$store.getters['user/dealerAddress'];
    },
    zip() {
      const zipCode = this.$store.getters['user/dealerZip'];
      if (zipCode) return `ZIP: ${zipCode}`;
      return '';
    },
    cityStateCountry() {
      const dCity = this.$store.getters['user/dealerCity'];
      const dState = this.$store.getters['user/dealerState'];
      let dCountry = this.$store.getters['user/dealerCountry'];

      if (dCountry === 'USA') dCountry = 'United States';
      if (dCountry === 'CAN') dCountry = 'Canada';

      if (dCity && dState && dCountry) {
        return `${dCity} - ${dState} - ${dCountry}`;
      }

      if (!dCity && !dState && dCountry) {
        return dCountry;
      }

      return '';
    },
  },
  methods: {
    ...mapActions({
      updateLocale: 'locales/updateLocale',
      resetUser: 'user/reset',
    }),
    changeLocale(locale) {
      const newLocale = locale ? this.locales.fr : this.locales.en;
      this.updateLocale(newLocale);
    },
    signOut() {
      localStorage.removeItem('tokenLoginInformation');
      this.resetUser();
      this.$router.push({ name: 'logout' });
    },
  },
  created() {
    // Set default lang based on user preference
    this.updateLocale(this.dealerPreferedLanguage);
  },
};
</script>
<style lang="scss" scoped>
#header {
  position: relative;
  margin-bottom: fds-rem(60px);
  z-index: 1;

  @include media('>=600px') {
    background-color: $fds-color--gray1;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1640px;
    margin: 0 auto;

    @include media('>=600px') {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: fds-rem(20px 0);
    }

    .logo-area{
      @include media('<=600px') {
        display: flex;
        justify-content: space-between;
        padding: fds-rem(40px 0);
        width: 100%;
        background-color: $fds-color--gray1;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.20);
      }
    }

    .logo {
      display: block;
      max-width: 240px;
      @include media('<=600px') {
        margin: fds-rem(0 30px);
      }
      @include media('>=600px') {
        margin: fds-rem(0 40px);
      }
      @include media('>=1920px') {
        margin-left: 0;
      }
    }

    .dealership-info {
      max-width: 500px;
      padding: fds-rem(30px 30px 0);
      @include media('<=600px') {
        width: 100%;
      }
      @include media('>=600px') {
        margin-left: fds-rem(40px);
        padding: fds-rem(0 40px 0 0);
      }
      @include media('>=1024px') {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
      }

      .address {
        width: 100%;
        color: $fds-color--black;
        font-size: fds-rem(11px);
        @include media('>=1024px') {
          text-align: right;
          border-right: fds-rem(1px) solid $fds-color--gray4;
          padding: fds-rem(10px 20px 10px 20px);
        }

        span {
          display: block;
        }
      }

      .name {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $fds-color--primary;
        font-weight: 500;
        font-size: fds-rem(18px);
        margin-top: fds-rem(20px);
        padding-top: fds-rem(20px);
        border-top: fds-rem(1px) solid $fds-color--gray4;
        white-space: pre-line;
        // word-wrap:break-word;
        @include media('>=1024px') {
          max-width: 190px;
          margin: fds-rem(0 0 0 20px);
          padding-top: fds-rem(0px);
          border-top: 0;
        }
        @include media('<=600px') {
          border-top: none;
          border-bottom: fds-rem(1px) solid $fds-color--gray4;
          padding-bottom: fds-rem(20px);
          margin-top: fds-rem(0px);

        }
        img {
          margin: fds-rem(0 0 0 40px);
        }
      }
    }
  }

  .menu-bar {
    padding: fds-rem(20px 0);
    background-color: $fds-color--primary;
    font-size: fds-rem(16px);
    font-weight: var(--fmc-type__weight-medium);
    line-height: fds-rem(25px);
    @include media('<=600px') {
      display: none;
    }

    .container {
      padding: fds-rem(0 30px);
      @include media('>=1024px') {
        padding: fds-rem(0 30px 0 140px);
      }
    }
    .title {
      color: $fds-color--white;
      font-size: 1em;
    }

    .link,
    .label {
      color: $fds-color--white;
      white-space: nowrap;
    }

    .link {
      display: block;
      margin: fds-rem(30px 0);
      font-weight: var(--fmc-type__weight-normal);
      @include media('>=1024px') {
        margin: fds-rem(0);
      }
    }
  }
}
</style>
