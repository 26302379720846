export default {
  // Info Dealer/Customer
  sectionNames: {
    customerNameLabel: '@:tabs.general.sectionNames.customerNameLabel',
    dealerNameLabel: '@:tabs.general.sectionNames.dealerNameLabel',
  },

  // Info Vehicle
  sectionVehicle: {
    title: '@:tabs.general.sectionVehicle.title',
    yearLabel: '@:tabs.general.sectionVehicle.yearLabel',
    makeLabel: '@:tabs.general.sectionVehicle.makeLabel',
    modelLabel: '@:tabs.general.sectionVehicle.modelLabel',
    netCapCostLabel: '@:tabs.general.sectionVehicle.netCapCostLabel',
  },

  // Services and Taxes
  sectionTaxes: {
    title: 'Termes',
    termLabel: 'Période',
    residualLabel: '(%) de valeur de résiduelle',
    customerRateLabel: '(%) Taux du client',
    customerRateInfo: 'Incluant la participation du concessionnaire',
    dealerParticipationLabel: '(%) Participation du concessionnaire',
    paymentTimingLabel: 'Fréquence de paiement ',
    paymentTimingOption1: 'Au début',
    paymentTimingOption2: 'À la fin',
    taxRateLabel: '(%) Taux de taxe',
  },

  // PDF
  sectionPDF: {
    title: '@:mainTitle',
    subtitle: 'Location Commerciale - Cotation rapide',

    // Info Vehicle
    sectionVehicle: {
      title: '@:tabs.general.sectionVehicle.title',
      yearLabel: '@:tabs.general.sectionVehicle.yearLabel',
      makeLabel: '@:tabs.general.sectionVehicle.makeLabel',
      modelLabel: '@:tabs.general.sectionVehicle.modelLabel',
      netCapCostLabel: '@:tabs.general.sectionVehicle.netCapCostLabel',
    },

    // Services and Taxes
    sectionTaxes: {
      title: '@:tabs.quickQuote.sectionTaxes.title',
      termLabel: '@:tabs.quickQuote.sectionTaxes.termLabel',
      residualLabel: '@:tabs.quickQuote.sectionTaxes.residualLabel',
      residualAmountLabel: 'Valeur résiduelle',
      paymentTimingLabel: '@:tabs.quickQuote.sectionTaxes.paymentTimingLabel',
    },

    // Estimated Monthly
    sectionEstimated: {
      title1: 'Paiement Mensuel Estimatif (Sans les Taxes)',
      title2: 'Paiement Mensuel Estimatif (Avec Taxes)',
    },

    // Signature and Disclaimer
    sectionSignature: {
      customerName: '@:tabs.general.sectionSignature.customerName',
      dealerName: '@:tabs.general.sectionSignature.dealerName',
      signature: '@:tabs.general.sectionSignature.signature',
      disclaimer: '@:tabs.general.sectionSignature.disclaimer',
      disclaimerTextUSA: '@:tabs.general.sectionSignature.disclaimerTextUSA',
      disclaimerTextCAN: 'Ceci ne constitue pas une offre ou une approbation de Compagnie Crédit Ford du Canada. Il s’agit d’une estimation des paiements qui peuvent être disponibles à un Locataire selon certaines hypothèses de location. L’éventuelle approbation de crédit de Crédit Ford sera basée sur une analyse indépendante de Crédit Ford quant à la solvabilité réelle du Locataire et les modalités de la transaction proposée seront soumises à la vérification et d’pprobation finale de Compagnie Crédit Ford du Canada.',
    },
  },
};
