<template>
  <div>
    <center>
      <p>Something went wrong, you can try again in the button bellow</p>
      <Button
        id="return-to-login"
        class="fds-m--t-3"
        :label="'Login'"
        @onClick="retryLogin"
      />
    </center>
  </div>
</template>
<script>
import Button from '@/components/form-elements/Button.vue';

export default {
  name: 'HomeView',
  components: {
    Button,
  },
  methods: {
    retryLogin() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
