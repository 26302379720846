export default {
  namespaced: true,
  state: {
    customerName: '',
    dealerName: '',
    vehicle: {
      year: '',
      make: 'Ford',
      model: '',
      vin: '',
    },
    taxes: {
      sellingPrice: '',
      leaseTerm: '',
      retailTerm: '',
      alternateRetailTerm: '',
      cashDownPayment: '',
      serviceContract: '',
      fleetConcessions: '',
      tradeEquity: '',
      residual: '',
      customerLeaseRate: '',
      customerRetailRate: '',
      monthlyMaintenanceCharge: '',
      alternateRetailRate: '',
    },
    competitor: {
      sellingPrice: '',
      fleetConcessions: '',
      cashDownPayment: '',
      serviceContract: '',
      residualAmount: '',
      competitorPayment: '',
      paymentTiming: {
        label: '',
        value: '',
      },
      upfrontFees: '',
      monthlyManagementFees: '',
      monthlyMaintenanceCharge: '',
      leaseEndServiceCharge: '',
    },
  },
  getters: {
    customerName: (state) => state.customerName,
    dealerName: (state) => state.dealerName,
    vehicle: (state) => state.vehicle,
    taxes: (state) => state.taxes,
    competitor: ((state) => state.competitor),
  },
  mutations: {
    /**
     * Set Customer Name
     * @param {String} name - Name of Customer
     */
    setCustomerName: (state, name) => {
      // Checks if name is valid
      if (state.customerName === name) return;

      state.customerName = name;
    },

    /**
     * Set Dealer Name
     * @param {String} name - Name of Dealer
     */
    setDealerName: (state, name) => {
      // Checks if name is valid
      if (state.dealerName === name) return;

      state.dealerName = name;
    },

    /**
     * Set vehicle information
     * @param {String} year - Year of vehicle
     * @param {String} make - Make of vehicle
     * @param {String} model - Model of vehicle
     * @param {String} vin - VIN
     */
    setVehicle: (
      state,
      {
        year,
        make,
        model,
        vin,
      },
    ) => {
      state.vehicle = {
        year,
        make,
        model,
        vin,
      };
    },

    /**
     * Set Services and Taxes
     * @param {String} sellingPrice - Selling Price
     * @param {String} leaseTerm - Lease Term
     * @param {String} retailTerm - Retail Term
     * @param {String} alternateRetailTerm - AlternateRetail Term
     * @param {String} cashDownPayment - Cash Down Payment
     * @param {String} serviceContract - Service Contract
     * @param {String} fleetConcessions - Fleet Concessions
     * @param {String} tradeEquity - Trade Equity
     * @param {String} residual - Residual
     * @param {String} customerLeaseRate - Customer Lease Rate
     * @param {String} customerRetailRate - Customer Retail Rate
     * @param {String} monthlyMaintenanceCharge - Monthly Maintenance Charge
     * @param {String} alternateRetailRate - Alternate Retail Rate
     */
    setTaxes: (
      state,
      {
        sellingPrice,
        leaseTerm,
        retailTerm,
        alternateRetailTerm,
        cashDownPayment,
        serviceContract,
        fleetConcessions,
        tradeEquity,
        residual,
        customerLeaseRate,
        customerRetailRate,
        monthlyMaintenanceCharge,
        alternateRetailRate,
      },
    ) => {
      state.taxes = {
        sellingPrice,
        leaseTerm,
        retailTerm,
        alternateRetailTerm,
        cashDownPayment,
        serviceContract,
        fleetConcessions,
        tradeEquity,
        residual,
        customerLeaseRate,
        customerRetailRate,
        monthlyMaintenanceCharge,
        alternateRetailRate,
      };
    },

    /**
     * Set Competitor Details
     * @param {String} sellingPrice - Selling Price
     * @param {String} fleetConcessions - Fleet Concessions
     * @param {String} cashDownPayment - Cash Down Payment
     * @param {String} serviceContract - Service Contract
     * @param {String} residualAmount - Residual Amount
     * @param {String} competitorPayment - Competitor Payment
     * @param {String} paymentTiming - Payment Timing
     * @param {String} upfrontFees - Upfront Fees
     * @param {String} monthlyManagementFees - Monthly Management Fees
     * @param {String} monthlyMaintenanceCharge - Monthly Maintenance Charge
     * @param {String} leaseEndServiceCharge - Lease End Service Charge
     */
    setCompetitor: (
      state,
      {
        sellingPrice,
        fleetConcessions,
        cashDownPayment,
        serviceContract,
        residualAmount,
        competitorPayment,
        paymentTiming,
        upfrontFees,
        monthlyManagementFees,
        monthlyMaintenanceCharge,
        leaseEndServiceCharge,
      },
    ) => {
      state.competitor = {
        sellingPrice,
        fleetConcessions,
        cashDownPayment,
        serviceContract,
        residualAmount,
        competitorPayment,
        paymentTiming,
        upfrontFees,
        monthlyManagementFees,
        monthlyMaintenanceCharge,
        leaseEndServiceCharge,
      };
    },

    /**
     * Clear all information from Effective Rate
     */
    setClearEffectiveRate: (state) => {
      state.customerName = '';
      state.dealerName = '';
      state.vehicle.year = '';
      state.vehicle.make = '';
      state.vehicle.model = '';
      state.vehicle.vin = '';
      state.taxes.sellingPrice = '';
      state.taxes.leaseTerm = '';
      state.taxes.retailTerm = '';
      state.taxes.alternateRetailTerm = '';
      state.taxes.cashDownPayment = '';
      state.taxes.serviceContract = '';
      state.taxes.fleetConcessions = '';
      state.taxes.tradeEquity = '';
      state.taxes.residual = '';
      state.taxes.customerLeaseRate = '';
      state.taxes.customerRetailRate = '';
      state.taxes.monthlyMaintenanceCharge = '';
      state.taxes.alternateRetailRate = '';
      state.competitor.sellingPrice = '';
      state.competitor.fleetConcessions = '';
      state.competitor.cashDownPayment = '';
      state.competitor.serviceContract = '';
      state.competitor.residualAmount = '';
      state.competitor.competitorPayment = '';
      state.competitor.paymentTiming = {
        label: '',
        value: '',
      };
      state.competitor.upfrontFees = '';
      state.competitor.monthlyManagementFees = '';
      state.competitor.monthlyMaintenanceCharge = '';
      state.competitor.leaseEndServiceCharge = '';
    },
  },
  actions: {
    /**
     * Update the Customer Name
     * @param {String} name - Name of Customer
     */
    updateCustomerName({ commit }, name) {
      commit('setCustomerName', name);
    },

    /**
     * Update the Dealer Name
     * @param {String} name - Name of Dealer
     */
    updateDealerName({ commit }, name) {
      commit('setDealerName', name);
    },

    /**
     * Update the Vehicle Information
     * @param {Object} vehicle - Data of Vehicle
     * @param {String} vehicle.year - Year of vehicle
     * @param {String} vehicle.make - Make of vehicle
     * @param {String} vehicle.model - Model of vehicle
     * @param {String} vehicle.vin - VIN
     */
    updateVehicle({ commit }, vehicle) {
      commit('setVehicle', vehicle);
    },

    /**
     * Update Services and Taxes
     * @param {Object} taxes - Data of Taxes
     * @param {String} taxes.sellingPrice - Selling Price
     * @param {String} taxes.leaseTerm - Lease Term
     * @param {String} taxes.retailTerm - Retail Term
     * @param {String} taxes.alternateRetailTerm - AlternateRetail Term
     * @param {String} taxes.cashDownPayment - Cash Down Payment
     * @param {String} taxes.serviceContract - Service Contract
     * @param {String} taxes.fleetConcessions - Fleet Concessions
     * @param {String} taxes.tradeEquity - Trade Equity
     * @param {String} taxes.residual - Residual
     * @param {String} taxes.customerLeaseRate - Customer Lease Rate
     * @param {String} taxes.customerRetailRate - Customer Retail Rate
     * @param {String} taxes.monthlyMaintenanceCharge - Monthly Maintenance Charge
     * @param {String} taxes.alternateRetailRate - Alternate Retail Rate
     */
    updateTaxes({ commit }, taxes) {
      commit('setTaxes', taxes);
    },

    /**
     * Update Services and Taxes
     * @param {Object} competitor - Data of Taxes
     * @param {String} competitor.sellingPrice - Selling Price
     * @param {String} competitor.fleetConcessions - Fleet Concessions
     * @param {String} competitor.cashDownPayment - Cash Down Payment
     * @param {String} competitor.serviceContract - Service Contract
     * @param {String} competitor.residualAmount - Residual Amount
     * @param {String} competitor.competitorPayment - Competitor Payment
     * @param {Object} competitor.paymentTiming - Payment Timing
     * @param {String} competitor.upfrontFees - Upfront Fees
     * @param {String} competitor.monthlyManagementFees - Monthly Management Fees
     * @param {String} competitor.monthlyMaintenanceCharge - Monthly Maintenance Charge
     * @param {String} competitor.leaseEndServiceCharge - Lease End Service Charge
     */
    updateCompetitor({ commit }, competitor) {
      commit('setCompetitor', competitor);
    },

    /**
     * Update All Effective Rate Form
     * @param {Object} data - Data of Effective Rate
     * @param {String} data.customerName - Name of Customer
     * @param {String} data.dealerName - Name of Dealer
     * @param {Object} data.vehicle - Data of Vehicle
     * @param {Object} data.taxes - Data of Taxes
     * @param {Object} data.competitor - Data of Competitor
     */
    updateEffectiveRate({ commit }, data) {
      commit('setCustomerName', data.customerName);
      if (data.dealerName !== undefined) {
        commit('setDealerName', data.dealerName);
      }
      commit('setVehicle', data.vehicle);
      if (data.taxes !== undefined) {
        commit('setTaxes', data.taxes);
      }
      if (data.competitor !== undefined) {
        commit('setCompetitor', data.competitor);
      }
    },

    /**
     * Clear All Effective Rate Form
     */
    clearEffectiveRate({ commit }) {
      commit('setClearEffectiveRate');
    },
  },
};
