export default {
  namespaced: true,
  state: {
    customerName: '',
    dealerName: '',
    vehicle: {
      year: '',
      make: 'Ford',
      model: '',
      netCapCost: '',
    },
    taxes: {
      term: '',
      residual: '',
      customerRate: '',
      dealerParticipation: '',
      paymentTiming: {
        label: '',
        value: '',
      },
      taxRate: '',
    },
  },
  getters: {
    customerName: (state) => state.customerName,
    dealerName: (state) => state.dealerName,
    vehicle: (state) => state.vehicle,
    taxes: (state) => state.taxes,
  },
  mutations: {
    /**
     * Set Customer Name
     * @param {String} name - Name of Customer
     */
    setCustomerName: (state, name) => {
      // Checks if name is valid
      if (state.customerName === name) return;

      state.customerName = name;
    },

    /**
     * Set Dealer Name
     * @param {String} name - Name of Dealer
     */
    setDealerName: (state, name) => {
      // Checks if name is valid
      if (state.dealerName === name) return;

      state.dealerName = name;
    },

    /**
     * Set vehicle information
     * @param {String} year - Year of vehicle
     * @param {String} make - Make of vehicle
     * @param {String} model - Model of vehicle
     * @param {String} netCapCost - Net Cap Cost
     */
    setVehicle: (
      state,
      {
        year,
        make,
        model,
        netCapCost,
      },
    ) => {
      state.vehicle = {
        year,
        make,
        model,
        netCapCost,
      };
    },

    /**
     * Set Services and Taxes
     * @param {String} term - Number of months
     * @param {String} residual - Residual (%)
     * @param {String} customerRate - Customer Rate (%)
     * @param {String} dealerParticipation - Dealer Participation (%)
     * @param {String} paymentTiming - Type of Payment Timing
     * @param {String} taxRate - Tax Rate (%)
     */
    setTaxes: (
      state,
      {
        term,
        residual,
        customerRate,
        dealerParticipation,
        paymentTiming,
        taxRate,
      },
    ) => {
      state.taxes = {
        term,
        residual,
        customerRate,
        dealerParticipation,
        paymentTiming,
        taxRate,
      };
    },

    /**
     * Clear all information from Quick Quote
     */
    setClearQuickQuote: (state) => {
      state.customerName = '';
      state.dealerName = '';
      state.vehicle.year = '';
      state.vehicle.make = '';
      state.vehicle.model = '';
      state.vehicle.netCapCost = '';
      state.taxes.term = '';
      state.taxes.residual = '';
      state.taxes.customerRate = '';
      state.taxes.dealerParticipation = '';
      state.taxes.paymentTiming = {
        label: '',
        value: '',
      };
      state.taxes.taxRate = '';
    },
  },
  actions: {
    /**
     * Update the Customer Name
     * @param {String} name - Name of Customer
     */
    updateCustomerName({ commit }, name) {
      commit('setCustomerName', name);
    },

    /**
     * Update the Dealer Name
     * @param {String} name - Name of Dealer
     */
    updateDealerName({ commit }, name) {
      commit('setDealerName', name);
    },

    /**
     * Update the Vehicle Information
     * @param {Object} vehicle - Data of Vehicle
     * @param {String} vehicle.year - Year of vehicle
     * @param {String} vehicle.make - Make of vehicle
     * @param {String} vehicle.model - Model of vehicle
     * @param {String} vehicle.netCapCost - Net Cap Cost
     */
    updateVehicle({ commit }, vehicle) {
      commit('setVehicle', vehicle);
    },

    /**
     * Update Services and Taxes
     * @param {Object} taxes - Data of Taxes
     * @param {String} taxes.term - Number of months
     * @param {String} taxes.residual - Residual (%)
     * @param {String} taxes.customerRate - Customer Rate (%)
     * @param {String} taxes.dealerParticipation - Dealer Participation (%)
     * @param {Object} taxes.paymentTiming - Type of Payment Timing
     * @param {String} taxes.taxRate - Tax Rate (%)
     */
    updateTaxes({ commit }, taxes) {
      commit('setTaxes', taxes);
    },

    /**
     * Update All Quick Quote Form
     * @param {Object} data - Data of Quick Quote
     * @param {String} data.customerName - Name of Customer
     * @param {String} data.dealerName - Name of Dealer
     * @param {Object} data.vehicle - Data of Vehicle
     * @param {Object} data.taxes - Data of Taxes
     */
    updateQuickQuote({ commit }, data) {
      commit('setCustomerName', data.customerName);
      if (data.taxes !== undefined) {
        commit('setDealerName', data.dealerName);
      }
      commit('setVehicle', data.vehicle);
      if (data.taxes !== undefined) {
        commit('setTaxes', data.taxes);
      }
    },

    /**
     * Clear All Quick Quote Form
     */
    clearQuickQuote({ commit }) {
      commit('setClearQuickQuote');
    },
  },
};
