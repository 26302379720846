<template>
  <div
    class="fds-activity-indicator fds-activity-indicator__circular"
    data-aria-loading-message="Please wait while page loads."
    data-aria-loading-complete-message="Loading live region is complete."
    data-aria-determinate-percent-message="loaded">
    <div class="progress-wrapper" role="progressbar">
      <div class="progress-line">
        <svg class="fds-activity-indicator__progress-line_svg" viewBox="25 25 50 50">
          <circle class="circle" cx="50" cy="50" r="23" fill="none" stroke-miterlimit="10"/>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'CircularIndeterminate',
};
</script>

<style lang="scss" scoped>
@-webkit-keyframes circular-indeterminate-animation {
  0% {
    stroke-dashoffset: 200;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes circular-indeterminate-animation {
  0% {
    stroke-dashoffset: 200;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.fds-activity-indicator {
  .progress-wrapper {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 3px;
      top: 3px;
      border: 0.1rem solid var(--fds-color--disabled3);
      border-radius: 50%;
      width: fds-rem(74px);
      height: fds-rem(74px);
    }

    svg {
      width: fds-rem(80px);
      height: fds-rem(80px);
      transform: rotate(-90deg);

      circle {
        stroke-dasharray: 38,162;
        stroke-dashoffset: 0;
        stroke-width: 0.2rem;
        -webkit-animation: circular-indeterminate-animation 3s linear infinite;
        animation: circular-indeterminate-animation 3s linear infinite;
        stroke-linecap: round;
        stroke: var(--fds-color--primary);
      }
    }
  }
}
</style>
