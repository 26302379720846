/**
 * Generates a parameter string to ensure fresh data is returned, if desired.
 * @param {boolean} cached Whether to retrieved cached data or not
 * @returns {string} empty string if cached, a unique identifier if not wanting cached data
 */
export const getCachedParams = (cached) => (cached ? '' : `?unique=${Math.round(+new Date() / 1000)}`);

export default class baseAPI {
  constructor(axiosObject, apiBaseName) {
    this.axiosObject = axiosObject;
    this.apiBaseName = apiBaseName;

    this.getCachedParams = getCachedParams;
  }
}
