export const ExcelRate = (periods, payment, present, future, type, guess) => {
  /* eslint-disable no-param-reassign */
  guess = (guess === undefined) ? 0.01 : guess;
  future = (future === undefined) ? 0 : future;
  type = (type === undefined) ? 0 : type;

  // Set maximum epsilon for end of iteration
  const epsMax = 1e-10;

  // Set maximum number of iterations
  const iterMax = 50;

  // Implement Newton's method
  let y;
  let y0;
  let y1;
  let x0;
  let x1 = 0;
  let f = 0;
  let i = 0;
  let rate = guess;
  if (Math.abs(rate) < epsMax) {
    y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
  } else {
    f = Math.exp(periods * Math.log(1 + rate));
    y = present * f + payment * (1 / rate + type) * (f - 1) + future;
  }
  y0 = present + payment * periods + future;
  y1 = present * f + payment * (1 / rate + type) * (f - 1) + future;
  x0 = 0;
  i = x0;
  x1 = rate;
  while ((Math.abs(y0 - y1) > epsMax) && (i < iterMax)) {
    rate = (y1 * x0 - y0 * x1) / (y1 - y0);
    x0 = x1;
    x1 = rate;
    if (Math.abs(rate) < epsMax) {
      y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
    } else {
      f = Math.exp(periods * Math.log(1 + rate));
      y = present * f + payment * (1 / rate + type) * (f - 1) + future;
    }
    y0 = y1;
    y1 = y;
    i += i;
  }
  return rate;
};

/**
 * Payment per period
 * @param {Number} ratePerPeriod - tax per period
 * @param {Number} numOfPayments - total number of payments
 * @param {Number} presentValue - present value
 * @param {Number} futureValue - future value
 * @param {Number} type - 0 period end term and 1 period start term
 */
export const ExcelPMT = (ratePerPeriod, numOfPayments, presentValue, futureValue, type) => {
  const validFutureValue = typeof futureValue !== 'undefined' ? futureValue : 0;
  const validType = typeof type !== 'undefined' ? type : 0;

  if (ratePerPeriod !== 0.0) {
    // Interest rate exists
    const q = (1 + ratePerPeriod) ** numOfPayments;
    const z = validFutureValue + (q * presentValue);
    return -(ratePerPeriod * z) / ((-1 + q) * (1 + ratePerPeriod * (validType)));
  }
  if (numOfPayments !== 0.0) {
    // No interest rate, but number of payments exists
    return -(validFutureValue + presentValue) / numOfPayments;
  }
  return 0;
};

/**
  * Convert a value to a number
  * @param {String} value - Your percentual or amount
  */
export const ConvertToNumber = (value) => Number(value
  .replace(/[^0-9.]/, '')
  .replace('%', '')
  .replace(',', ''));

export default { ExcelRate, ExcelPMT, ConvertToNumber };
