<template>
  <div class="fds-table-equipment">
    <table class="fds-table-equipment__table">
      <thead class="fds-table-equipment__head">
        <tr class="fds-table-equipment__row">
            <th class="fds-table-equipment__title">{{ componentText.description }}</th>
            <th class="fds-table-equipment__title">{{ componentText.value }}</th>
            <th class="fds-table-equipment__title">{{ componentText.action }}</th>
        </tr>
      </thead>
      <tbody class="fds-table-equipment__body">
        <tr
          class="fds-table-equipment__row"
          v-for="(equip, index) in equipments"
          :key="index"
          >
          <td class="fds-table-equipment__col description">
            <Input
              :id="`fds-table-equipment-description-${index}`"
              :name="`fds-table-equipment-description-${index}`"
              :class="hasBeenValidated && equip.hasError ? 'error' : ''"
              class="fds-table-equipment__field"
              placeholder="Description"
              v-model:value="equip.description"
              :required="required ? required : equip.value !== ''"
              :hasLabel="false"
              submitted
              @change="updateErrors()"
            />
          </td>
          <td class="fds-table-equipment__col value">
            <InputCurrency
              :id="`fds-table-equipment-value-${index}`"
              :name="`fds-table-equipment-value-${index}`"
              class="fds-table-equipment__field"
              :class="hasBeenValidated && equip.hasError ? 'error' : ''"
              placeholder="Value"
              v-model:value="equip.value"
              :required="required ? required : equip.description !== ''"
              :hasLabel="false"
              submitted
              @change="updateErrors()"
            />
          </td>
          <td class="fds-table-equipment__col action">
            <Button
              :id="`fds-table-equipment-delete-${index}`"
              class="fds-table-equipment__delete"
              label="Delete"
              :primary="false"
              isLink
              @onClick="deleteLine(index)"
            />
          </td>
        </tr>
      </tbody>
      <tfoot class="fds-table-equipment__foot mobile">
        <tr>
          <td class="fds-table-equipment__col sum" colspan="2">
            <div>
              <strong>{{ componentText.sum }}</strong>
              <span>{{ sumOfEquipments }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td class="fds-table-equipment__col" colspan="2">
            <Button
              id="add-equipment"
              :class="buttonAddClasses"
              label="Add Equipment"
              icon="add"
              :disabled="equipments.length >= 5"
              @onClick="addNewLine()"
              />
          </td>
        </tr>
      </tfoot>
      <tfoot class="fds-table-equipment__foot desktop">
        <tr>
          <td class="fds-table-equipment__col">
            <Button
              id="add-equipment"
              :class="buttonAddClasses"
              label="Add Equipment"
              icon="add"
              :disabled="equipments.length >= 5"
              @onClick="addNewLine()"
            />
          </td>
          <td class="fds-table-equipment__col sum">
            <div>
              <strong>{{ componentText.sum }}</strong>
              <span>{{ sumOfEquipments }}</span>
            </div>
          </td>
          <td class="fds-table-equipment__col"></td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
<script>
import { ConvertToNumber } from '@/utilities/functions/pdf';
import Input from '@/components/form-elements/Input.vue';
import InputCurrency from '@/components/form-elements/InputCurrency.vue';
import Button from '@/components/form-elements/Button.vue';

export default {
  name: 'EquipmentTable',
  components: { Input, InputCurrency, Button },
  props: {
    listOfEquipments: { type: Array, default: () => {} },
    required: { type: Boolean, default: false },
    submitted: { type: Boolean, required: true },
  },
  data() {
    return {
      isLoading: false,
      equipments: [
        {
          description: '',
          value: '',
          hasError: true,
        },
      ],
      errorMessage: '',
      hasError: false,
      hasBeenValidated: false,
    };
  },
  computed: {
    componentText() {
      const tableEquipment = 'tabs.general.form.tableEquipment';
      return {
        description: this.$t(`${tableEquipment}.description`),
        value: this.$t(`${tableEquipment}.value`),
        action: this.$t(`${tableEquipment}.action`),
        sum: this.$t(`${tableEquipment}.sum`),
      };
    },
    buttonAddClasses() {
      return {
        'fds-table-equipment__add': true,
        loading: this.isLoading,
      };
    },
    sumOfEquipments() {
      const values = this.equipments.map((e) => {
        const valueInteger = ConvertToNumber(e.value);
        return Number(valueInteger);
      });
      const total = values.reduce((a, b) => a + b, 0);
      return this.formatCurrency(total);
    },
  },
  methods: {
    setEquipments() {
      this.equipments = this.listOfEquipments;
    },

    addNewLine() {
      this.isLoading = true;
      this.equipments.push({
        description: '',
        value: '',
        hasError: true,
      });
      this.isLoading = false;
      this.updateErrors();
    },

    deleteLine(index) {
      this.equipments.splice(index, 1);
      this.checkIfIsValid();
    },

    updateErrors() {
      this.equipments = this.equipments.map((e) => (
        {
          description: e.description,
          value: e.value,
          hasError: !e.description || !e.value,
        }
      ));

      this.checkIfIsValid(true);
    },

    async checkIfIsValid(isUpdatingFields = false) {
      if (this.required) {
        if (!isUpdatingFields) {
          this.hasBeenValidated = true;
          await this.updateErrors();
        }

        const hasError = this.equipments.filter((e) => e.hasError).length;

        if (hasError > 0) {
          this.hasError = true;
          this.errorMessage = 'This field is required';
          this.$emit('hasError', { name: 'equipments-table', error: true });
          return;
        }
      }

      this.hasError = false;
      this.$emit('hasError', { name: 'equipments-table', error: false });
      this.$emit('equipments', this.equipments);
    },
  },
  watch: {
    equipments() {
      this.$emit('equipments', this.equipments);
    },
    listOfEquipments(value) {
      if (value.length > 0) this.setEquipments();
    },
    submitted(value) {
      if (value) this.checkIfIsValid();
    },
  },
  created() {
    if (this.listOfEquipments.length) this.setEquipments();
  },
};
</script>
<style scoped lang="scss">
.fds-table-equipment {
  width: 100%;
  &__table {
    width: 100%;
    border-collapse: collapse;
  }

  &__title {
    height: fds-rem(48px);
    background: $fds-color--primary;
    color: $fds-color--white;
    font-weight: var(--fmc-type__weight-bold);
    font-stretch: condensed;
    font-size: fds-rem(16px);
    border: 1px solid $fds-color--gray2;

    &:nth-child(3n) {
      display: none;

      @include media('>=600px') {
        display: table-cell;
      }
    }
  }

  &__row {
    &:nth-child(even) > td {
      background-color: $fds-color--gray1;
    }
    &:nth-child(odd) > td {
      background-color: $fds-color--white;
    }
  }

  &__col {
    position: relative;
    color: $fds-color--black;
    font-weight: var(--fmc-type__weight-regular);
    font-size: fds-rem(16px);
    border: 0;

    &:nth-child(2n) {
      width: 30%;

      @include media('>=1024px') {
          width: 40%;
      }
    }

    &:nth-child(3n) {
      display: none;
      width: 15%;

      @include media('>=600px') {
        display: table-cell;
      }
    }

    &.value {
      text-align: right;
      .fds-table-equipment__field {
        :deep input {
          color: $fds-color--primary !important;
          text-align: right;
        }
      }
    }

    &.action {
      text-align: center;
    }

    &.sum {
      padding: fds-rem(12px);
      div {
        display: flex;
        justify-content: space-between;
        span {
          white-space: nowrap;
        }
      }
    }
  }

  &__foot {
    &.mobile {
        @include media('>=1024px') {
          display: none;
        }

        & > td {
          display: block;
          width: 100%;
        }
    }
    &.desktop {
      @include media('<1024px') {
        display: none;
      }
    }

    td {
        padding-top: fds-rem(30px);
    }
  }

  &__field {
    position: static;

    :deep input {
      width: 100%;
      padding: fds-rem(12px);
      background: transparent !important;
      color: $fds-color--black;
      font-weight: var(--fmc-type__weight-regular);
      font-size: fds-rem(16px);
      border: 0 !important;
      outline: none;
      box-shadow: none !important;
    }

    :deep .error-message {
      padding: fds-rem(0 15px);
      bottom: -.5rem !important;
    }

    &.key-focused {
      outline-offset: fds-rem(0);
    }
  }

  &__delete {
    height: auto;
  }

  &__error-message {
    padding: fds-rem(0 12px);
    color: $fds-color--error1;
    font-size: fds-rem(12px);
    line-height: fds-rem(18px);
  }

  &__add {
    &.loading {
      pointer-events: none;
    }
  }
}
</style>
