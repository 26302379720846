import { createRouter, createWebHashHistory } from 'vue-router';
import VueJwtDecode from 'vue-jwt-decode';
import HomeView from '@/views/HomeView.vue';
import LoginView from '@/views/LoginView.vue';
import ErrorView from '@/views/ErrorView.vue';
import LogoutView from '@/views/LogoutView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/capital-cost',
    name: 'capital-cost',
    component: HomeView,
  },
  {
    path: '/quick-quote',
    name: 'quick-quote',
    component: HomeView,
  },
  {
    path: '/payment-comparison',
    name: 'payment-comparison',
    component: HomeView,
  },
  {
    path: '/effective-rate',
    name: 'effective-rate',
    component: HomeView,
  },
  {
    path: '/Login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/Error',
    name: 'error',
    component: ErrorView,
  },
  {
    path: '/Logout',
    name: 'logout',
    component: LogoutView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to) => {
  const localData = localStorage.getItem('tokenLoginInformation');
  const routeName = 'login';

  if (to.name === 'error' || to.name === 'logout') return true;

  if (localData !== null && to.name !== 'login') {
    const tokenData = localStorage.getItem('tokenLoginInformation');
    let token = {};

    try {
      token = VueJwtDecode.decode(tokenData);
    } catch {
      localStorage.removeItem('tokenLoginInformation');
      return { name: routeName };
    }

    if (Date.now() / 1000 >= token.exp * 1000) {
      localStorage.removeItem('tokenLoginInformation');
      return { name: routeName };
    }

    return true;
  }

  if (localData === null && to.name !== 'login') return { name: routeName };

  return true;
});

export default router;
