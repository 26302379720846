const DEFAULT_LOCALES = {
  en: 'en',
  fr: 'fr',
};

export default {
  namespaced: true,
  state: {
    locales: DEFAULT_LOCALES,
    activeLocale: DEFAULT_LOCALES.en,
  },
  getters: {
    locales: (state) => state.locales,
    activeLocale: (state) => state.activeLocale,
    activeLocaleIsEn: (state) => state.activeLocale === DEFAULT_LOCALES.en,
    activeLocaleIsFr: (state) => state.activeLocale === DEFAULT_LOCALES.fr,
  },
  mutations: {
    setActiveLocale: (state, locale) => {
      // Checks if locale is valid
      if (!Object.values(state.locales).includes(locale)) return;

      state.activeLocale = locale;
    },
  },
  actions: {
    updateLocale({ commit }, locale) {
      commit('setActiveLocale', locale);
    },
  },
};
