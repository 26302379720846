import { createApp } from 'vue';
import VCalendar from 'v-calendar';
import Maska from 'maska';

import AnalyticHelper from '@/helpers/analyticHelper';
import i18n from './plugins/i18n';
import App from './App.vue';
import router from './router';
import store from './store';

import currencyFormatMixin from './mixins/currencyFormat';

import '@/scss/_fonts.scss';
import '@/scss/fds.scss';

const analytics = new AnalyticHelper();

const appInstance = createApp(App);
appInstance.config.globalProperties.$insights = analytics;

appInstance
  .use(i18n)
  .use(store)
  .use(router)
  .use(VCalendar)
  .use(Maska)
  .mixin(currencyFormatMixin)
  .mount('#app');
