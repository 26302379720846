<template>
  <div :id="id" class="fds-dropdown" :class="dropdownClasses">
    <label
      class="button-wrapper"
      @click.stop="openDropdown"
      :for="`dropdown--${id}`"
      aria-hidden>
      <span class="label" :class="labelClasses">
        {{ label }}
        <i
          v-if="hasBeenValidated && label"
          class="fds-icon fds-icon--offset-right fds-icon--16"
          :class="iconClasses">
        </i>
      </span>
      <i class="fds-icon fds-icon-wrapper fds-font--ford-icons__chevron-down fds-icon--16"></i>
      <input
        class="field"
        type="button"
        :id="`dropdown--${id}`"
        :name="`dropdown--${id}`"
        :class="inputClasses"
        :value="currentLabel"
        @keyup="onKeyUp"
        @blur="onBlur"
        readonly
      />
      <span
       class="error-message"
       :id="id.includes('searchdropdown')
        ? `${id}-error`
        : `dropdown--${id}-error`">
       {{ errorMessage }}
      </span>
    </label>
    <div :class="`list-wrapper ${showDropdown ? 'active' : ''}`">
      <div
        v-if="!showNotFound"
        class="list" role="listbox">
        <div
          :id="`${id}-default`"
          class="list-option default"
          aria-disabled="true"
          @click.stop="selectOption({ label: 'Please Select', value: '' })"
          aria-hidden
        >
          Please Select
        </div>
        <ul role="listbox">
          <li
            v-for="option in options" :key="option.value"
            :id="`${id}-${option.value}`"
            class="list-option"
            role="option"
            aria-selected="false"
            aria-hidden
            @click.stop="selectOption(option)"
          >
            {{ option.label }}
          </li>
        </ul>
      </div>
      <span v-else class="list nothing-found">
        {{ $t('tabs.general.form.dropdownNoOptions') }}
      </span>
    </div>
  </div>
</template>

<script>
import keyCodes from '../../utilities/constants/keysCodes.const';

export default {
  name: 'FdsDropdown',
  props: {
    id: { type: String, required: true },
    name: { type: String, required: true },
    label: { type: String, default: '' },
    options: { type: Array, required: true },
    selectedOption: { type: Object },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    submitted: { type: Boolean, required: true, default: false },
    darkTheme: { type: Boolean, default: false },
  },
  data() {
    return {
      currentLabel: '',
      currentValue: '',
      showDropdown: false,
      showNotFound: false,
      isFilled: false,
      keyFocused: false,
      hasBeenValidated: false,
      hasBeenOpened: false,
      hasError: false,
      errorMessage: '',
    };
  },
  computed: {
    dropdownClasses() {
      return {
        'dark-theme': this.darkTheme,
        'light-theme': !this.darkTheme,
        'key-focused': this.keyFocused,
        disabled: this.disabled,
        readonly: this.readonly,
        success: this.hasBeenValidated && this.isFilled && !this.hasError,
        error: this.hasBeenValidated && this.hasError,
      };
    },
    labelClasses() {
      return {
        active: this.showDropdown,
        filled: this.isFilled || this.currentLabel,
      };
    },
    iconClasses() {
      return {
        'fds-font--ford-icons__success': this.hasBeenValidated && this.isFilled && !this.hasError,
        'fds-font--ford-icons__error': this.hasBeenValidated && this.hasError,
      };
    },
    inputClasses() {
      return {
        active: this.showDropdown,
        filled: this.isFilled || this.currentLabel,
        disabled: this.disabled,
        readonly: this.readonly,
      };
    },
  },
  methods: {
    checkIfIsValid() {
      if (!this.disabled && this.required) {
        this.hasBeenValidated = true;
        if (!this.currentValue) {
          this.errorMessage = this.$t('tabs.general.form.requiredField');
          this.hasError = true;
          this.$emit('hasError', { name: this.name, error: true });
        } else {
          this.hasError = false;
          this.$emit('hasError', { name: this.name, error: false });
        }
      } else {
        this.$emit('hasError', { name: this.name, error: false });
      }
    },
    openDropdown() {
      if (!this.disabled && !this.readonly) {
        if (this.hasBeenOpened) {
          this.checkIfIsValid();
        } else {
          this.hasBeenOpened = true;
        }
        this.showDropdown = true;
      }
    },
    hideDropdown() {
      this.showDropdown = false;
      this.keyFocused = false;
    },
    listClick() {
      this.$emit('click');
    },
    selectOption(option) {
      this.currentLabel = option.label;
      this.currentValue = option.value;
      this.isFilled = option.label && option.value;
      this.$emit('select', option);
      this.hideDropdown();
      this.checkIfIsValid();
    },
    onKeyUp(e) {
      if (e.keyCode === keyCodes.TAB) {
        this.keyFocused = true;
        this.openDropdown();
        this.$emit('keyup', e.keyCode);
      }
    },
    onBlur() {
      setTimeout(() => {
        if (this.showDropdown) this.hideDropdown();
      }, 200);
      this.$emit('blur');
    },
  },
  watch: {
    showDropdown(value) {
      this.$emit('show', value);
    },
    submitted() {
      if (!this.disabled) this.checkIfIsValid();
    },
    selectedOption(data) {
      this.currentLabel = data.label;
      this.currentValue = data.value;
      this.isFilled = data.label && data.value;
      if (this.required) {
        this.$emit('hasError', { name: this.name, error: data.value === '' });
      }
    },
    options(list) {
      this.showNotFound = !list.length;
    },
    required(value) {
      if (value) {
        this.checkIfIsValid();
      } else {
        this.hasError = false;
        this.isFilled = false;
        this.hasBeenValidated = false;
        this.currentLabel = '';
        this.currentValue = '';
        this.$emit('select', { label: '', value: '' });
        this.$emit('hasError', { name: this.name, error: false });
      }
    },
  },
  created() {
    if (this.required && this.currentValue === '') {
      this.$emit('hasError', { name: this.name, error: false });
    }
    if (this.selectedOption) {
      this.currentLabel = this.selectedOption.label;
      this.currentValue = this.selectedOption.value;
    }
  },
};
</script>

<style lang="scss" scoped>
$fds-dropdown__option-height: fds-rem(50px);
$fds-dropdown__option-max: 5;

.fds-dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: fds-rem(520px);
  overflow: visible;

  .button-wrapper {
    position: relative;
  }

  .label {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: fds-rem(36px);
    margin: fds-rem(0 0 5px 15px);
    white-space: nowrap;
    font-size: fds-rem(16px);
    color: $fds-color--gray3;
    line-height: fds-rem(16px);
    letter-spacing: fds-rem(1px);
    pointer-events: none;
    z-index: 1;
    transform: translate(0, fds-rem(40px));
    will-change: font-size, transform;
    transition:
      font-size 0.3s ease-in-out,
      transform 0.3s ease-in-out;

    &.active,
    &.filled {
      transform: translate(fds-rem(-15px),0);
      font-size: fds-rem(12px);
    }

    &.active {
      & ~ .fds-icon-wrapper {
        transform: rotate(180deg);
      }
    }

    .fds-icon {
      font-family: $fds-font--ford-icons;
      margin-bottom: fds-rem(3px);

      &.error::before {
        content: $fds-font--ford-icons__error;
      }
      &.success::before {
        content: $fds-font--ford-icons__success;
      }
    }
  }

  .fds-icon-wrapper {
    position: absolute;
    top: fds-rem(58px);
    right: fds-rem(20px);
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
  }

  .field {
    @extend %fmc-type--body1;
    padding: 0 fds-rem(15px);
    width: 100%;
    height: fds-rem(50px);
    text-align: left;
    border-width: fds-rem(1px);
    border-style: solid;
    border-radius: fds-rem(3px);
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
    -webkit-appearance: none; /* stylelint-disable property-no-vendor-prefix */

    &.active:not(.disabled):not(.readonly) {
      border-radius: fds-rem(3px 3px 0 0);
      box-shadow: 0 0 0 transparent inset, $fds-elevation__box-shadow--layer2;
    }
    &:not(:focus) {
      background: $fds-color--gray1;
    }
    &.disabled,
    &.readonly {
      background-color: $fds-color--disabled3 !important;
      cursor: not-allowed;
    }
  }

  .error-message {
    position: absolute;
    left: 0;
    bottom: fds-rem(-18px);
    width: 100%;
    color: $fds-color--error1;
    font-size: fds-rem(12px);
    line-height: fds-rem(18px);
    letter-spacing: fds-rem(1px);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .list-wrapper {
    display: none;
    width: 100%;
    top: fds-rem(91px);
    left: 0;
    opacity: 0;
    position: absolute;
    z-index: 3;

    &.active {
      display: block;
      opacity: 1;
    }
  }

  .list {
    width: 100%;
    max-height: calc(
      #{$fds-dropdown__option-max} * #{$fds-dropdown__option-height}
    );
    margin-bottom: fds-rem(40px);
    overflow-y: auto;
    border-radius: fds-rem(0 0 3px 3px);
    background-color: var(--fds-color--white);
    color: var(--fds-color--primary);
    border: solid 1px var(--fds-color--gray2);
    border-top: none;
    list-style: none;
    padding: 0;
    outline: none;
    box-shadow: inset 0 0 0 transparent, $fds-elevation__box-shadow--layer2;
    transition: opacity 0.3s ease-in-out;

    &.nothing-found {
      display: block;
      padding: fds-rem(20px);
      text-align: center;
    }
  }

  .list-option {
    @include fds-get-component-typeset("dropdown-option");

    display: block;
    padding: fds-rem(16px);
    color: var(--fds-color--primary);
    line-height: fds-rem(30px);
    cursor: pointer;

    &:not(.default):hover {
      background-color: var(--fds-color--gray2);
      color: var(--fds-color--white);
      outline: none;
    }
  }

  &.light-theme {
    .label {
      color: $fds-color--gray3;
    }
    .field {
      background-color: $fds-color--gray1;
      color: $fds-color--gray3;
      border-color: $fds-color--gray2;
      :-webkit-autofill {
        -webkit-text-fill-color: $fds-color--gray3;
      }

      &.filled,
      &.active {
        background-color: $fds-color--white;
        color: $fds-color--primary;
        :-webkit-autofill {
          -webkit-text-fill-color: $fds-color--primary;
        }
      }
    }

    &.disabled,
    &.readonly {
      .label,
      .fds-icon {
        color: $fds-color--white;
        font-weight: 300;
        &.filled {
          color: $fds-color--gray3;
        }
      }
      .field {
        color: $fds-color--white;
        font-weight: 300;
        :-webkit-autofill {
          -webkit-text-fill-color: $fds-color--white;
        }
      }
    }
  }

  &.dark-theme {
    .label {
      color: $fds-color--gray3;
    }
    .field {
      background-color: $fds-color--white;
      color: $fds-color--gray3;
      border-color: $fds-color--gray2;
      :-webkit-autofill {
        -webkit-text-fill-color: $fds-color--gray3;
      }

      &.filled,
      &.active {
        background-color: $fds-color--white;
        color: $fds-color--primary;
        :-webkit-autofill {
          -webkit-text-fill-color: $fds-color--primary;
        }
      }
    }

    &.disabled,
    &.readonly {
      .label {
        font-weight: 300;
      }

      .field {
        &.filled,
        &.active {
          background-color: $fds-color--gray3;
          color: $fds-color--white;
          font-weight: 300;
          border-color: $fds-color--gray3;
          :-webkit-autofill {
            -webkit-text-fill-color: $fds-color--white;
          }
        }
      }

      .label,
      .fds-icon {
        color: $fds-color--white;
      }
    }
  }

  &.success {
    .field,
    .list {
      border-color: var(--fds-color--success1);
    }
  }

  &.error {
    .field,
    .list {
      border-color: var(--fds-color--error1);
    }
    .error-message {
      opacity: 1;
    }
  }

  &.key-focused {
    .list-wrapper::before {
      content: '';
      position: absolute;
      left: fds-rem(-10px);
      top: fds-rem(-81px);
      right: fds-rem(-10px);
      bottom: fds-rem(-10px);
      border: 1px solid $fds-color--primary;
      z-index: -1;
    }
  }
}
</style>
