export default {
  // Info Dealer/Customer
  sectionNames: {
    customerNameLabel: '@:tabs.general.sectionNames.customerNameLabel',
    dealerNameLabel: '@:tabs.general.sectionNames.dealerNameLabel',
  },

  // Info Vehicle
  sectionVehicle: {
    title: '@:tabs.general.sectionVehicle.title',
    yearLabel: '@:tabs.general.sectionVehicle.yearLabel',
    makeLabel: '@:tabs.general.sectionVehicle.makeLabel',
    modelLabel: '@:tabs.general.sectionVehicle.modelLabel',
    vinLabel: '@:tabs.general.sectionVehicle.vinLabel',
  },

  // Services and Taxes
  sectionTaxes: {
    title: '@:tabs.quickQuote.sectionTaxes.title',
    sellingPriceLabel: 'Selling Price/Cap Cost',
    sellingPriceInfo: 'Include Body',
    leaseTermLabel: 'Lease Term',
    retailTermLabel: 'Retail Term',
    alternateRetailTermLabel: 'Alternate Retail Term',
    cashDownPaymentLabel: 'Cash Down Payment',
    serviceContractLabel: 'Service Contract/Warranty',
    fleetConcessionsLabel: 'Fleet Concessions/Rebate',
    tradeEquityLabel: 'Trade Equity',
    residualLabel: '@:tabs.quickQuote.sectionTaxes.residualLabel',
    customerLeaseRateLabel: 'Customer Lease Rate (%)',
    customerRetailRateLabel: 'Customer Retail Rate (%)',
    monthlyMaintenanceChargeLabel: 'Monthly Maintenance Charge',
    alternateRetailRateLabel: 'Alternate Retail Rate (%)',
  },

  // Competitor Details
  sectionCompetitor: {
    title: 'Competitor Details',
    sellingPriceLabel: '@:tabs.effectiveRate.sectionTaxes.sellingPriceLabel',
    sellingPriceInfo: '@:tabs.effectiveRate.sectionTaxes.sellingPriceInfo',
    fleetConcessionsLabel: '@:tabs.effectiveRate.sectionTaxes.fleetConcessionsLabel',
    cashDownPaymentLabel: '@:tabs.effectiveRate.sectionTaxes.cashDownPaymentLabel',
    serviceContractLabel: '@:tabs.effectiveRate.sectionTaxes.serviceContractLabel',
    residualAmountLabel: 'Residual Amount ($)',
    competitorPaymentLabel: 'Competitor Payment',
    paymentTimingLabel: '@:tabs.quickQuote.sectionTaxes.paymentTimingLabel',
    paymentTimingOption1: '@:tabs.quickQuote.sectionTaxes.paymentTimingOption1',
    paymentTimingOption2: '@:tabs.quickQuote.sectionTaxes.paymentTimingOption2',
    upfrontFeesLabel: 'Upfront Fees',
    monthlyManagementFeesLabel: 'Monthly Management Fees',
    monthlyMaintenanceChargeLabel: 'Monthly Maintenance Charge',
    leaseEndServiceChargeLabel: 'Lease End Service Charge',
  },

  // Descriptions
  sectionDescription: {
    item1: 'Enter sale price of vehicle (including body)',
    item2: 'Enter total rebates or fleet concessions',
    item3: 'Enter additional down payment (may be requirement of the Fleet Management Company)',
    item4: 'Enter the desired lease contract term and desired retail contract term',
    item5: 'Enter residual %',
    item6: 'Enter upfront fees (Non capitalizes fees)',
    item7: 'Enter monthly maintenance charges',
    item8: 'Enter competitor residual $',
    item9: 'Enter competitor monthly payment (include taxes if capitalized)',
    item10: 'Enter competitor monthly management fees',
    item11: 'Enter competitor lease end service charges',
    item12: 'Fields that require a percentage must have a numerical value entered',
  },

  // PDF
  sectionPDF: {
    title: '@:mainTitle',
    subtitle: 'Effective Rate Calculator',

    // Info Vehicle
    sectionVehicle: {
      title: '@:tabs.general.sectionVehicle.title',
      yearLabel: '@:tabs.general.sectionVehicle.yearLabel',
      makeLabel: '@:tabs.general.sectionVehicle.makeLabel',
      modelLabel: '@:tabs.general.sectionVehicle.modelLabel',
      vinLabel: '@:tabs.general.sectionVehicle.vinLabel',
    },

    // Info Table
    sectionTable: {
      topInfo1: 'Lease Co.',
      topInfo2: 'Ford Pro FinSimple',
      topTitle1: 'Type of Finance',
      topTitle2: 'TRAC',
      topTitle3: 'CommerciaLease Advance',
      topTitle4: 'CommerciaLease Arrears',
      topTitle5: 'Retail',
      topTitle6: 'Alternate Retail',
      leftSubtitle1: 'Sale Price (including body)',
      leftSubtitle2: 'Fleet Concessions & Rebates',
      leftSubtitle3: 'Down Payment',
      leftSubtitle4: 'Service Contract / Warranty Cost',
      leftSubtitle5: 'Capitalized Cost (Amt. Financed)',
      leftSubtitle6: 'Term',
      leftSubtitle7: 'Residual (%)',
      leftSubtitle8: 'Residual ($)',
      leftSubtitle9: 'Base Payment w/o Tax/Fees',
      leftSubtitle10: 'Rate w/o fees',
      leftSubtitle11: 'Upfront Fees',
      leftSubtitle12: 'Monthly Management Fees',
      leftSubtitle13: 'Lease End Service Charge',
      leftSubtitle14: 'Effective Rate w/ fees',
      leftSubtitle15: 'Monthly Maintenance Charge',
      leftSubtitle16: 'Payment w/ maint. charges',
      leftSubtitle17: 'Total of Payments',
      leftSubtitle18: 'Total Fees',
      leftSubtitle19: 'Residual',
      leftSubtitle20: 'Total Expense',
      leftSubtitle21: 'Total Savings',
    },

    // Signature and Disclaimer
    sectionSignature: {
      customerName: '@:tabs.general.sectionSignature.customerName',
      dealerName: '@:tabs.general.sectionSignature.dealerName',
      signature: '@:tabs.general.sectionSignature.signature',
      disclaimer: '@:tabs.general.sectionSignature.disclaimer',
      disclaimerTextUSA: '@:tabs.general.sectionSignature.disclaimerTextUSA',
      disclaimerTextCAN1: 'This is not an offer or approval by Ford Credit Canada Company. This is an estimated payment that may be available to a Lessee based on certain lease assumptions. Actual approval by Ford Credit Canada Company will be based on independent analysis of the actual credit-worthiness of the Lessee and the terms of the proposed transaction and is subject to the final review and concurrence of Ford Credit Canada Company.',
      disclaimerTextCAN2: 'This sales tool is provided compliments of Ford Credit Canada Company. Although we have attempted to make this a useful sales tool, we do not guarantee sales results or the accuracy of the calculations, nor do we take any responsibility for the manner in which this tool is used. The decision to use this tool is within the sole discretion of your dealership, and dealership management should complete a thorough and independent review.',
    },
  },
};
