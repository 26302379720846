<template>
  <section id="quick-quote-content" class="quick-quote">
    <form
      v-if="!showPDF"
      ref="quickquoteform"
      @submit.prevent="generatePDF()"
    >
      <div class="first-info fds-m--b-3">
        <Input
          id="quick-quote-costumer-name"
          class="field"
          :name="'quick-quote-costumer-name'"
          :submitted="hasSubmitted"
          v-model:value="customerName"
          @hasError="validateField($event)"
          required
        >{{ componentText.customerName }}</Input>
        <Input
          id="quick-quote-dealer-name"
          class="field"
          :name="'quick-quote-dealer-name'"
          :submitted="hasSubmitted"
          v-model:value="dealerName"
          v-maska="{ mask: 'EEEEEEEEEEEEEEEEEEEEEEEEE',
          tokens: { 'E': { pattern: /[0-9a-zA-Z-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]/}} }"
          @hasError="validateField($event)"
          required
        >{{ componentText.dealerName }}</Input>
      </div>

      <h3 class="quick-quote__subtitle fmc-type--subtitle1">
        {{ componentText.vehicle.title }}
      </h3>
      <div class="second-info fds-m--b-3">
        <Input
          id="quick-quote-vi-year"
          class="field"
          :name="'quick-quote-vi-year'"
          :submitted="hasSubmitted"
          minlength="4"
          v-model:value="vehicle.year"
          v-maska="'####'"
          @hasError="validateField($event)"
          required
        >{{ componentText.vehicle.yearLabel }}</Input>
        <Input
          id="quick-quote-vi-make"
          class="field"
          :name="'quick-quote-vi-make'"
          :submitted="hasSubmitted"
          v-model:value="vehicle.make"
          @hasError="validateField($event)"
          required
        >{{ componentText.vehicle.makeLabel }}</Input>
        <Input
          id="quick-quote-vi-model"
          class="field"
          :name="'quick-quote-vi-model'"
          :submitted="hasSubmitted"
          v-model:value="vehicle.model"
          @hasError="validateField($event)"
          required
        >{{ componentText.vehicle.modelLabel }}</Input>
        <InputCurrency
          id="quick-quote-vi-net-cap-cost"
          class="field"
          :name="'quick-quote-vi-net-cap-cost'"
          :submitted="hasSubmitted"
          v-model:value="vehicle.netCapCost"
          @hasError="validateField($event)"
          required
        >{{ componentText.vehicle.netCapCostLabel }}</InputCurrency>
      </div>

      <h3 class="quick-quote__subtitle fmc-type--subtitle1">
        {{ componentText.taxes.title }}
      </h3>
      <div class="third-info fds-m--b-3">
        <Input
          id="quick-quote-st-term"
          class="field"
          :name="'quick-quote-st-term'"
          :submitted="hasSubmitted"
          v-model:value="taxes.term"
          v-maska="'##'"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.termLabel }}</Input>
        <InputPercent
          id="quick-quote-st-residual"
          class="field"
          :name="'quick-quote-st-residual'"
          :submitted="hasSubmitted"
          v-model:value="taxes.residual"
          @hasError="validateField($event)"
          required
          wantToRound
        >{{ componentText.taxes.residualLabel }}</InputPercent>
        <InputPercent
          id="quick-quote-st-customer-rate"
          class="field"
          :name="'quick-quote-st-customer-rate'"
          :submitted="hasSubmitted"
          v-model:value="taxes.customerRate"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.customerRateLabel }}</InputPercent>
        <InputPercent
          id="quick-quote-st-dealer-participation"
          class="field"
          :name="'quick-quote-st-dealer-participation'"
          :submitted="hasSubmitted"
          v-model:value="taxes.dealerParticipation"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.dealerParticipationLabel }}</InputPercent>
        <Dropdown
          id="quick-quote-st-payment-timing"
          class="field"
          :name="'quick-quote-st-payment-timing'"
          :label="componentText.taxes.paymentTimingLabel"
          :submitted="hasSubmitted"
          :options="[
            {
              label: componentText.taxes.paymentTimingOption1,
              value: 'advance',
            },
            {
              label: componentText.taxes.paymentTimingOption2,
              value: 'arrears',
            },
          ]"
          :selectedOption="taxes.paymentTiming"
          v-model:value="taxes.paymentTiming"
          @select="taxes.paymentTiming = $event"
          @hasError="validateField($event)"
          required
        ></Dropdown>
        <InputPercent
          id="quick-quote-st-tax-rate"
          class="field"
          :name="'quick-quote-st-tax-rate'"
          :submitted="hasSubmitted"
          v-model:value="taxes.taxRate"
          @hasError="validateField($event)"
          required
        >{{ componentText.taxes.taxRateLabel }}</InputPercent>
      </div>
      <Button
        id="quick-quote-generate-pdf"
        class="fds-m--b-3"
        :type="'submit'"
        :label="componentText.buttonGeneratePDF"
        icon="next"
        @click="generatePDF()"
      />
    </form>
    <div class="pdf" v-if="showPDF">
      <GeneratePDF />
      <Button
        id="quick-quote-return-to-form"
        class="fds-m--t-3"
        :type="'submit'"
        :label="componentText.buttonCloseAndReturn"
        icon="next"
        @onClick="showPDF = false"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Input from '@/components/form-elements/Input.vue';
import InputPercent from '@/components/form-elements/InputPercent.vue';
import InputCurrency from '@/components/form-elements/InputCurrency.vue';
import Dropdown from '@/components/form-elements/Dropdown.vue';
import Button from '@/components/form-elements/Button.vue';
import GeneratePDF from './GeneratePDF.vue';

export default {
  name: 'QuickQuoteForm',
  components: {
    Input,
    InputPercent,
    InputCurrency,
    Dropdown,
    Button,
    GeneratePDF,
  },
  data() {
    return {
      showPDF: false,
      hasSubmitted: false,
      errors: [],
      changeStore: false,

      // Dealer & Customer
      customerName: '',
      dealerName: '',

      // Vehicle Information
      vehicle: {
        year: '',
        make: '',
        model: '',
        netCapCost: '',
      },

      // Services and Taxes
      taxes: {
        term: '',
        residual: '',
        customerRate: '',
        dealerParticipation: '',
        paymentTiming: {},
        taxRate: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      customerNameStore: 'quickQuote/customerName',
      dealerNameStore: 'quickQuote/dealerName',
      vehicleStore: 'quickQuote/vehicle',
      taxesStore: 'quickQuote/taxes',
      getModalResponse: 'notifications/getModalResponse',
    }),

    componentText() {
      const sectionNames = 'tabs.quickQuote.sectionNames';
      const sectionVehicle = 'tabs.quickQuote.sectionVehicle';
      const sectionTaxes = 'tabs.quickQuote.sectionTaxes';
      const sectionActions = 'tabs.general.sectionActions';

      return {
        customerName: this.$t(`${sectionNames}.customerNameLabel`),
        dealerName: this.$t(`${sectionNames}.dealerNameLabel`),

        vehicle: {
          title: this.$t(`${sectionVehicle}.title`),
          yearLabel: this.$t(`${sectionVehicle}.yearLabel`),
          makeLabel: this.$t(`${sectionVehicle}.makeLabel`),
          modelLabel: this.$t(`${sectionVehicle}.modelLabel`),
          netCapCostLabel: this.$t(`${sectionVehicle}.netCapCostLabel`),
        },

        taxes: {
          title: this.$t(`${sectionTaxes}.title`),
          termLabel: this.$t(`${sectionTaxes}.termLabel`),
          residualLabel: this.$t(`${sectionTaxes}.residualLabel`),
          customerRateLabel: this.$t(`${sectionTaxes}.customerRateLabel`),
          dealerParticipationLabel: this.$t(`${sectionTaxes}.dealerParticipationLabel`),
          paymentTimingLabel: this.$t(`${sectionTaxes}.paymentTimingLabel`),
          paymentTimingOption1: this.$t(`${sectionTaxes}.paymentTimingOption1`),
          paymentTimingOption2: this.$t(`${sectionTaxes}.paymentTimingOption2`),
          taxRateLabel: this.$t(`${sectionTaxes}.taxRateLabel`),
        },

        buttonGeneratePDF: this.$t(`${sectionActions}.buttonGeneratePDF`),
        buttonClear: this.$t(`${sectionActions}.buttonClear`),
        buttonClearAll: this.$t(`${sectionActions}.buttonClearAll`),
        buttonCloseAndReturn: this.$t(`${sectionActions}.buttonCloseAndReturn`),
      };
    },
  },
  watch: {
    customerNameStore() {
      this.changeStore = true;
    },
    'vehicleStore.year': function () {
      this.changeStore = true;
    },
    'vehicleStore.make': function () {
      this.changeStore = true;
    },
    'vehicleStore.model': function () {
      this.changeStore = true;
    },
    'vehicleStore.vin': function () {
      this.changeStore = true;
    },
    'taxesStore.residual': function () {
      this.changeStore = true;
    },
    changeStore(value) {
      if (value === true) {
        this.populateSharedFields();
      }
    },
  },
  mounted() {
    this.populateForm();
  },
  methods: {
    ...mapActions({
      updateQuickQuote: 'quickQuote/updateQuickQuote',
      clearQuickQuote: 'quickQuote/clearQuickQuote',
      showNotification: 'notifications/showNotification',
      showDefaultErrorForm: 'notifications/showDefaultErrorForm',
      showLoading: 'notifications/showLoading',
      hideLoading: 'notifications/hideLoading',
      showModalDialog: 'notifications/showModalDialog',
    }),

    /**
     * Controls fields with error
     * @param {Object} field - Field data
     * @param {String} field.name - Name of Field
     * @param {Boolean} field.error - Field has error?
     */
    validateField(field) {
      if (field.error) {
        this.errors.push(field.name);
      } else {
        this.errors = this.errors.filter((e) => e !== field.name);
      }
    },

    /**
     * Get and Set values from store
     */
    populateForm() {
      this.hasSubmitted = false;

      this.customerName = this.customerNameStore;
      this.dealerName = this.dealerNameStore;

      this.vehicle.year = this.vehicleStore.year;
      this.vehicle.make = this.vehicleStore.make;
      this.vehicle.model = this.vehicleStore.model;
      this.vehicle.netCapCost = this.vehicleStore.netCapCost;

      this.taxes.term = this.taxesStore.term;
      this.taxes.residual = this.taxesStore.residual;
      this.taxes.customerRate = this.taxesStore.customerRate;
      this.taxes.dealerParticipation = this.taxesStore.dealerParticipation;
      this.taxes.paymentTiming = this.taxesStore.paymentTiming;
      this.taxes.taxRate = this.taxesStore.taxRate;
    },

    populateSharedFields() {
      this.customerName = this.customerNameStore;

      this.vehicle.year = this.vehicleStore.year;
      this.vehicle.make = this.vehicleStore.make;
      this.vehicle.model = this.vehicleStore.model;

      this.taxes.residual = this.taxesStore.residual;

      this.changeStore = false;
    },

    async generatePDF() {
      this.hasSubmitted = true;
      if (this.errors.length) {
        this.showDefaultErrorForm();
        return;
      }

      this.showLoading();
      await this.updateQuickQuote({
        customerName: this.customerName,
        dealerName: this.dealerName,
        vehicle: this.vehicle,
        taxes: this.taxes,
      });

      this.showPDF = true;
      this.hideLoading();
      // Report action to Application Insights
      const userData = this.$store.getters['user/userData'];
      userData.calculator = 'Quick Quote';
      this.$insights.trackEventBtnPageWithMetaInfo('Quick Quote PDF', userData);
    },
  },
};
</script>

<style lang="scss" scoped>
.quick-quote {
  &__title {
    margin-bottom: 40px;
  }

  &__subtitle {
    margin-bottom: 20px;
  }

  .field{
    margin-bottom: fds-rem(40px);
    @include media('>=600px'){
      width: calc(50% - 2rem);
    }
    @include media('>=1024px'){
      width: calc(33.33% - 2rem);
    }
  }

.first-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @include media('>=1024px'){
    justify-content: flex-start;
    gap: 3rem;
  }
}

.second-info,
  .third-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}
</style>
