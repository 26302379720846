export default {
  // Info Dealer/Customer
  sectionNames: {
    customerNameLabel: '@:tabs.general.sectionNames.customerNameLabel',
    dealerNameLabel: '@:tabs.general.sectionNames.dealerNameLabel',
  },

  // Info Vehicle
  sectionVehicle: {
    title: '@:tabs.general.sectionVehicle.title',
    yearLabel: '@:tabs.general.sectionVehicle.yearLabel',
    makeLabel: '@:tabs.general.sectionVehicle.makeLabel',
    modelLabel: '@:tabs.general.sectionVehicle.modelLabel',
    netCapCostLabel: '@:tabs.general.sectionVehicle.netCapCostLabel',
  },

  // Services and Taxes
  sectionTaxes: {
    title: 'Terms',
    termLabel: 'Term (Months)',
    residualLabel: 'Residual (%)',
    customerRateLabel: 'Customer Rate (%)',
    customerRateInfo: 'Includes Dealer Partipation',
    dealerParticipationLabel: 'Dealer Participation (%)',
    paymentTimingLabel: 'Payment Timing',
    paymentTimingOption1: 'Advance',
    paymentTimingOption2: 'Arrears',
    taxRateLabel: 'Tax Rate (%)',
  },

  // PDF
  sectionPDF: {
    title: '@:mainTitle',
    subtitle: 'CommerciaLease Quick Quote',

    // Info Vehicle
    sectionVehicle: {
      title: '@:tabs.general.sectionVehicle.title',
      yearLabel: '@:tabs.general.sectionVehicle.yearLabel',
      makeLabel: '@:tabs.general.sectionVehicle.makeLabel',
      modelLabel: '@:tabs.general.sectionVehicle.modelLabel',
      netCapCostLabel: '@:tabs.general.sectionVehicle.netCapCostLabel',
    },

    // Services and Taxes
    sectionTaxes: {
      title: '@:tabs.quickQuote.sectionTaxes.title',
      termLabel: '@:tabs.quickQuote.sectionTaxes.termLabel',
      residualLabel: '@:tabs.quickQuote.sectionTaxes.residualLabel',
      residualAmountLabel: 'Residual Amount',
      paymentTimingLabel: '@:tabs.quickQuote.sectionTaxes.paymentTimingLabel',
    },

    // Estimated Monthly
    sectionEstimated: {
      title1: 'Estimated Monthly Payment (Before Tax)',
      title2: 'Estimated Monthly Payment (Including Tax)',
    },

    // Signature and Disclaimer
    sectionSignature: {
      customerName: '@:tabs.general.sectionSignature.customerName',
      dealerName: '@:tabs.general.sectionSignature.dealerName',
      signature: '@:tabs.general.sectionSignature.signature',
      disclaimer: '@:tabs.general.sectionSignature.disclaimer',
      disclaimerTextUSA: '@:tabs.general.sectionSignature.disclaimerTextUSA',
      disclaimerTextCAN: 'This is not an offer or approval by Ford Credit Canada Company. This is an estimated payment that may be available to a Lessee based on certain lease assumptions. Actual approval by Ford Credit will be based on Ford Credit’s independent analysis of the actual credit-worthiness of the Lessee and the terms of the proposed transaction and is subject to the final review and concurrence of Ford Credit Canada Company.',
    },
  },
};
