import baseAPI from '@/api/baseAPI';

export default class Login extends baseAPI {
  constructor(axiosObject) {
    super(axiosObject, '/Login');
    this.apiBaseName = '/Login';
  }

  async getAuth() {
    const { data } = await this.axiosObject.get(`${this.apiBaseName}`);
    return data;
  }
}
