 <template>
  <RenderPDF
    name="CommerciaLease Payment Comparison"
    :definition="pdfConfig"
    :data-customerName="customerName"
    :data-dealerName="dealerName"
    :data-date="datePDF"
    :data-vehicle-year="vehicle.year"
    :data-vehicle-make="vehicle.make"
    :data-vehicle-model="vehicle.model"
    :data-commercial-leaseTerm="taxes.leaseTerm"
    :data-vehicle-vin="vehicle.vin"
    :data-retail-sellingPrice="taxes.sellingPrice"
    :data-retail-cashDownPayment="taxes.cashDownPayment"
    :data-retail-tradeEquity="taxes.tradeEquity"
    :data-retail-retailRebate="taxes.retailRebate"
    :data-retail-taxRate="taxes.taxRate"
    :data-retail-amountFinanced="formatCurrency(amountFinanced)"
    :data-retail-retailTerm="taxes.retailTerm"
    :data-retail-customerRetailRate="taxes.customerRetailRate"
    :data-retail-financeCharge="formatCurrency(financeCharge)"
    :data-retail-paymentWithTax="formatCurrency(paymentWithTax)"
    :data-commercial-capCost="taxes.capCost"
    :data-commercial-cashDownPayment="taxes.cashDownPayment"
    :data-commercial-tradeEquity="taxes.tradeEquity"
    :data-commercial-commercialLeaseRebate="taxes.commercialLeaseRebate"
    :data-commercial-taxRate="taxes.taxRate"
    :data-commercial-netCapCost="formatCurrency(netCapCost)"
    :data-leaseTerm="taxes.leaseTerm"
    :data-commercial-residual="taxes.residual"
    :data-commercial-paymentsInAdvance="taxes.paymentsInAdvance"
    :data-commercial-customerLeaseRate="taxes.customerLeaseRate"
    :data-commercial-residualAmount="formatCurrency(residualAmount)"
    :data-commercial-basePayment="formatCurrency(basePayment)"
    :data-commercial-commercialPaymentWithTax="formatCurrency(commercialPaymentWithTax)"
    :data-notes="taxes.notes"
  />
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { ExcelPMT, ConvertToNumber } from '@/utilities/functions/pdf';
import RenderPDF from '@/components/calculators/RenderPDF.vue';
import definePDF from './TemplatePDF';

export default {
  name: 'FinishPaymentComparison',
  components: {
    RenderPDF,
  },
  data() {
    return {
      pdfConfig: {},
    };
  },
  computed: {
    ...mapGetters({
      activeLocale: 'locales/activeLocale',
      customerName: 'paymentComparison/customerName',
      dealerName: 'paymentComparison/dealerName',
      vehicle: 'paymentComparison/vehicle',
      taxes: 'paymentComparison/taxes',
    }),

    datePDF() {
      return moment(new Date()).format('MMM DD, YYYY');
    },

    amountFinanced() {
      const taxesSellingPrice = ConvertToNumber(this.taxes.sellingPrice);
      const taxesCashDownPayment = ConvertToNumber(this.taxes.cashDownPayment);
      const taxesTradeEquity = ConvertToNumber(this.taxes.tradeEquity);
      const taxesRebate = ConvertToNumber(this.taxes.retailRebate);
      const taxesTaxRate = ConvertToNumber(this.taxes.taxRate);
      const totalAmountFinanced = (
        taxesSellingPrice * ((taxesTaxRate / 100) + 1)
        - (taxesCashDownPayment) - (taxesTradeEquity) - (taxesRebate)
      );

      return totalAmountFinanced;
    },
    netCapCost() {
      const taxesSellingPrice = ConvertToNumber(this.taxes.sellingPrice);
      const taxesCashDownPayment = ConvertToNumber(this.taxes.cashDownPayment);
      const taxesTradeEquity = ConvertToNumber(this.taxes.tradeEquity);
      const taxesRetailRebate = ConvertToNumber(this.taxes.retailRebate);
      const taxesCommercialLR = ConvertToNumber(this.taxes.commercialLeaseRebate);
      const taxesTaxRate = ConvertToNumber(this.taxes.taxRate);
      if (this.taxes.taxesUpfront === 'Yes') {
        return (taxesSellingPrice * ((taxesTaxRate / 100) + 1)
          - (taxesCashDownPayment) - (taxesTradeEquity) - (taxesRetailRebate)
        );
      }
      return taxesSellingPrice - (taxesCashDownPayment) - (taxesTradeEquity) - (taxesCommercialLR);
    },
    residualAmount() {
      const taxesResidual = ConvertToNumber(this.taxes.residual) / 100;
      return this.netCapCost * taxesResidual;
    },
    paymentWithTax() {
      const taxesCustomerRR = ConvertToNumber(this.taxes.customerRetailRate) / 100;
      const taxesRetailTerm = ConvertToNumber(this.taxes.retailTerm);
      return ExcelPMT(taxesCustomerRR / 12, taxesRetailTerm, -this.amountFinanced);
    },
    financeCharge() {
      const taxesRetailTerm = ConvertToNumber(this.taxes.retailTerm);
      return taxesRetailTerm * this.paymentWithTax - (this.amountFinanced);
    },
    basePayment() {
      const taxesCLR = ConvertToNumber(this.taxes.customerLeaseRate) / 100;
      const taxesRT = ConvertToNumber(this.taxes.leaseTerm);
      const taxesPIA = this.taxes.paymentsInAdvance === 'Yes' ? 1 : 0;
      return ExcelPMT(taxesCLR / 12, taxesRT, -this.netCapCost, this.residualAmount, taxesPIA);
    },
    commercialPaymentWithTax() {
      const taxRate = ConvertToNumber(this.taxes.taxRate);
      return this.taxes.taxesUpfront === 'Yes' ? this.basePayment
        : (this.basePayment * (taxRate / 100)) + this.basePayment;
    },
  },
  mounted() {
    const sectionVehicle = 'tabs.paymentComparison.sectionPDF.sectionVehicle';
    const sectionTableRetail = 'tabs.paymentComparison.sectionPDF.sectionTableRetail';
    const sectionTableCommercialLease = 'tabs.paymentComparison.sectionPDF.sectionTableCommercialLease';

    this.pdfConfig = definePDF({
      labels: {
        title: this.$t('tabs.paymentComparison.sectionPDF.title'),
        subtitle: this.$t('tabs.paymentComparison.sectionPDF.subtitle'),
        vehicle: {
          title: this.$t(`${sectionVehicle}.title`),
          yearLabel: this.$t(`${sectionVehicle}.yearLabel`),
          makeLabel: this.$t(`${sectionVehicle}.makeLabel`),
          modelLabel: this.$t(`${sectionVehicle}.modelLabel`),
          commercialLeaseTermLabel: this.$t(`${sectionVehicle}.commercialLeaseTermLabel`),
          vin: this.$t(`${sectionVehicle}.vinLabel`),
        },
        tableRetail: {
          title: this.$t(`${sectionTableRetail}.title`),
          topSubtitle1: this.$t(`${sectionTableRetail}.topSubtitle1`),
          topSubtitle2: this.$t(`${sectionTableRetail}.topSubtitle2`),
          topSubtitle3: this.$t(`${sectionTableRetail}.topSubtitle3`),
          topSubtitle4: this.$t(`${sectionTableRetail}.topSubtitle4`),
          topSubtitle5: this.$t(`${sectionTableRetail}.topSubtitle5`),
          topSubtitle6: this.$t(`${sectionTableRetail}.topSubtitle6`),
          topSubtitle7: this.$t(`${sectionTableRetail}.topSubtitle7`),
          topSubtitle8: this.$t(`${sectionTableRetail}.topSubtitle8`),
          topSubtitle9: this.$t(`${sectionTableRetail}.topSubtitle9`),
          topSubtitle10: this.$t(`${sectionTableRetail}.topSubtitle10`),
        },
        tableCommercialLease: {
          title: this.$t(`${sectionTableCommercialLease}.title`),
          topSubtitle1: this.$t(`${sectionTableCommercialLease}.topSubtitle1`),
          topSubtitle2: this.$t(`${sectionTableCommercialLease}.topSubtitle2`),
          topSubtitle3: this.$t(`${sectionTableCommercialLease}.topSubtitle3`),
          topSubtitle4: this.$t(`${sectionTableCommercialLease}.topSubtitle4`),
          topSubtitle5: this.$t(`${sectionTableCommercialLease}.topSubtitle5`),
          topSubtitle6: this.taxes.taxesUpfront === 'No'
            ? this.$t(`${sectionTableCommercialLease}.topSubtitle6_1`)
            : this.$t(`${sectionTableCommercialLease}.topSubtitle6_2`),
          topSubtitle7: this.$t(`${sectionTableCommercialLease}.topSubtitle7`),
          topSubtitle8: this.$t(`${sectionTableCommercialLease}.topSubtitle8`),
          topSubtitle9: this.$t(`${sectionTableCommercialLease}.topSubtitle9`),
          topSubtitle10: this.$t(`${sectionTableCommercialLease}.topSubtitle10`),
          topSubtitle11: this.$t(`${sectionTableCommercialLease}.topSubtitle11`),
          topSubtitle12: this.$t(`${sectionTableCommercialLease}.topSubtitle12`),
          topSubtitle13: this.$t(`${sectionTableCommercialLease}.topSubtitle13`),
        },
        notes: {
          title: this.$t('tabs.paymentComparison.sectionPDF.sectionNotes.title'),
        },
        signature: {
          customerName: this.$t('tabs.paymentComparison.sectionPDF.sectionSignature.customerName'),
          dealerName: this.$t('tabs.paymentComparison.sectionPDF.sectionSignature.dealerName'),
          signature: this.$t('tabs.paymentComparison.sectionPDF.sectionSignature.signature'),
          disclaimer: this.$t('tabs.paymentComparison.sectionPDF.sectionSignature.disclaimer'),
          disclaimerText: this.$t('tabs.paymentComparison.sectionPDF.sectionSignature.disclaimerTextUSA'),
        },
      },
      info: {
        customerName: this.customerName,
        dealerName: this.dealerName,
        date: this.datePDF,
        vehicle: {
          year: this.vehicle.year,
          make: this.vehicle.make,
          model: this.vehicle.model,
          clt: this.taxes.leaseTerm,
          vin: this.vehicle.vin,
        },
        tableRetail: {
          sellingPrice: this.taxes.sellingPrice,
          cashDownPayment: this.taxes.cashDownPayment,
          tradeEquity: this.taxes.tradeEquity,
          rebate: this.taxes.retailRebate,
          localSaleTaxRate: this.taxes.taxRate,
          amountFinanced: this.formatCurrency(this.amountFinanced),
          term: this.taxes.retailTerm,
          customerRate: this.taxes.customerRetailRate,
          financeCharge: this.formatCurrency(this.financeCharge),
          paymentWithTax: this.formatCurrency(this.paymentWithTax),
        },
        tableCommercialLease: {
          capCost: this.taxes.sellingPrice,
          cashDownPayment: this.taxes.cashDownPayment,
          tradeEquity: this.taxes.tradeEquity,
          capCostReduction: this.taxes.commercialLeaseRebate,
          localSalesTaxRate: this.taxes.taxRate,
          netCapCost: this.formatCurrency(this.netCapCost),
          term: this.taxes.leaseTerm,
          residual: this.taxes.residual,
          paymentsInAdvance: this.taxes.paymentsInAdvance,
          customerRate: this.taxes.customerLeaseRate,
          residualAmount: this.formatCurrency(this.residualAmount),
          basePayment: this.formatCurrency(this.basePayment),
          paymentWithTax: this.formatCurrency(this.commercialPaymentWithTax),
        },
        notes: {
          text: this.taxes.notes,
        },
      },
    });
  },
};
</script>
