import quickQuote from './modules/quickQuote';
import effectiveRate from './modules/effectiveRate';
import paymentComparison from './modules/paymentComparison';
import capitalCost from './modules/capitalCost';

export default {
  header: {
    logo: {
      alternativeText: 'Ford Pro FinSimple',
    },
    userInfo: {
      icon: 'Log out',
    },
    toggleLang: {
      labelLeft: 'English',
      labelRight: 'French',
    },
  },
  mainTitle: 'Ford Credit Calculator Conversion',
  tabs: {
    titles: {
      quickQuote: 'Quick Quote',
      quickQuoteInfo: '',
      effectiveRate: 'Effective Rate',
      effectiveRateInfo: '',
      paymentComparison: 'Payment Comparison',
      paymentComparisonInfo: '',
      capitalCost: 'Capital Cost',
      capitalCostInfo: '',
    },
    general: {
      // Form validation text
      form: {
        requiredField: 'This field is required',
        dropdownNoOptions: 'Sorry, there are no options.',
        toggleLang: {
          en: 'English',
          fr: 'French',
        },
        tableEquipment: {
          description: 'Description',
          value: 'Value',
          action: 'Action',
          sum: 'Sum Of Added Equipment',
        },
        modalClearForm: {
          title: 'Clear Form',
          content: `
          <p>This action will delete all information filled in this form.</p>
          <p>Do you want to proceed?</p>`,
        },
        modalClearAllForms: {
          title: 'Clear All Forms',
          content: `
          <p>This action will delete all information filled in all forms.</p>
          <p>Do you want to proceed?</p>`,
        },
        yesLabel: 'Yes',
        noLabel: 'No',
      },

      // Info Dealer/Customer
      sectionNames: {
        customerNameLabel: 'Customer Name',
        dealerNameLabel: 'Dealer Name',
      },

      // Info Vehicle
      sectionVehicle: {
        title: 'Vehicle Information',
        yearLabel: 'Year',
        makeLabel: 'Make',
        modelLabel: 'Model',
        netCapCostLabel: 'Net Cap Cost',
        vinLabel: 'VIN',
      },

      // Signature and Disclaimer (For PDFs)
      sectionSignature: {
        customerName: '@:tabs.general.sectionNames.customerNameLabel',
        dealerName: '@:tabs.general.sectionNames.dealerNameLabel',
        signature: 'Signature',
        disclaimer: 'Disclaimer',
        disclaimerTextUSA: 'This is not an offer or approval by Ford Motor Credit Company. This is an estimated payment that may be available to a Lessee based on certain lease assumptions. Actual approval by Ford Credit will be based on Ford Credit’s independent analysis of the actual credit-worthiness of the Lessee and the terms of the proposed transaction and is subject to the final review and concurrence of Ford Motor Credit Company.',
        disclaimerTextCAN: 'This is not an offer or approval by Ford Credit Canada Company. This is an estimated payment that may be available to a Lessee based on certain lease assumptions. Actual approval by Ford Credit Canada Company will be based on independent analysis of the actual credit-worthiness of the Lessee and the terms of the proposed transaction and is subject to the final review and concurrence of Ford Credit Canada Company. This sales tool is provided compliments of Ford Credit Canada Company. Although we have attempted to make this a useful sales tool, we do not guarantee sales results or the accuracy of the calculations, nor do we take any responsibility for the manner in which this tool is used. The decision to use this tool is within the sole discretion of your dealership, and dealership management should complete a thorough and independent review.',
      },

      // Actions
      sectionActions: {
        buttonGeneratePDF: 'Generate PDF',
        buttonClear: 'Clear Form',
        buttonClearAll: 'Clear All Forms',
        buttonCloseAndReturn: 'Close And Return to Form',
        buttonScrollToTop: 'Scroll to top',
      },
    },
    quickQuote,
    effectiveRate,
    paymentComparison,
    capitalCost,
  },
};
