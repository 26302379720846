<template>
  <div class="tab-group">
    <TabItem
      v-for="tab in tabs"
      :key="tab.id"
      :id="`tab-group-${tab.id}`"
      class="tab-group__tab"
      :isActive="activeTab === tab.id"
      @click="updateTab(tab.id, tab.route)"
    >
      {{ tab.name }}
    </TabItem>
    <span
      class="tab-group__follow-line test"
      :style="`
        left: ${ activeTabCoordX }px;
        bottom: ${ activeTabCoordY }px;
        width: ${ activeTabWidth }px;
      `"
    />
  </div>
</template>

<script>
import TabItem from '@/components/content-controls/TabItem.vue';

export default {
  name: 'TabGroup',
  emits: ['setActiveTab', 'updateIndex'],
  components: { TabItem },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      activeTabWidth: 0,
      activeTabCoordX: 0,
      activeTabCoordY: 0,
    };
  },
  methods: {
    async updateTab(index, route) {
      await this.$emit('updateIndex', index);
      this.$router.push(route);
    },
    configFollowLine() {
      const currentTab = document.getElementById(`tab-group-${this.activeTab}`);
      if (currentTab) {
        this.activeTabWidth = currentTab.clientWidth;
        this.activeTabCoordX = currentTab.offsetLeft;
        this.activeTabCoordY = currentTab.offsetTop;
      }
    },
  },
  mounted() {
    this.$nextTick(this.configFollowLine);
    window.addEventListener('resize', this.configFollowLine);
  },
  watch: {
    activeTab() {
      this.configFollowLine();
    },
    tabs() {
      this.configFollowLine();
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-group {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
  margin-bottom: 80px;
  @include media('<=900px') {
    &__tab {
      width: 100% !important;
      max-width: 600px;
    }
  }
  @include media('>=900px') {
    flex-direction: row;
    align-items: stretch;
    gap: 40px;
  }

  &__follow-line {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    background-color: $fds-color--primary;
    transition: all .3s ease-in;

    @include media('>=900px') {
      display: block;
    }
  }
}
</style>
