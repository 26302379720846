import { createI18n } from 'vue-i18n';
import store from '@/store';
import enMessages from '@/locales/en/index';
import frMessages from '@/locales/fr/index';

const i18n = createI18n({
  locale: store.getters['locales/activeLocale'], // set locale
  fallbackLocale: store.getters['locales/locales'].en, // set fallback locale
  messages: {
    en: enMessages,
    fr: frMessages,
  },
});

export default i18n;

const setLocale = () => {
  const locale = store.getters['locales/activeLocale'];
  i18n.global.locale = locale;
};

store.watch((state, getters) => getters['locales/activeLocale'], setLocale);
