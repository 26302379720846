<template>
  <Input
    v-maska="{
      mask: [
      'H %',
      'HH %',
      'HHH %',
      'HHHH %',
      'HHHHH %',
      'HHHHHH %',
      'HHHHHHH %',
      ],
      tokens: {
        'H': { pattern: /[0-9\.]/ }
      },
    }"
    v-model:value="currentValue"
    :id="id"
    :name="name"
    :value="value"
    :infoText="infoText"
    :errorText="errorText"
    :placeholder="placeholder"
    :disabled="disabled"
    :readonly="readonly"
    :required="required"
    :submitted="submitted"
    :hasLabel="hasLabel"
    :darkTheme="darkTheme"
    @change="validatePercent"
    @keyup="$emit('keyup', $event)"
    @focus="$emit('focus', $event)"
  >
    <slot />
  </Input>
</template>

<script>
import Input from './Input.vue';

export default {
  name: 'InputPercent',
  components: { Input },
  props: {
    id: { type: String, required: true },
    name: { type: String, required: true },
    value: { type: [String, Number], default: '' },
    infoText: { type: String, default: '' },
    errorText: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    submitted: { type: Boolean, required: true },
    hasLabel: { type: Boolean, default: true },
    darkTheme: { type: Boolean, default: false },
    wantToRound: { type: Boolean, default: false },
  },
  data() {
    return {
      currentValue: '',
    };
  },
  methods: {
    validatePercent() {
      let value = '';

      const percent = this.currentValue.replaceAll('%', '').trim();

      if (percent !== '' && !Number.isNaN(+percent)) {
        value = `${this.wantToRound ? Math.round(+percent) : +percent} %`;
      }

      this.currentValue = value;

      this.$emit('update:value', this.currentValue);
    },
  },
  mounted() {
    if (this.value) this.currentValue = this.value;
  },
};
</script>
