export default {
  namespaced: true,
  state: {
    customerName: '',
    dealerName: '',
    vehicle: {
      year: '',
      make: 'Ford',
      model: '',
      vin: '',
    },
    taxes: {
      sellingPrice: '',
      leaseTerm: '',
      retailTerm: '',
      cashDownPayment: '',
      commercialLeaseRebate: '',
      retailRebate: '',
      tradeEquity: '',
      taxesUpfront: '',
      residual: '',
      customerLeaseRate: '',
      customerRetailRate: '',
      paymentsInAdvance: '',
      taxRate: '',
      notes: '',
    },
  },
  getters: {
    customerName: (state) => state.customerName,
    dealerName: (state) => state.dealerName,
    vehicle: (state) => state.vehicle,
    taxes: (state) => state.taxes,
  },
  mutations: {
    /**
     * Set Customer Name
     * @param {String} name - Name of Customer
     */
    setCustomerName: (state, name) => {
      // Checks if name is valid
      if (state.customerName === name) return;

      state.customerName = name;
    },

    /**
     * Set Dealer Name
     * @param {String} name - Name of Dealer
     */
    setDealerName: (state, name) => {
      // Checks if name is valid
      if (state.dealerName === name) return;

      state.dealerName = name;
    },

    /**
     * Set vehicle information
     * @param {String} year - Year of vehicle
     * @param {String} make - Make of vehicle
     * @param {String} model - Model of vehicle
     * @param {String} vin - VIN
     */
    setVehicle: (
      state,
      {
        year,
        make,
        model,
        vin,
      },
    ) => {
      state.vehicle = {
        year,
        make,
        model,
        vin,
      };
    },

    /**
     * Set Services and Taxes
     * @param {String} sellingPrice - Selling Price
     * @param {String} leaseTerm - Lease Term
     * @param {String} retailTerm - Retail Term
     * @param {String} cashDownPayment - Cash Down Payment
     * @param {String} commercialLeaseRebate - CommercialLease Rebate
     * @param {String} retailRebate - Retail Rebate
     * @param {String} tradeEquity - Trade Equity
     * @param {String} taxesUpfront - Taxes Upfront
     * @param {String} residual - Residual
     * @param {String} customerLeaseRate - Customer Lease Rate
     * @param {String} customerRetailRate - Customer Retail Rate
     * @param {String} paymentsInAdvance - Payments in Advance
     * @param {String} taxRate - Tax Rate
     * @param {String} notes - Notes
     */
    setTaxes: (
      state,
      {
        sellingPrice,
        leaseTerm,
        retailTerm,
        cashDownPayment,
        commercialLeaseRebate,
        retailRebate,
        tradeEquity,
        taxesUpfront,
        residual,
        customerLeaseRate,
        customerRetailRate,
        paymentsInAdvance,
        taxRate,
        notes,
      },
    ) => {
      state.taxes = {
        sellingPrice,
        leaseTerm,
        retailTerm,
        cashDownPayment,
        commercialLeaseRebate,
        retailRebate,
        tradeEquity,
        taxesUpfront,
        residual,
        customerLeaseRate,
        customerRetailRate,
        paymentsInAdvance,
        taxRate,
        notes,
      };
    },

    /**
     * Clear all information from Effective Rate
     */
    setClearPaymentComparison: (state) => {
      state.customerName = '';
      state.dealerName = '';
      state.vehicle.year = '';
      state.vehicle.make = '';
      state.vehicle.model = '';
      state.vehicle.vin = '';
      state.taxes.sellingPrice = '';
      state.taxes.leaseTerm = '';
      state.taxes.retailTerm = '';
      state.taxes.cashDownPayment = '';
      state.taxes.commercialLeaseRebate = '';
      state.taxes.retailRebate = '';
      state.taxes.tradeEquity = '';
      state.taxes.taxesUpfront = '';
      state.taxes.residual = '';
      state.taxes.customerLeaseRate = '';
      state.taxes.customerRetailRate = '';
      state.taxes.paymentsInAdvance = '';
      state.taxes.taxRate = '';
      state.taxes.notes = '';
    },
  },
  actions: {
    /**
     * Update the Customer Name
     * @param {String} name - Name of Customer
     */
    updateCustomerName({ commit }, name) {
      commit('setCustomerName', name);
    },

    /**
     * Update the Dealer Name
     * @param {String} name - Name of Dealer
     */
    updateDealerName({ commit }, name) {
      commit('setDealerName', name);
    },

    /**
     * Update the Vehicle Information
     * @param {Object} vehicle - Data of Vehicle
     * @param {String} vehicle.year - Year of vehicle
     * @param {String} vehicle.make - Make of vehicle
     * @param {String} vehicle.model - Model of vehicle
     * @param {String} vehicle.vin - VIN
     */
    updateVehicle({ commit }, vehicle) {
      commit('setVehicle', vehicle);
    },

    /**
     * Update Services and Taxes
     * @param {Object} taxes - Data of Taxes
     * @param {String} taxes.sellingPrice - Selling Price
     * @param {String} taxes.leaseTerm - Lease Term
     * @param {String} taxes.retailTerm - Retail Term
     * @param {String} taxes.cashDownPayment - Cash Down Payment
     * @param {String} taxes.commercialLeaseRebate - CommercialLease Rebate
     * @param {String} taxes.retailRebate - Retail Rebate
     * @param {String} taxes.tradeEquity - Trade Equity
     * @param {String} taxes.taxesUpfront - Taxes Upfront
     * @param {String} taxes.residual - Residual
     * @param {String} taxes.customerLeaseRate - Customer Lease Rate
     * @param {String} taxes.customerRetailRate - Customer Retail Rate
     * @param {String} taxes.paymentsInAdvance - Payments in Advance
     * @param {String} taxes.taxRate - Tax Rate
     * @param {String} taxes.notes - Notes
     */
    updateTaxes({ commit }, taxes) {
      commit('setTaxes', taxes);
    },

    /**
     * Update All Quick Quote Form
     * @param {Object} data - Data of Quick Quote
     * @param {String} data.customerName - Name of Customer
     * @param {String} data.dealerName - Name of Dealer
     * @param {Object} data.vehicle - Data of Vehicle
     * @param {Object} data.taxes - Data of Taxes
     */
    updatePaymentComparison({ commit }, data) {
      commit('setCustomerName', data.customerName);
      if (data.taxes !== undefined) {
        commit('setDealerName', data.dealerName);
      }
      commit('setVehicle', data.vehicle);
      if (data.taxes !== undefined) {
        commit('setTaxes', data.taxes);
      }
    },
    /**
     * Clear All Quick Quote Form
     */
    clearPaymentComparison({ commit }) {
      commit('setClearPaymentComparison');
    },
  },
};
