<template>
  <center><h1>Logging in...</h1></center>
</template>

<script>

export default {

  async mounted() {
    if (this.$route.query.token !== undefined) {
      const tokenData = this.$route.query.token;
      this.$store.dispatch('user/decodeToken', tokenData);
      localStorage.setItem('tokenLoginInformation', tokenData);

      // Registar to Application Insights
      const userData = this.$store.getters['user/userData'];
      this.$insights.trackPageViewWithMetaInfo(userData);

      this.$router.push({ name: 'home' });
      return;
    }

    const result = await this.$store.dispatch('user/doLogin');

    if (result !== undefined) {
      if (result.success) {
        window.location.href = result.url;
        return;
      }
      // TODO: implement error message passed as param
      this.$router.push({ name: 'error' });
    }
  },
};
</script>
