<template>
  <section id="payment-comparison" class="payment-comparison">
    <form
      v-if="!showPDF"
      ref="effectiverateform"
      @submit.prevent="generatePDF()"
    >
      <div class="first-info fds-m--b-3">
        <Input
          id="payment-comparison-costumer-name"
          name="payment-comparison-costumer-name"
          class="field"
          :submitted="hasSubmitted"
          v-model:value="customerName"
          @hasError="validateField($event)"
          required
        >{{ componentText.customerName }}</Input>
        <Input
          id="payment-comparison-dealer-name"
          class="field"
          name="payment-comparison-dealer-name"
          :submitted="hasSubmitted"
          v-model:value="dealerName"
          v-maska="{ mask: 'EEEEEEEEEEEEEEEEEEEEEEEEE',
          tokens: { 'E': { pattern: /[0-9a-zA-Z-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'\s]/}} }"
          @hasError="validateField($event)"
          required
        >{{ componentText.dealerName }}</Input>
      </div>

      <h3 class="payment-comparison__subtitle fmc-type--subtitle1">
        {{ componentText.vehicle.title }}
      </h3>

      <div class="second-info fds-m--b-3">
        <Input
          id="payment-comparison-vehicle-year"
          class="field"
          name="payment-comparison-vehicle-year"
          :submitted="hasSubmitted"
          minlength="4"
          v-model:value="vehicle.year"
          v-maska="'####'"
          @hasError="validateField($event)"
          required
        >{{ componentText.vehicle.yearLabel }}</Input>
        <Input
          id="payment-comparison-vehicle-make"
          class="field"
          name="payment-comparison-vehicle-make"
          :submitted="hasSubmitted"
          v-model:value="vehicle.make"
          @hasError="validateField($event)"
          required
        >{{ componentText.vehicle.makeLabel }}</Input>
        <Input
          id="payment-comparison-vehicle-model"
          class="field"
          name="payment-comparison-vehicle-model"
          :submitted="hasSubmitted"
          v-model:value="vehicle.model"
          @hasError="validateField($event)"
          required
        >{{ componentText.vehicle.modelLabel }}</Input>
        <Input
          id="payment-comparison-vehicle-vin"
          class="field"
          name="payment-comparison-vehicle-vin"
          :submitted="hasSubmitted"
          v-model:value="vehicle.vin"
          @hasError="validateField($event)"
          required
        >{{ componentText.vehicle.vinLabel }}</Input>
      </div>

      <h3 class="payment-comparison__subtitle fmc-type--subtitle1">
        {{ componentText.taxes.title }}
      </h3>

      <div class="third-info fds-m--b-3">
        <InputCurrency
          id="payment-comparison-sellingPrice"
          class="field"
          name="payment-comparison-sellingPrice"
          :submitted="hasSubmitted"
          v-model:value="taxes.sellingPrice"
          @hasError="validateField($event)"
          required
        >
          {{ componentText.taxes.sellingPriceLabel }} <sup>1</sup>
        </InputCurrency>
        <Input
          id="payment-comparison-leaseTerm"
          class="field"
          name="payment-comparison-leaseTerm"
          :submitted="hasSubmitted"
          v-model:value="taxes.leaseTerm"
          v-maska="'##'"
          @hasError="validateField($event)"
          required
        >
          {{ componentText.taxes.leaseTermLabel }} <sup>4</sup>
        </Input>
        <Input
          id="payment-comparison-retail-term"
          class="field"
          name="payment-comparison-retail-term"
          :submitted="hasSubmitted"
          v-model:value="taxes.retailTerm"
          v-maska="'##'"
          @hasError="validateField($event)"
          required
        >
          {{ componentText.taxes.retailTermLabel }} <sup>4</sup>
        </Input>
        <InputCurrency
          id="payment-comparison-cashdown"
          class="field"
          name="payment-comparison-cashdown"
          :submitted="hasSubmitted"
          v-model:value="taxes.cashDownPayment"
          @hasError="validateField($event)"
          required
        >
          {{ componentText.taxes.cashDownPaymentLabel }} <sup>4</sup>
        </InputCurrency>
        <InputCurrency
          id="payment-comparison-commerciallease"
          class="field"
          name="payment-comparison-commerciallease"
          :submitted="hasSubmitted"
          v-model:value="taxes.commercialLeaseRebate"
          @hasError="validateField($event)"
          required
        >
          {{ componentText.taxes.commercialLeaseRebateLabel }} <sup>4</sup>
        </InputCurrency>
        <InputCurrency
          id="payment-comparison-retail-rebate"
          class="field"
          name="payment-comparison-retail-rebate"
          :submitted="hasSubmitted"
          v-model:value="taxes.retailRebate"
          @hasError="validateField($event)"
          required
        >
          {{ componentText.taxes.retailRebateLabel }} <sup>4</sup>
        </InputCurrency>
        <InputCurrency
          id="payment-comparison-trade-equity"
          class="field"
          name="payment-comparison-trade-equity"
          :submitted="hasSubmitted"
          v-model:value="taxes.tradeEquity"
          @hasError="validateField($event)"
          required
        >
          {{ componentText.taxes.tradeEquityLabel }}
        </InputCurrency>
        <div class="field radios-flex">
          <Radio
            id="payment-comparison-taxes-upfront"
            name="payment-comparison-taxes-upfront"
            :options="[
              {
                id: 'payment-comparison-taxes-upfront-yes',
                label: 'Yes',
                isChecked: taxes.taxesUpfront === 'Yes',
                disabled: false,
              },
              {
                id: 'payment-comparison-taxes-upfront-no',
                label: 'No',
                isChecked: taxes.taxesUpfront === 'No',
                disabled: false,
              },
            ]"
            :submitted="hasSubmitted"
            @input="taxes.taxesUpfront = $event"
            @hasError="validateField($event)"
            required
          >
            {{ componentText.taxes.taxesUpfrontLabel }}
          </Radio>
        </div>
        <InputPercent
          id="payment-comparison-residual"
          class="field"
          name="payment-comparison-residual"
          :submitted="hasSubmitted"
          v-model:value="taxes.residual"
          @hasError="validateField($event)"
          required
        >
          {{ componentText.taxes.residualLabel }}
        </InputPercent>
        <InputPercent
          id="payment-comparison-customer-lease-rate"
          class="field"
          name="payment-comparison-customer-lease-rate"
          :submitted="hasSubmitted"
          v-model:value="taxes.customerLeaseRate"
          @hasError="validateField($event)"
          required
        >
          {{ componentText.taxes.customerLeaseRateLabel }}
        </InputPercent>
        <InputPercent
          id="payment-comparison-customer-retail-rate"
          class="field"
          name="payment-comparison-customer-retail-rate"
          :submitted="hasSubmitted"
          v-model:value="taxes.customerRetailRate"
          @hasError="validateField($event)"
          required
        >
          {{ componentText.taxes.customerRetailRateLabel }}
        </InputPercent>
        <div class="field radios-flex">
          <Radio
            id="payment-comparison-payments-in-advance"
            name="payment-comparison-payments-in-advance"
            :options="[
              {
                id: 'payment-comparison-payments-in-advance-yes',
                label: 'Yes',
                isChecked: taxes.paymentsInAdvance === 'Yes',
                disabled: false,
              },
              {
                id: 'payment-comparison-payments-in-advance-no',
                label: 'No',
                isChecked: taxes.paymentsInAdvance === 'No',
                disabled: false,
              },
            ]"
            :submitted="hasSubmitted"
            @input="taxes.paymentsInAdvance = $event"
            @hasError="validateField($event)"
            required
          >
            {{ componentText.taxes.paymentsInAdvanceLabel }}
          </Radio>
        </div>
        <div class="last-fields">
          <InputPercent
            id="payment-comparison-tax-rate"
            class="field"
            name="payment-comparison-tax-rate"
            :submitted="hasSubmitted"
            v-model:value="taxes.taxRate"
            @hasError="validateField($event)"
            required
          >
            {{ componentText.taxes.taxRateLabel }}
          </InputPercent>
          <TextareaFDS
            id="payment-comparison-notes"
            class="field textarea"
            name="payment-comparison-notes"
            :submitted="hasSubmitted"
            v-model:value="taxes.notes"
            @hasError="validateField($event)"
          >
            {{ componentText.taxes.notesLabel }}
          </TextareaFDS>
        </div>
      </div>
      <Button
        id="payment-comparison-generate-pdf"
        class="fds-m--b-3"
        :type="'submit'"
        :label="componentText.buttonGeneratePDF"
        icon="next"
        @click="generatePDF()"
      />
    </form>
    <div class="pdf" v-if="showPDF">
      <GeneratePDF />
      <Button
        id="payment-comparison-return-to-form"
        class="fds-m--t-3"
        :type="'submit'"
        :label="componentText.buttonCloseAndReturn"
        icon="next"
        @onClick="showPDF = false"
      />
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Input from '@/components/form-elements/Input.vue';
import InputPercent from '@/components/form-elements/InputPercent.vue';
import InputCurrency from '@/components/form-elements/InputCurrency.vue';
import Radio from '@/components/form-elements/Radio.vue';
import TextareaFDS from '@/components/form-elements/Textarea.vue';
import Button from '@/components/form-elements/Button.vue';
import GeneratePDF from './GeneratePDF.vue';

export default {
  name: 'PaymentComparisonForm',
  components: {
    Input,
    InputPercent,
    InputCurrency,
    Radio,
    TextareaFDS,
    Button,
    GeneratePDF,
  },
  data() {
    return {
      showPDF: false,
      hasSubmitted: false,
      errors: [],
      changeStore: false,

      // Dealer & Customer
      customerName: '',
      dealerName: '',

      // Vehicle Information
      vehicle: {
        year: '',
        make: '',
        model: '',
        vin: '',
      },

      // Services and Taxes
      taxes: {
        sellingPrice: '',
        leaseTerm: '',
        retailTerm: '',
        cashDownPayment: '',
        commercialLeaseRebate: '',
        retailRebate: '',
        tradeEquity: '',
        taxesUpfront: '',
        residual: '',
        customerLeaseRate: '',
        customerRetailRate: '',
        paymentsInAdvance: '',
        taxRate: '',
        notes: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      customerNameStore: 'paymentComparison/customerName',
      dealerNameStore: 'paymentComparison/dealerName',
      vehicleStore: 'paymentComparison/vehicle',
      taxesStore: 'paymentComparison/taxes',
      getModalResponse: 'notifications/getModalResponse',
    }),

    componentText() {
      const sectionNames = 'tabs.paymentComparison.sectionNames';
      const sectionVehicle = 'tabs.paymentComparison.sectionVehicle';
      const sectionTaxes = 'tabs.paymentComparison.sectionTaxes';
      const sectionActions = 'tabs.general.sectionActions';

      return {
        customerName: this.$t(`${sectionNames}.customerNameLabel`),
        dealerName: this.$t(`${sectionNames}.dealerNameLabel`),

        vehicle: {
          title: this.$t(`${sectionVehicle}.title`),
          yearLabel: this.$t(`${sectionVehicle}.yearLabel`),
          makeLabel: this.$t(`${sectionVehicle}.makeLabel`),
          modelLabel: this.$t(`${sectionVehicle}.modelLabel`),
          vinLabel: this.$t(`${sectionVehicle}.vinLabel`),
        },

        taxes: {
          title: this.$t(`${sectionTaxes}.title`),
          sellingPriceLabel: this.$t(`${sectionTaxes}.sellingPriceLabel`),
          sellingPriceInfo: this.$t(`${sectionTaxes}.sellingPriceInfo`),
          leaseTermLabel: this.$t(`${sectionTaxes}.leaseTermLabel`),
          retailTermLabel: this.$t(`${sectionTaxes}.retailTermLabel`),
          cashDownPaymentLabel: this.$t(`${sectionTaxes}.cashDownPaymentLabel`),
          commercialLeaseRebateLabel: this.$t(`${sectionTaxes}.commercialLeaseRebateLabel`),
          retailRebateLabel: this.$t(`${sectionTaxes}.retailRebateLabel`),
          tradeEquityLabel: this.$t(`${sectionTaxes}.tradeEquityLabel`),
          taxesUpfrontLabel: this.$t(`${sectionTaxes}.taxesUpfrontLabel`),
          residualLabel: this.$t(`${sectionTaxes}.residualLabel`),
          customerLeaseRateLabel: this.$t(`${sectionTaxes}.customerLeaseRateLabel`),
          customerRetailRateLabel: this.$t(`${sectionTaxes}.customerRetailRateLabel`),
          paymentsInAdvanceLabel: this.$t(`${sectionTaxes}.paymentsInAdvanceLabel`),
          taxRateLabel: this.$t(`${sectionTaxes}.taxRateLabel`),
          notesLabel: this.$t(`${sectionTaxes}.notesLabel`),
        },
        buttonGeneratePDF: this.$t(`${sectionActions}.buttonGeneratePDF`),
        buttonClear: this.$t(`${sectionActions}.buttonClear`),
        buttonClearAll: this.$t(`${sectionActions}.buttonClearAll`),
        buttonCloseAndReturn: this.$t(`${sectionActions}.buttonCloseAndReturn`),
      };
    },
  },
  watch: {
    customerNameStore() {
      this.changeStore = true;
    },
    'vehicleStore.year': function () {
      this.changeStore = true;
    },
    'vehicleStore.make': function () {
      this.changeStore = true;
    },
    'vehicleStore.model': function () {
      this.changeStore = true;
    },
    'vehicleStore.vin': function () {
      this.changeStore = true;
    },
    'taxesStore.residual': function () {
      this.changeStore = true;
    },
    changeStore(value) {
      if (value === true) {
        this.populateSharedFields();
      }
    },
  },
  mounted() {
    this.populateForm();
  },
  methods: {
    ...mapActions({
      updatePaymentComparison: 'paymentComparison/updatePaymentComparison',
      clearPaymentComparison: 'paymentComparison/clearPaymentComparison',
      showNotification: 'notifications/showNotification',
      showDefaultErrorForm: 'notifications/showDefaultErrorForm',
      showLoading: 'notifications/showLoading',
      hideLoading: 'notifications/hideLoading',
      showModalDialog: 'notifications/showModalDialog',
    }),

    /**
     * Controls fields with error
     * @param {Object} field - Field data
     * @param {String} field.name - Name of Field
     * @param {Boolean} field.error - Field has error?
     */
    validateField(field) {
      if (field.error) {
        this.errors.push(field.name);
      } else {
        this.errors = this.errors.filter((e) => e !== field.name);
      }
    },

    /**
     * Get and Set values from store
     */
    populateForm() {
      this.hasSubmitted = false;

      this.customerName = this.customerNameStore;
      this.dealerName = this.dealerNameStore;

      this.vehicle.year = this.vehicleStore.year;
      this.vehicle.make = this.vehicleStore.make;
      this.vehicle.model = this.vehicleStore.model;
      this.vehicle.vin = this.vehicleStore.vin;

      this.taxes.sellingPrice = this.taxesStore.sellingPrice;
      this.taxes.leaseTerm = this.taxesStore.leaseTerm;
      this.taxes.retailTerm = this.taxesStore.retailTerm;
      this.taxes.cashDownPayment = this.taxesStore.cashDownPayment;
      this.taxes.commercialLeaseRebate = this.taxesStore.commercialLeaseRebate;
      this.taxes.retailRebate = this.taxesStore.retailRebate;
      this.taxes.tradeEquity = this.taxesStore.tradeEquity;
      this.taxes.taxesUpfront = this.taxesStore.taxesUpfront;
      this.taxes.residual = this.taxesStore.residual;
      this.taxes.customerLeaseRate = this.taxesStore.customerLeaseRate;
      this.taxes.customerRetailRate = this.taxesStore.customerRetailRate;
      this.taxes.paymentsInAdvance = this.taxesStore.paymentsInAdvance;
      this.taxes.taxRate = this.taxesStore.taxRate;
      this.taxes.notes = this.taxesStore.notes;
    },

    populateSharedFields() {
      this.customerName = this.customerNameStore;

      this.vehicle.year = this.vehicleStore.year;
      this.vehicle.make = this.vehicleStore.make;
      this.vehicle.model = this.vehicleStore.model;
      this.vehicle.vin = this.vehicleStore.vin;

      this.taxes.residual = this.taxesStore.residual;

      this.changeStore = false;
    },

    async generatePDF() {
      this.hasSubmitted = true;
      if (this.errors.length) {
        this.showDefaultErrorForm();
        return;
      }

      this.showLoading();
      await this.updatePaymentComparison({
        customerName: this.customerName,
        dealerName: this.dealerName,
        vehicle: this.vehicle,
        taxes: this.taxes,
      });

      this.showPDF = true;
      this.hideLoading();

      // Report action to Application Insights
      const userData = this.$store.getters['user/userData'];
      userData.calculator = 'Paument Comparison';
      this.$insights.trackEventBtnPageWithMetaInfo('Payment Comparison PDF', userData);
    },
  },
};
</script>
<style lang="scss" scoped>
.payment-comparison {
  &__title {
    margin-bottom: 40px;
  }

  &__subtitle {
    margin-bottom: 20px;
  }

  .field {
    margin-bottom: fds-rem(40px);
    @include media('>=600px'){
      width: calc(50% - 2rem);
    }
    @include media('>=1024px'){
      width: calc(33.33% - 2rem);
    }
  }

  .radios-flex {
    display: flex;
    align-items: center;
    padding-top: fds-rem(24px);

    &__options {
      display: flex;
    }
  }

  .last-fields {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;

    .field.textarea {
      @include media('>=1024px'){
        width: calc(66.66% - 1rem);
        max-width: fds-rem(1040px);
      }
    }
  }

  .first-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @include media('>=1024px'){
      justify-content: flex-start;
      gap: 0 3rem;
    }
  }

  .second-info,
  .third-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}
</style>
