import { createStore } from 'vuex';

// Modules
import notifications from './modules/notifications';
import locales from './modules/locales';
import user from './modules/user';
import quickQuote from './modules/calcQuickQuote';
import effectiveRate from './modules/calcEffectiveRate';
import paymentComparison from './modules/calcPaymentComparison';
import capitalCost from './modules/calcCapitalCost';

const store = createStore({
  modules: {
    notifications,
    locales,
    user,
    quickQuote,
    effectiveRate,
    paymentComparison,
    capitalCost,
  },
});

export default store;
